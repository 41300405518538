import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Avatar, Button, Typography } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useStateValue } from "../../../providers/context/contextProvider";
import "./ProductoCard.css";
import LazyLoad from "react-lazyload";

const ProductoCard = ({ handleOpen, producto, colores }) => {
  const [context] = useStateValue();
  const classes = useStyles();
  const { nombre, descripcion, precio, productoImg, _id } = producto;

  const incluido = React.useMemo(() => {
    const result = context.app.productos.find((_pro) => _pro._id === _id);
    return result;
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.app.productos]);

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.root}
      onClick={() => {
        handleOpen();
      }}
    >
      <Grid item xs={4} className={classes.flexCenter}>
        <Avatar
          src={productoImg}
          style={{
            height: 120,
            width: 110,
            borderRadius: 15,
            boxShadow: "0px 6px 10px #727C8E40",
          }}
          variant="rounded"
        />
      </Grid>
      <Grid
        container
        item
        xs={8}
        style={{
          backgroundColor: "rgba(250,250,250,0.1)",
          padding: "0px 15px",
          paddingRight: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <p className={classes.titulo}>
          <Typography
            component={"span"}
            style={{ color: colores.primary, fontWeight: 600 }}
          >
            {nombre}
          </Typography>
          {incluido && (
            <ShoppingCartIcon
              color="secondary"
              style={{
                height: 16,
                width: "auto",
              }}
            />
          )}
        </p>
        <Typography
          className="description-text"
          style={{
            color: "#515C6F",
            fontSize: 12,
            marginTop: -2,
            marginBottom: 6,
            minHeight: 36,
            // height: 45,
          }}
        >
          {descripcion}
        </Typography>
        {/* <p className="description-text">{descripcion}</p> */}
        <p style={{ padding: 0, margin: 0 }}>
          <Typography
            component={"span"}
            style={{ color: "#4A4A4A", fontWeight: 600 }}
          >
            ${precio}
          </Typography>
        </p>
        <Button
          className={classes.button}
          style={{
            backgroundColor: colores.primary,
          }}
        >
          Añadir
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    // backgroundColor: "#8F295F",
    borderRadius: 5,
    color: "white",
    width: "100%",
    height: 22,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "capitalize",
    margin: 0,
    padding: 0,
  },
  titulo: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default ProductoCard;
