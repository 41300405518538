/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, Fragment } from "react";
import { Modal } from "@material-ui/core";
import TablaProductos from "../../../components/AdminAliado/TablaProductos/TablaProductos";
import { useStateValue } from "../../../providers/context/adminAliadoContext";
import ReorderIcon from "@material-ui/icons/Reorder";
import CategoriasModal from "../../../components/modals/CategoriasModal/CategoriasModal";
import reorderArray from "../../../utils/reorderArray";

const Productos = () => {
  const [context, dispatch] = useStateValue();
  const [categoriaSelected, setCategoriaSeleted] = useState(null);
  const [modal, setModal] = useState(false);

  const modifyCategoriaSelected = (value) => {
    setCategoriaSeleted(value);
  };

  const productosGrouped = useMemo(() => {
    if (!context.aliado && !context.aliado.productos) return [];
    const resultado = context.aliado.productos.reduce((prev, next) => {
      if (next.categorias && next.categorias.length) {
        next.categorias.forEach((categoria) => {
          if (prev[categoria]) {
            if (!prev[categoria].find((producto) => producto._id === next._id))
              prev[categoria].push(next);
          } else {
            prev[categoria] = [next];
          }
        });
      } else {
        if (prev["Otros"]) {
          prev["Otros"].push(next);
        } else {
          prev["Otros"] = [next];
        }
      }
      return prev;
    }, {});
    return resultado;
  }, [context]);

  const productosReordered = useMemo(
    () => reorderArray(productosGrouped, context.aliado.ordenCategorias || []),
    [productosGrouped]
  );

  const productosCategoria = useMemo(() => {
    if (!categoriaSelected || categoriaSelected === "todos") {
      return context.aliado.productos;
    }
    if (
      !categoriaSelected ||
      !productosGrouped.hasOwnProperty(categoriaSelected)
    ) {
      return [];
    }

    const productosFiltered = productosGrouped[categoriaSelected].map(
      ({
        _id,
        aliado,
        nombre,
        descripcion,
        precio,
        imagen,
        productoImg,
        ingredientes,
        categorias,
      }) =>
        Object({
          _id,
          aliado,
          nombre,
          descripcion,
          precio,
          imagen,
          productoImg,
          ingredientes,
          categorias,
        })
    );
    return productosFiltered;
  }, [productosGrouped, categoriaSelected]);

  return (
    <div className="tienda-container productos-container">
      <div className="productos-header">
        <h2>Productos</h2>
        <div className="right">
          <div
            className="reorder-button"
            onClick={() => {
              setModal(true);
            }}
          >
            <ReorderIcon style={{ color: "white", fontSize: 20 }} />
          </div>
          <select
            className="select-productos"
            defaultValue="todos"
            onChange={({ target }) => {
              modifyCategoriaSelected(target.value);
            }}
          >
            <option value="todos">Todos</option>
            {Object.keys(productosReordered).map((categoria, idx) => (
              <option value={categoria} key={idx}>
                {categoria}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="productos-body">
        <TablaProductos
          productos={productosCategoria}
          reordered={productosReordered}
          existingCategorias={Object.keys(productosGrouped)}
          categoria={categoriaSelected}
          context={context}
          dispatch={dispatch}
        />
      </div>
      <Modal
        open={modal}
        onClose={() => {
          // if (!avoidClose) setModal(false);
          setModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fragment>
          <CategoriasModal
            context={context}
            dispatch={dispatch}
            setModal={setModal}
            existingCategorias={productosGrouped}
            ordenCategorias={context.aliado.ordenCategorias}
          />
        </Fragment>
      </Modal>
    </div>
  );
};

export default Productos;
