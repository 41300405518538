const reorderArray = (base, arrayOrder) => {
  const processData = (arr, source) =>
    arr.reduce((prev, next) => {
      return { ...prev, [next]: source[next] };
    }, {});

  const faltantes = Object.keys(base).filter(
    (item) => !arrayOrder.includes(item)
  );

  return {
    ...processData(arrayOrder, base),
    ...processData(faltantes, base),
  };
};

export default reorderArray;
