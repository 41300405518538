import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const FormItem = ({ children, style = {} }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      justify={"flex-start"}
      alignItems={"flex-start"}
      className={classes.formContent}
      style={style}
    >
      {children}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  formContent: {
    marginTop: theme.spacing(1),
    // background: "red",
  },
}));

export default FormItem;
