import React, { useState } from "react";
import { IconButton, Input } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import HelpIcon from "@material-ui/icons/Help";
import "./ChangeBanner.css";

const ChangeBanner = ({ id, currentImg, uploadImg }) => {
  const [backgroundImg] = useState(currentImg);

  const triggerInput = (input) => {
    document.getElementById(input).click();
  };

  return (
    <div className="banner-container">
      <Input
        accept="image/*"
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={({ target }) => uploadImg(target.files[0])}
      />

      <div className="banner-title">
        <span className="title">Imagen para sección de aliados:</span>
        <label htmlFor={id}>
          <IconButton
            onClick={() => triggerInput(id)}
            className="icon-button"
            style={{
              marginLeft: 4,
            }}
          >
            <CreateIcon />
          </IconButton>
        </label>
        <IconButton
          // onClick={() => console.log("this is working")}
          className="icon-button"
        >
          <HelpIcon />
        </IconButton>
      </div>
      <div
        className="preview-box"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

export default ChangeBanner;
