import React from "react";
import { Grid, Typography, Select } from "@material-ui/core";

const CategoriasSelect = ({ handleChange, categorias = [], selector }) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          fontWeight: 800,
          color: "#515C6F",
          fontSize: "15px",
        }}
      >
        Aliados
      </Typography>
      <Select
        disableUnderline
        native
        value={selector}
        onChange={handleChange}
        style={{
          backgroundColor: "white",
          width: 140,
          height: 30,
          borderRadius: 30,
          boxShadow: "0px 3px 6px #00000029",
          color: "#515C6F",
          fontSize: 13,
          textAlign: "center",
          paddingLeft: 10,
        }}
      >
        <option name="Todos" value={""} key={0}>
          Todos
        </option>
        {categorias.map((categoria, key) => {
          const { _id, nombre } = categoria;
          return (
            <option name={nombre} value={_id} key={key + 1}>
              {nombre}
            </option>
          );
        })}
      </Select>
    </Grid>
  );
};

export default CategoriasSelect;
