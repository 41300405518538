import React from "react";
import { Typography } from "@material-ui/core";

const Selector = ({
  plus = false,
  className = "",
  setCantidad,
  cantidad,
  chart = false,
}) => (
  <div
    style={{
      height: chart ? 15 : 25,
      width: chart ? 15 : 25,
      borderRadius: 50,
      background: "white",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
    onClick={() => {
      if (cantidad === 1 && !plus) {
        if (chart) {
          const response = window.confirm(
            "Seguro que deseas retirar producto?"
          );
          if (!response) return;
          chart();
          return;
        }
        return cantidad;
      }
      const newCantidad = plus ? cantidad + 1 : cantidad - 1;
      setCantidad(newCantidad);
    }}
  >
    <Typography className={className}>{plus ? "+" : "-"}</Typography>
  </div>
);
const CantidadInput = ({
  cantidad = 0,
  className,
  setCantidad,
  chart = false,
}) => {
  return (
    <>
      <Typography className={className}>Cantidad: </Typography>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Selector
          className={className}
          cantidad={cantidad}
          setCantidad={setCantidad}
          chart={chart}
        />
        <Typography className={className}>{cantidad}</Typography>
        <Selector
          className={className}
          cantidad={cantidad}
          setCantidad={setCantidad}
          chart={chart}
          plus
        />
      </div>
    </>
  );
};

export default CantidadInput;
