import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { useStateValue } from "../../../providers/context/contextProvider";
import { Button, Typography, Avatar } from "@material-ui/core";
import ChartProducto from "./ChartProducto/ChartProducto";
import { useHistory } from "react-router-dom";
import { calcularPrecioTotalProductos } from "../../../utils/calcularPrecio";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import { getComandaPickup } from "../../../utils/comandaHelper";

const ChartModal = ({ closeChart }, ref) => {
  const [context, dispatch] = useStateValue();
  const productosIndexed = useMemo(() => {
    return context.app.productos.map((producto, i) => ({
      ...producto,
      i,
    }));
  }, [context.app.productos]);
  const styles = useStyles();
  const history = useHistory();
  const aliadosEnCarrito = useMemo(
    () => [...new Set(productosIndexed.map(({ aliado }) => aliado))],
    [productosIndexed]
  );

  const productosAliado = (aliadoId) => {
    if (!aliadoId) return [];
    return productosIndexed.filter((producto) => producto.aliado === aliadoId);
  };

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.modalContainer}>
        {productosIndexed.length > 0 ? (
          <div className={styles.internalContainer}>
            {aliadosEnCarrito.map((aliadoId, idx) => {
              const aliado = context.app.aliados.find(
                (aliado) => aliado._id === aliadoId
              );
              if (!aliado) {
                console.error("Error matching aliados");
                return null;
              }
              return (
                <div
                  style={{
                    marginBottom: 10,
                  }}
                  key={idx}
                >
                  <div
                    style={{
                      padding: "5px 10px",
                      backgroundColor: aliado.colores.primary,
                      borderRadius: 5,
                      marginBottom: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={aliado.perfilImg}
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: 15,
                        boxShadow: "0px 6px 10px #727C8E40",
                        backgroundColor: "white",
                      }}
                      variant="rounded"
                    />
                    <Typography
                      style={{
                        fontSize: 13,
                        marginLeft: 5,
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      {aliado.nombre}
                    </Typography>
                  </div>
                  <div
                    style={{
                      maxHeight: 280,
                      overflowY: "scroll",
                    }}
                  >
                    {productosAliado(aliado._id).map((producto, idy) => (
                      <ChartProducto
                        {...producto}
                        context={context}
                        dispatch={dispatch}
                        key={idy}
                      />
                    ))}
                  </div>
                  <div className={styles.totalBox}>
                    <Typography className={styles.title}>Total: </Typography>
                    <Typography className={styles.title}>
                      $
                      {calcularPrecioTotalProductos(
                        productosAliado(aliado._id)
                      )}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      className={`
                      ${styles.button} 
                      ${styles.buttonContinuar} 
                      ${styles.buttonAdd}
                    `}
                      style={{
                        width: "49%",
                        height: 30,
                        backgroundColor: aliado.colores.primary,
                      }}
                      onClick={() => {
                        const comm = getComandaPickup({
                          aliado,
                          productos: productosAliado(aliado._id),
                          precioProductos: calcularPrecioTotalProductos(
                            productosAliado(aliado._id)
                          ),
                          metodoPago: "zelling",
                        });
                        const safariWindow = window.open();
                        safariWindow.location.href = `https://api.whatsapp.com/send?phone=${aliado.telefono}&text=${comm}`;
                        closeChart();
                      }}
                    >
                      <StoreMallDirectoryIcon
                        style={{
                          height: 20,
                          width: "auto",
                          marginRight: 5,
                        }}
                      />
                      Pickup
                    </Button>
                    <Button
                      className={`
                      ${styles.button} 
                      ${styles.buttonContinuar} 
                      ${styles.buttonAdd}
                      `}
                      style={{
                        width: "49%",
                        height: 30,
                        backgroundColor: aliado.colores.primary,
                      }}
                      onClick={() => {
                        dispatch({
                          type: "SET_COMANDA",
                          comanda: {
                            aliado: aliado,
                            productos: productosAliado(aliado._id),
                            total: calcularPrecioTotalProductos(
                              productosAliado(aliado._id)
                            ),
                          },
                        });
                        dispatch({ type: "TOGGLE_CHART" });
                        history.push("/Checkout");
                      }}
                    >
                      <DirectionsBikeIcon
                        style={{
                          height: 15,
                          width: "auto",
                          marginRight: 5,
                        }}
                      />
                      Delivery
                    </Button>
                  </div>
                </div>
              );
            })}

            <div className={styles.options}>
              <div className={styles.flexCenter}>
                <Button
                  className={`${styles.button} ${styles.buttonAdd}`}
                  onClick={closeChart}
                >
                  Agregar mas productos
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noItemsContainer}>
            <Typography variant="h6">
              Aun no tienes productos seleccionados
            </Typography>
            <Button className={styles.button} onClick={closeChart}>
              Volver
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    width: "80vw",
    minHeight: "70vh",
    position: "relative",
    display: "flex",
  },
  modalContainer: {
    // background: "red",
    padding: theme.spacing(3),
    flex: 1,
    display: "flex",
  },
  noItemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.grises.main,
  },
  button: {
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(2),
    backgroundColor: "#35a4fe",
    borderRadius: 5,
    color: "white",
    width: "50%",
    // marginLeft: "15%",
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  buttonContinuar: {
    backgroundColor: "#EF9700",
  },
  buttonAdd: {
    width: "100%",
  },
  internalContainer: {
    display: "flex",
    flexDirection: "column",
    // background: "yellow",
    width: "100%",
  },
  options: {
    height: "20%",
  },
  totalBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grises.darkblue,
    textTransform: "capitalize",
    lineHeight: "1.9vh",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default React.forwardRef(ChartModal);
