import React, { useState } from "react";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import style from "./style";

const IngredienteRadio = ({ tipo, ingredientes, setCuenta, colores }, ref) => {
  const classes = style();
  const [value, setValue] = useState(
    `${ingredientes[0].ingredienteId}%${ingredientes[0].nombre}%${ingredientes[0].precio}`
  );
  const handleChange = ({ target }) => {
    const { value, name } = target;
    const valueSplit = value.split("%");
    const ingredienteId = valueSplit[0];
    const nombre = valueSplit[1];
    const precio = parseFloat(valueSplit[2]);
    setCuenta({
      tipo: name,
      ingredienteId,
      nombre,
      precio,
    });
    setValue(value);
  };

  const StyledRadio = withStyles({
    root: {
      color: colores.secondary,
      "&$checked": {
        color: colores.primary,
      },
    },
    checked: {},
  })((props) => (
    <Radio
      color="default"
      size="small"
      className={classes.formRadioInputStyle}
      {...props}
    />
  ));

  return (
    <FormControl ref={ref} component="fieldset" className={classes.formRadio}>
      <FormLabel component="legend" className={classes.formRadioTitle}>
        {tipo}
      </FormLabel>
      <RadioGroup
        aria-label={tipo}
        name={tipo}
        value={value}
        onChange={handleChange}
        className={classes.formRadioGroup}
      >
        {ingredientes.map((ingrediente, key) => (
          <FormControlLabel
            key={key}
            value={`${ingrediente.ingredienteId}%${ingrediente.nombre}%${ingrediente.precio}`}
            control={
              <StyledRadio/>
            }
            label={
              <Typography
                component={"span"}
                className={classes.formRadioInputLabel}
              >
                {ingrediente.nombre}
                {ingrediente.precio ? (
                  <Box
                    fontWeight="fontWeightBold"
                    style={{ display: "inline" }}
                  >
                    {" "}
                    (+${ingrediente.precio})
                  </Box>
                ) : (
                  ""
                )}
              </Typography>
            }
            labelPlacement="start"
            className={classes.formRadioInput}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default React.forwardRef(IngredienteRadio);
