import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Avatar, Typography, CircularProgress } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 15,
    boxShadow: "0px 6px 10px rgba(114,124,142,0.25)",
    height: 100,
    backgroundBlendMode: "overlay",
    padding: 10,
  },
  aliadoTypo: {
    color: "white",
    fontWeight: 800,
    textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    fontSize: "15px",
  },
}));

const AliadoCard = ({
  nombre,
  _id,
  categorias = [],
  distance = null,
  loadingLocation = false,
  perfilImg,
  cardImg,
  desactivarMenu = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid
      container
      item
      xs={12}
      className={classes.root}
      style={{
        background: `url(${cardImg}) rgba(30,30,30,0.8)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      onClick={() => {
        if (desactivarMenu) {
          history.push(`checkout/${_id}`);
          return;
        }
        history.push(`aliado/${_id}`);
      }}
    >
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        xs={4}
      >
        <Avatar
          src={perfilImg}
          style={{
            height: 70,
            width: 70,
            borderRadius: 15,
            boxShadow: "0px 6px 10px #727C8E40",
            backgroundColor: "white",
          }}
          variant="rounded"
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        xs={8}
      >
        <span>
          <Typography className={classes.aliadoTypo}>{nombre}</Typography>
        </span>
        <span>
          <Typography
            className={classes.profileTypo}
            style={{
              color: "white",
              fontWeight: 300,
              fontSize: "13px",
              letterSpacing: "-0.2px",
            }}
          >
            {categorias.map((categoria) => categoria.nombre).join(" - ")}
          </Typography>
        </span>
        {distance ? (
          loadingLocation ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon style={{ color: "white" }} />
              <Typography
                style={{
                  fontWeight: 800,
                  color: "white",
                  fontSize: "11px",
                }}
              >
                {distance} km
              </Typography>
            </div>
          )
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AliadoCard;
