import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Header from "./Header";

const AliadoHeader = ({
  nombre,
  headerImg,
  perfilImg,
  categorias = ["Hamburguesas"],
  distance = null,
  loadingLocation = false,
  colores,
  market = false,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        marginBottom: 140,
      }}
    >
      <Grid
        container
        className={classes.header}
        style={{
          background: `url('${headerImg}') rgba(0,0,0,0.3)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          zIndex: 999,
          // backgroundBlendMode: "darken",
        }}
      >
        <Header aliado market={market} />
        <Grid
          item
          xs={12}
          style={{
            height: "20%",
            marginTop: "auto",
            display: "flex",
            //   background: "red",
          }}
        >
          <div
            className={classes.aliadoAvatar}
            style={{
              background: `url(${perfilImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "white",
              boxShadow: "rgba(114, 124, 142, 0.08) 0px 2px 4px",
            }}
          />
          <Typography className={classes.aliadoTitulo}>{nombre}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subHeaderAliado}>
        <Typography
          className={classes.aliadoCategorias}
          style={{
            color: "#8A8888",
            fontWeight: 300,
            fontSize: "0.7em",
            minHeight: "17px",
          }}
        >
          {categorias.map((cat) => cat.nombre).join(" - ")}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {distance ? (
            loadingLocation ? (
              <CircularProgress
                size="16px"
                style={{
                  color: colores.primary || "red",
                }}
              />
            ) : (
              <>
                <LocationOnIcon
                  color="secondary"
                  style={{
                    height: "auto",
                    width: 14,
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 700,
                    color: "#8A8888",
                    fontSize: "11px",
                  }}
                >
                  {distance} km
                </Typography>
              </>
            )
          ) : null}
        </div>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    height: "15vh",
    padding: 0,
  },
  subHeaderAliado: {
    height: "20%",
    marginTop: "auto",
    display: "flex",
    paddingLeft: theme.spacing(4.5) + 100,
    flexDirection: "column",
  },
  aliadoAvatar: {
    height: "100px",
    width: "100px",
    marginLeft: theme.spacing(3),
    borderRadius: "20px",
    marginTop: -theme.spacing(2.25),
  },
  aliadoTitulo: {
    display: "flex",
    alignItems: "flex-end",
    color: "white",
    fontWeight: 800,
    fontSize: 18,
    textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  aliadoCategorias: {
    marginTop: theme.spacing(0.2),
  },
}));

export default AliadoHeader;
