import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const Categoria = (props) => {
  const {
    tipo,
    backgroundSize = "80%",
    onClick = () => {},
    texto = "",
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(3),
      // background: "red",
    },
    categoria: {
      height: 68,
      width: "150px",
      background: `url(/assets/img/categorias/${tipo}.png) ${theme.palette.celestes.main}`,
      backgroundSize,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "15px",
    },
    titulo: {
      // textTransform: "capitalize",
      textAlign: "center",
      // fontFamily: "Century Gothic",
      fontWeight: 700,
      color: theme.palette.grises.main,
      marginTop: "0.5em",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <Box boxShadow={5} className={classes.categoria}></Box>
      <Typography className={classes.titulo}>{texto}</Typography>
    </div>
  );
};

export default Categoria;
