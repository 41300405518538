import React, { useState } from "react";
import { Input, CircularProgress } from "@material-ui/core";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import axios from "axios";
import uploadImage from "../../../../providers/uploadImage";
import LazyBackground from "../../../LazyBackground";

const ImageModal = ({
  productoSelected = {},
  setModal,
  setAvoidClose,
  context,
  dispatch,
  onSubmit,
}) => {
  const { REACT_APP_API_HOST } = process.env;
  const [loading, setLoading] = useState(false);

  const startUploadImage = async (image) => {
    setLoading(true);
    setAvoidClose(true);
    const imageUrl = await uploadImage(image);
    const result = await sendProductoImg(imageUrl);
    if (!result) alert("Error al subir imagen...");
    onSubmit(imageUrl);
  };

  const sendProductoImg = async (productoImg) => {
    const { aliado, token } = context;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios
      .put(
        `${REACT_APP_API_HOST}/producto/${productoSelected._id}`,
        {
          aliadoId: aliado._id,
          productoImg,
        },
        config
      )
      .catch((err) => {
        console.log("error", err);
        return false;
      });

    if (!response) {
      return false;
    }
    return true;
  };

  const existeImagen = Boolean(productoSelected.productoImg);

  return (
    <div className="modal-container imagen">
      <h2>Imagen del producto</h2>
      {loading ? (
        <>
          <CircularProgress />
          <p>Subiendo imagen...</p>
        </>
      ) : existeImagen ? (
        <LazyBackground
          className="imagen-container"
          src={productoSelected.productoImg}
          onClick={() => {
            document.getElementById("producto-img").click();
          }}
        />
      ) : (
        <div
          className="add-image"
          onClick={() => {
            document.getElementById("producto-img").click();
          }}
        >
          <p>Haz click para agregar imagen</p>
          <AddPhotoAlternateIcon
            style={{
              color: "grey",
              fontSize: 40,
            }}
          />
        </div>
      )}
      <Input
        accept="image/*"
        id="producto-img"
        type="file"
        style={{ display: "none" }}
        onChange={({ target }) => {
          startUploadImage(target.files[0]);
        }}
      />
      {!loading && existeImagen ? (
        <p>Haz click sobre la imagen para reemplazarla</p>
      ) : null}
    </div>
  );
};

export default ImageModal;
