import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
