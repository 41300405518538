import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Typography, Button } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useStateValue } from "../../../providers/context/contextProvider";
import { calcularPrecioProducto } from "../../../utils/calcularPrecio";
import IngredienteRadio from "../../inputs/IngredientesInput/IngredienteRadio";
import IngredienteCheck from "../../inputs/IngredientesInput/IngredienteCheck";
import CantidadInput from "../../inputs/CantidadInput/CantidadInput";
import style from "./style";

const ProductoModal = ({ handleClose, data, colores = null }, ref) => {
  const { _id, aliado, nombre, descripcion, precio, productoImg } = data;
  const classes = style();
  const ingredientes = useMemo(() => {
    const _requeridos = data.ingredientes.filter(
      (ingrediente) => ingrediente.tipo === "requerido"
    );
    const requeridosTemp = {};
    const tipos = [...new Set(_requeridos.map((e) => e.requerido))];
    tipos.forEach((tipo) => {
      requeridosTemp[tipo] = _requeridos.filter(
        (ingrediente) => ingrediente.requerido === tipo
      );
    });
    return {
      requeridos: requeridosTemp,
      opcional: data.ingredientes.filter(
        (ingrediente) => ingrediente.tipo === "opcional"
      ),
    };
  }, [data.ingredientes]);
  const [, dispatch] = useStateValue();
  const [requeridos, setRequeridos] = useState({});
  const [extras, setExtras] = useState([]);
  const [cantidad, setCantidad] = useState(1);
  const [nota, setNota] = useState("");

  const setRequerido = (producto) => {
    setRequeridos((prev) => ({
      ...prev,
      [producto.tipo]: producto,
    }));
  };

  const setExtra = ({ accion = "agregar", _id, nombre, precio = 0 }) => {
    if (accion === "agregar") {
      const extra = { _id, nombre, precio: isNaN(precio) ? 0 : precio };
      // setPrecioFinal((prev) => [...prev, extra]);
      if (!extras.find((p) => p._id === _id))
        setExtras((prev) => [...prev, extra]);
    } else {
      setExtras((prev) => prev.filter((p) => p._id !== _id));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPrecio = useCallback(
    calcularPrecioProducto({ precio, requeridos, extras, cantidad }),
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cantidad, requeridos, extras]
  );

  useEffect(() => {
    const requeridos_tipos = Object.keys(ingredientes.requeridos);
    const temp = {};
    requeridos_tipos.forEach((tipo) => {
      temp[tipo] = ingredientes.requeridos[tipo][0];
    });
    setRequeridos({ ...temp });
  }, [ingredientes]);

  return (
    <div className={classes.root} ref={ref}>
      <div
        className={classes.mediaCard}
        style={{
          background: `url(${productoImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            handleClose();
          }}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.titleBar}>
          <Typography className={classes.title}>{nombre}</Typography>
          <Typography className={classes.precio}>${precio}</Typography>
        </div>
        <Typography className={classes.desc}>{descripcion}</Typography>
        <div
          style={{
            overfloxY: "scroll",
            overflowX: "hidden",
            maxHeight: "25vh",
          }}
        >
          {ingredientes &&
            Object.keys(ingredientes.requeridos).map((tipo, key) => {
              return (
                <IngredienteRadio
                  key={key}
                  tipo={tipo}
                  ingredientes={ingredientes.requeridos[tipo]}
                  setCuenta={setRequerido}
                  value={requeridos[tipo]}
                  colores={colores}
                />
              );
            })}
          {ingredientes && (
            <IngredienteCheck
              extras={ingredientes.opcional}
              setCuenta={setExtra}
              colores={colores}
            />
          )}
        </div>
        <div className={classes.cantidad}>
          <CantidadInput
            className={`${classes.totalText} ${classes.cantidadText}`}
            cantidad={cantidad}
            setCantidad={setCantidad}
          />
        </div>
        <Typography className={classes.descTitle}>Descripcion:</Typography>
        <textarea
          className={classes.descInput}
          placeholder="Descripcion para su pedido"
          value={nota}
          onChange={(evt) => {
            setNota(evt.target.value);
          }}
        ></textarea>
        <div className={classes.totalBox}>
          <Typography className={classes.totalText}>Total:</Typography>
          <Typography className={classes.totalText}>${getPrecio}</Typography>
        </div>
        <div className={classes.buttonBox}>
          <Button
            className={classes.button}
            onClick={() => {
              const productoTotal = {
                aliado,
                descripcion,
                _id,
                nombre,
                precio,
                precioTotal: getPrecio,
                requeridos,
                cantidad,
                extras,
                productoImg,
                nota,
              };

              dispatch({ type: "SET_PRODUCTO", producto: productoTotal });
              handleClose("submit");
            }}
            style={colores && { backgroundColor: colores.primary }}
          >
            Anadir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(ProductoModal);
