/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  CircularProgress,
  Modal,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SearchBar from "../../components/inputs/SearchBar/SearchBar";
import ProductoCard from "../../components/cards/ProductoCard/ProductoCard";
import PromocionesCards from "../../components/cards/PromocionesCards/PromocionesCards";
import ProductoModal from "../../components/modals/ProductoModal/ProductoModal";
import "./Aliado.css";
import AliadoHeader from "../../components/Header/AliadoHeader";
import CategoriaProductos from "../../components/inputs/CategoriaProductos/CategoriaProductos";
import useAliado from "../../providers/hooks/useAliado";
import { toast } from "react-toastify";
import { useStateValue } from "../../providers/context/contextProvider";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const ProductGroup = ({
  colores,
  productos,
  handleOpen,
  selector,
  handleCategoryChange,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <Typography
          style={{
            fontWeight: 800,
            color: "#515C6F",
            fontSize: "15px",
          }}
        >
          {selector ? selector : "Todos"}
        </Typography>
        {selector ? (
          <IconButton
            style={{
              margin: 0,
              padding: 0,
            }}
            onClick={() => {
              handleCategoryChange("Todos");
            }}
          >
            <HighlightOffIcon
              style={{
                color: colores.primary,
                cursor: "pointer",
              }}
            />
          </IconButton>
        ) : null}
      </div>
      <div>
        {productos.map((producto, idx) => (
          <ProductoCard
            colores={colores}
            producto={producto}
            handleOpen={() => {
              handleOpen(producto);
            }}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};

const Aliado = () => {
  const [context, dispatch] = useStateValue();
  const classes = useStyles();
  const { aliadoId } = useParams();

  const notify = () =>
    toast.success("Articulo agregado con exito", {
      className: classes.notificacion,
      hideProgressBar: true,
      autoClose: 700,
    });

  const {
    loading,
    AliadoData,
    activeProducto,
    allProductos,
    groupedProductos,
    loadAliado,
    handleCategoryChange,
    openModal,
    handleOpen,
    handleClose,
    selector,
  } = useAliado({ aliadoId, notify, context, dispatch });

  const promos = false;

  useEffect(() => {
    loadAliado();
  }, []);

  return (
    <Container
      maxWidth="sm"
      disableGutters
      style={{
        overflow: "scroll",
        height: "100vh",
      }}
    >
      {loading ? (
        <Grid container className={classes.loadingScreen}>
          <CircularProgress />
        </Grid>
      ) : (
        <Fragment>
          <AliadoHeader
            nombre={AliadoData.nombre}
            headerImg={AliadoData.headerImg}
            perfilImg={AliadoData.perfilImg}
            categorias={AliadoData.categorias}
            distance={AliadoData.distance || null}
            loadingLocation={context.app.loadingLocation}
            colores={AliadoData.colores}
            market={AliadoData.market}
          />
          <Grid container className={classes.body}>
            {promos ? (
              <div
                style={{
                  marginTop: 4 * 2,
                  marginBottom: 4 * 4,
                }}
              >
                <PromocionesCards
                  colores={AliadoData.colores}
                  promos={promos}
                />
              </div>
            ) : null}
            <div
              style={{
                marginTop: promos ? 0 : 4 * 10,
                marginBottom: 4 * 4,
                width: "100%",
              }}
            >
              <SearchBar
                color={AliadoData.colores.primary}
                aliado
                productos={allProductos}
                handleOpen={handleOpen}
              />
            </div>
          </Grid>
          <Grid container className={classes.categorias}>
            <CategoriaProductos
              categorias={Object.keys(groupedProductos)}
              selector={selector}
              getCategoria={() => {}}
              handleCategoryChange={handleCategoryChange}
              colores={AliadoData.colores}
            />
          </Grid>
          <Grid container className={classes.productos}>
            <ProductGroup
              selector={selector}
              colores={AliadoData.colores}
              productos={
                selector
                  ? groupedProductos.hasOwnProperty(selector)
                    ? groupedProductos[selector]
                    : []
                  : allProductos
              }
              handleOpen={handleOpen}
              handleCategoryChange={handleCategoryChange}
            />
          </Grid>

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
          >
            <Fragment>
              <ProductoModal
                handleClose={handleClose}
                colores={AliadoData.colores}
                data={activeProducto}
              />
            </Fragment>
          </Modal>
        </Fragment>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  body: {
    overflow: "scroll",
    paddingLeft: theme.spacing(0.875),
    paddingRight: theme.spacing(0.875),
  },
  productos: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // marginTop: 20,
  },
  categorias: {},
  loadingScreen: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
  notificacion: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default Aliado;
