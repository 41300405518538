const scriptLoader = (url, callback) => {
  const existingScript = document.getElementById("googleScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.id = "googleScript";
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else {
    callback();
  }
};

export default scriptLoader;
