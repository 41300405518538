import React, { useState } from "react";
import { useStateValue } from "../../../providers/context/adminDomiContext";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SportsMotorsportsIcon from "@material-ui/icons/SportsMotorsports";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory } from "react-router-dom";

const MENU_ITEMS = [
  {
    title: "Dashboard",
    navLabel: "dashboard",
    navRoute: "/admin/domi",
  },
  {
    title: "Riders",
    items: [
      {
        title: "Crear Rider",
        navLabel: "crearRider",
        navRoute: "/admin/domi/rider/crear",
        icon: <AddBoxIcon />,
      },
      {
        title: "Riders",
        navLabel: "riders",
        navRoute: "/admin/domi/riders",
        icon: <FormatListBulletedIcon />,
      },
      {
        title: "Tipos de  Rider",
        navLabel: "tiposRider",
        navRoute: "/admin/domi/rider/tipos",
        icon: <SportsMotorsportsIcon />,
      },
    ],
  },
  {
    title: "Aliados",
    items: [
      {
        title: "Ver Aliados",
        navLabel: "aliados",
        navRoute: "/admin/domi/aliados",
        icon: <FormatListBulletedIcon />,
      },
    ],
  },
  {
    title: "Comandas",
    items: [
      {
        title: "Ver Comandas",
        navLabel: "comandas",
        navRoute: "/admin/domi/comandas",
        icon: <FormatListBulletedIcon />,
      },
      {
        title: "Clonar Comanda",
        navLabel: "clonarComanda",
        navRoute: "/admin/domi/comandas/clonar",
        icon: <FileCopyIcon />,
      },
    ],
  },
];

const MenuBar = () => {
  const [context, dispatch] = useStateValue();

  const setNavigation = (nav) => {
    dispatch({
      type: "SET_NAV",
      nav,
    });
  };

  return (
    <div className="menu-bar">
      <div className="menu-item menu-first">Admin Panel v1</div>
      {MENU_ITEMS.map((menuItem, idx) => (
        <MenuItem
          key={idx}
          active={context.nav === menuItem.navLabel}
          title={menuItem.title}
          items={menuItem.items}
          navRoute={menuItem.navRoute}
          setNavigation={setNavigation}
          navLabel={menuItem.navLabel}
          activeNav={context.nav}
        />
      ))}
    </div>
  );
};

const MenuItem = ({
  title = "",
  items = [],
  setNavigation = () => {},
  navLabel = "",
  active = false,
  activeNav = "",
  navRoute = "",
}) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(true);
  const toggleMenu = () => setExpanded(!expanded);
  const NavArrow = () =>
    expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  const handleClick = (itemLabel = false, route) => {
    if (items.length && !itemLabel) {
      toggleMenu();
      return;
    }
    setNavigation(itemLabel ? itemLabel : navLabel);
    // history.replace(route);
  };

  return (
    <div
      className={`menu-item${items.length ? " expandable" : ""}${
        active && !items.length ? " active" : ""
      }`}
    >
      <div
        className="father-item"
        onClick={() => handleClick(!items.length ? navLabel : false, navRoute)}
      >
        <span>{title}</span>
        {items.length ? <NavArrow /> : null}
      </div>
      {expanded && items.length ? (
        <div className="sub-container">
          {items.map((item, idx) => (
            <div
              className={`sub-item${
                item.navLabel === activeNav ? " active" : ""
              }`}
              key={idx}
              onClick={() => handleClick(item.navLabel, item.navRoute)}
            >
              {item.icon}
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default MenuBar;
