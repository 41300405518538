import React, { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import TablaIngredientes from "../../TablaIngredientes/TablaIngredientes";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "./Dialog";
import Fetch from "../../../../providers/Fetch";
import { toast } from "react-toastify";

const IngredientesModal = ({ productoSelected, context, dispatch }) => {
  const { _id } = productoSelected;
  const [ingredientes, setIngredientes] = useState(
    productoSelected.ingredientes || []
  );
  const [loading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nuevosRequeridos, setNuevosRequeridos] = useState([]);

  const toastConfig = {
    autoClose: 2500,
    hideProgressBar: true,
  };

  const displayToast = {
    success: (message, toastId) => {
      toast.success(message, { ...toastConfig, toastId });
    },
    warn: (message, toastId) => {
      toast.warn(message, { ...toastConfig, toastId });
    },
    error: (message, toastId) => {
      toast.error(message, { ...toastConfig, toastId });
    },
  };

  const requeridos = useMemo(() => {
    const requeridosRaw = ingredientes.filter(
      (ingrediente) => ingrediente.tipo === "requerido"
    );
    const requeridosTemp = {};
    const tipos = [...new Set(requeridosRaw.map((e) => e.requerido))];
    tipos.forEach((tipo) => {
      requeridosTemp[tipo] = requeridosRaw.filter(
        (ingrediente) => ingrediente.requerido === tipo
      );
    });
    return requeridosTemp;
  }, [ingredientes]);
  const opcionales = useMemo(() => {
    const result = ingredientes.filter(
      (ingrediente) => ingrediente.tipo === "opcional"
    );
    // console.log("opcionales", result);
    return result;
  }, [ingredientes]);

  const addIngrediente = async (props, temporal = false) => {
    if (temporal) {
      setNuevosRequeridos((_req) => _req.filter((r) => r !== temporal));
    }
    delete props["_id"];
    if (props.tipo !== "opcional") {
      props.requerido = props.tipo;
      props.tipo = "requerido";
    }

    try {
      const result = await Fetch({
        method: "POST",
        endpoint: `/producto/${_id}/ingrediente`,
        body: props,
        token: context.token,
      });

      displayToast.success(
        "Item agregado exitosamente...",
        "success-add-ingrediente"
      );

      setIngredientes((_ing) => [..._ing, result.data.data]);

      dispatch({
        type: "UPDATE_PRODUCTO",
        _id,
        payload: {
          ingredientes: [...ingredientes, result.data.data],
        },
      });
    } catch (err) {
      displayToast.error(
        "Error al agregar ingrediente...",
        "error-add-ingrediente"
      );
      console.log(err);
      return;
    }
  };

  const addTempRequerido = (nombre) => {
    setNuevosRequeridos([...nuevosRequeridos, nombre]);
    setDialogOpen(false);
  };

  const modifyIngrediente = async (props) => {
    const { nombre, precio = null } = props;
    try {
      await Fetch({
        method: "PUT",
        endpoint: `/producto/${_id}/ingrediente`,
        body: {
          ingredienteId: props._id,
          nombre,
          precio: parseFloat(precio || 0).toFixed(2) || null,
        },
        token: context.token,
      });
      displayToast.success(
        "Ingrediente modificando con exito",
        "success-modify-ingrediente"
      );
      setIngredientes((ingds) =>
        ingds.map((ingrediente) => {
          if (ingrediente._id === props._id) {
            return { ...ingrediente, ...props, _id: ingrediente._id };
          }
          return ingrediente;
        })
      );

      dispatch({
        type: "UPDATE_PRODUCTO",
        _id,
        payload: {
          ingredientes: ingredientes.map((ingrediente) => {
            if (ingrediente._id === props._id) {
              return { ...ingrediente, ...props, _id: ingrediente._id };
            }
            return ingrediente;
          }),
        },
      });
    } catch (err) {
      displayToast.error(
        "Error al modificar ingrediente",
        "error-modify-ingrediente"
      );
      console.log(err);
    }
  };

  const retirarIngrediente = async (props) => {
    const ingredienteId = props._id;
    try {
      await Fetch({
        method: "DELETE",
        endpoint: `/producto/ingrediente/${ingredienteId}`,
        body: {
          ingredienteId,
        },
        token: context.token,
      });
      setIngredientes((ing) =>
        ing.filter((ingrediente) => ingrediente._id !== ingredienteId)
      );
      displayToast.success(
        "Ingrediente retirado con exito!",
        "success-delete-ingrediente"
      );
      dispatch({
        type: "UPDATE_PRODUCTO",
        _id,
        payload: {
          ingredientes: ingredientes.filter(
            (ingrediente) => ingrediente._id !== ingredienteId
          ),
        },
      });
    } catch (err) {
      displayToast.error(
        "Error al eliminar ingrediente...",
        "error-delete-ingrediente"
      );
      console.log(err);
      return;
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className="modal-container edit ingredientes">
      <h2>Ingredientes</h2>
      {loading ? <CircularProgress /> : <></>}
      <div className="ingredientes-container">
        <div className="ingrediente-container">
          <div className="ingrediente-title">
            <h2>Requeridos</h2>
            <div
              className="agregar-container"
              onClick={() => {
                setDialogOpen(true);
                // setProductoSelected({ type: "agregar" });
                // setModal(true);
              }}
            >
              <span>Agregar Tipo</span>
              <AddBoxIcon />
            </div>
          </div>
          <div className="ingrediente-body">
            {Object.keys(requeridos).map((ingrediente, idx) => {
              return (
                <TablaIngredientes
                  key={idx}
                  tipo={ingrediente}
                  requeridos={requeridos[ingrediente]}
                  addIngrediente={addIngrediente}
                  modifyIngrediente={modifyIngrediente}
                  retirarIngrediente={retirarIngrediente}
                />
              );
            })}
            {nuevosRequeridos.map((requerido, idy) => (
              <TablaIngredientes
                key={idy}
                tipo={requerido}
                requeridos={[]}
                addIngrediente={addIngrediente}
                modifyIngrediente={modifyIngrediente}
                retirarIngrediente={retirarIngrediente}
                temporal
              />
            ))}
          </div>
        </div>
        <div className="ingrediente-container">
          <div className="ingrediente-title">
            <h2>Opcionales</h2>
          </div>
          <div className="ingrediente-body">
            <TablaIngredientes
              tipo="opcional"
              opcionales={opcionales}
              addIngrediente={addIngrediente}
              modifyIngrediente={modifyIngrediente}
              retirarIngrediente={retirarIngrediente}
            />
          </div>
        </div>
      </div>
      <div className="info">
        <i>
          Si tu ingrediente no tiene precio adicional deja el campo "precio"
          vacio
        </i>
      </div>
      <div className="footer"></div>
      <Dialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        addTempRequerido={addTempRequerido}
        requeridos={requeridos}
        nuevosRequeridos={nuevosRequeridos}
      />
    </div>
  );
};

export default IngredientesModal;
