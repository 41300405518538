import React from "react";
import { useStateValue } from "../../providers/context/adminAliadoContext";
import StorefrontIcon from "@material-ui/icons/Storefront";
import FastfoodIcon from "@material-ui/icons/Fastfood";
const items = {
  tienda: {
    label: "Tienda",
    items: {
      //   home: {
      //     label: "Home",
      //     icon: "sdfd",
      //   },
      tienda: {
        label: "Mi Tienda",
        icon: () => <StorefrontIcon />,
      },
      // comandas: {
      //   label: "Comandas",
      //   icon: "sads",
      // },
    },
  },
  productos: {
    label: "Productos",
    items: {
      misProductos: {
        label: "Mis Productos",
        icon: () => <FastfoodIcon />,
      },
      // categorias: {
      //   label: "Categorias",
      //   icon: "sdsa",
      // },
      // promociones: {
      //   label: "Promociones",
      //   icon: "sdsa",
      // },
    },
  },
};

const NavBar = ({ selected }) => {
  const [context, dispatch] = useStateValue();

  const Section = ({ label = "", items = {}, selected, icon }) => {
    return (
      <div className="section">
        <span className="title">{label}</span>
        <div className="item-container">
          {Object.keys(items || {}).map((itemKey, idx) => {
            const { label, icon } = items[itemKey];
            return (
              <div
                className={`item ${
                  selected === itemKey ||
                  (itemKey === "home" && selected === null)
                    ? "selected"
                    : ""
                }`}
                key={idx}
                onClick={() => {
                  dispatch({ type: "SET_NAV", nav: itemKey });
                }}
              >
                {icon()}
                <span>{label}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="navbar">
      <div className="header">
        <span>domi</span>
      </div>
      <div className="aliado-header">
        <div
          className="image"
          style={{
            backgroundImage: `url(${context.aliado.perfilImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <span className="aliado-nombre">{context.aliado.nombre}</span>
      </div>
      {Object.keys(items).map((sectionKey, idx) => {
        const section = items[sectionKey];
        return (
          <Section
            label={section.label}
            items={section.items}
            selected={selected}
            key={idx}
          />
        );
      })}
    </div>
  );
};

export default NavBar;
