import React, { useState } from "react";
import { useStateValue } from "../../../providers/context/adminAliadoContext";
import axios from "axios";
import { toast } from "react-toastify";
const Login = () => {
  const { REACT_APP_API_HOST } = process.env;
  const [, dispatch] = useStateValue();
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const submitLogin = async () => {
    setError(false);
    const { email, password } = form;

    if (!email.length || !password.length) {
      // setError("Debes introducir correo y clave...");
      toast.error("Debes introducir correo y clave...", {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Correo invalido...", {
        autoClose: 2000,
        hideProgressBar: true,
      });
      // setError("Correo invalido...");
      return;
    }

    const response = await axios
      .post(`${REACT_APP_API_HOST}/auth/aliado`, {
        correo: String(email).toLocaleLowerCase(),
        clave: password,
      })
      .catch(() => false);

    if (!response) {
      // setError("Datos de Login invalidos...");
      toast.error("Datos de Login invalidos...", {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return;
    }

    const { token, aliado } = response.data.data;
    dispatch({ type: "SET_ALIADO", aliado, token });
    localStorage.setItem("domi-app-memory", token);
  };

  const validateEmail = (email) =>
    // TODO: CHECK ESLINT
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    );

  React.useEffect(() => {}, []);

  return (
    <div className="container login">
      <img src="../assets/img/domi.png" alt="domi" />
      <div className="login-box">
        <div className="input-group">
          <span className="label">Usuario/Correo</span>
          <input
            type="email"
            value={form.email}
            placeholder="ejemplo@aliado.com"
            onChange={({ target }) =>
              setForm((_form) => ({ ..._form, email: target.value }))
            }
          />
        </div>
        <div className="input-group">
          <span className="label">Clave</span>
          <input
            type="password"
            value={form.password}
            placeholder="*****"
            onChange={({ target }) =>
              setForm((_form) => ({ ..._form, password: target.value }))
            }
          />
        </div>
        <div>
          <span className="forgot-pass">Problemas para entrar?</span>
        </div>
        <div className="button-container">
          <div className="button login" onClick={() => submitLogin()}>
            Entrar
          </div>
        </div>
        {error ? <span className="error-box">{error}</span> : null}
      </div>
    </div>
  );
};

export default Login;
