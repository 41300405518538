import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import requestLocation from "../utils/requestLocation";
import { ContextProvider } from "../providers/context/contextProvider";

const AppContext = (props) => {
  const [Context, context, dispatch] = ContextProvider();
  const location = useLocation();

  useEffect(() => {
    if (
      props.excludeRoutes.some((route) => location.pathname.includes(route)) ||
      context.app.location
    ) {
      return;
    }
    requestLocation(dispatch);
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.app.location]);

  return (
    <Context.Provider value={[context, dispatch]}>
      {props.children}
    </Context.Provider>
  );
};

export default AppContext;
