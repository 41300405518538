import React from "react";
import { Typography, CircularProgress, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InfoIcon from "@material-ui/icons/Info";

// import Tooltip from "../../components/Tooltip/Tooltip";

const DistancePrice = ({
  distance = "0 km",
  encomienda = false,
  precioDelivery = 0,
  precioProductos = 0,
  precioDolar = 0,
  loadingPrecio = true,
  tooltipOpen,
  setTooltipOpen,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        gutterBottom
        className={classes.tooltipTitle}
        style={{
          color: "#515C6F",
          fontSize: 18,
        }}
      >
        Total: ${(precioProductos + precioDelivery).toFixed(2)}
      </Typography>
      <Tooltip
        open={tooltipOpen}
        placement="top"
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="caption"
              gutterBottom
              className={classes.tooltipTitle}
            >
              Precio Delivery: {precioDelivery.toFixed(2)}$
            </Typography>
            {!encomienda ? (
              <Typography
                variant="caption"
                gutterBottom
                className={classes.tooltipTitle}
              >
                Precio Productos: {precioProductos.toFixed(2)}$
              </Typography>
            ) : null}
            <Typography
              variant="caption"
              gutterBottom
              style={{
                color: "white",
                fontSize: 10,
                fontWeight: "bold",
                margin: 0,
                padding: 0,
              }}
            >
              Distancia Total: {distance}
            </Typography>
            <Typography variant="caption" className={classes.tooltipTitle}>
              Precio en BsS:{" "}
              {loadingPrecio ? (
                <CircularProgress
                  style={{
                    height: 12,
                    width: 12,
                    margin: "0px 10px",
                  }}
                />
              ) : (
                `${new Intl.NumberFormat("en-US").format(
                  precioDolar * (precioProductos + precioDelivery)
                )} bsS`
              )}
            </Typography>
            <Typography variant="caption" className={classes.tooltipTitle}>
              Tasa del Dia: $
              {new Intl.NumberFormat("en-US").format(precioDolar)} bsS / $
            </Typography>
          </div>
        }
        interactive
        arrow
      >
        <InfoIcon
          style={{
            marginLeft: 5,
            width: 20,
            height: 20,
            color: "#515C6F",
            marginBottom: 2,
          }}
          onClick={() => {
            setTooltipOpen(!tooltipOpen);
          }}
        />
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltipTitle: {
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    padding: 0,
    margin: 0,
  },
}));

export default DistancePrice;
