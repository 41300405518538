import getFecha from "./getFecha";
import redondear from "./redondear";

const { REACT_APP_HOST } = process.env;

export const getGPSLink = (coords) => {
  return `https://maps.google.com/?q=${parseFloat(coords.lat).toFixed(
    6
  )},${parseFloat(coords.lng).toFixed(6)}`;
};

export const getCoordsFromGoogle = (markers, markerId = "destino") => {
  const coordsFunction = markers.find(
    (marker) => marker.markerId === markerId
  )?.position;

  return coordsFunction
    ? {
        lat: coordsFunction.lat(),
        lng: coordsFunction.lng(),
      }
    : null;
};

export const getComandaText = ({
  markers = null,
  comanda = null,
  datosCliente,
  metodoPago,
  precioDelivery,
  precioDolar,
  comandaId,
  mostrarPrecio,
  direct,
}) => {
  if (!markers || !comanda) return;
  const ocultarPrecioDelivery = Boolean(!direct && !mostrarPrecio);
  const precioTotalFinal = redondear(
    comanda.total + (ocultarPrecioDelivery ? 0 : precioDelivery)
  );
  let string = `Pedido: https://plataforma.pideundomi.com/comanda/${comandaId}
--------------------------------
*${comanda.aliado.nombre.toUpperCase()}*
${getFecha()}
${datosCliente.nombre}
*Productos*
--------------------------------\n`;
  comanda.productos.forEach((producto) => {
    string += `*${producto.nombre.toUpperCase()}*
CANT: ${producto.cantidad}\n`;
    if (producto.requeridos !== {}) {
      string += `DET:\n - Requeridos: `;
      for (const requerido in producto.requeridos) {
        const ingrediente = producto.requeridos[requerido];
        string += `${ingrediente.nombre} - `;
      }
      string += "\n";
    }
    if (producto.extras.length) {
      string += "- Extras: ";
      for (const extra in producto.extras) {
        const ingrediente = producto.extras[extra];
        string += `${ingrediente.nombre} - `;
      }
      string += "\n";
    }
    string += `NOTA: ${producto.nota}
PRECIO: $${redondear(producto.precioTotal)}
--------------------------------\n`;
  });
  string += `*PEDIDO: $${redondear(comanda.total)}*`;
  if (!ocultarPrecioDelivery) {
    string += `\n*DOMI: $${redondear(precioDelivery)}*`;
  }
  string += `\n--------------------------------
*TOTAL: $${precioTotalFinal}*`;

  if (metodoPago) {
    string += `\n--------------------------------\n`;
    string +=
      metodoPago === "efectivo"
        ? `*M. PAGO: EFECTIVO*`
        : `*M. PAGO: ${metodoPago.tipo.toUpperCase()}*`;
  }

  return encodeURIComponent(string);
};

export const getComandaDirectText = ({
  markers = null,
  comanda = null,
  datosCliente,
  metodoPago,
  precioDelivery,
  comandaId,
  clientGPSLink = "",
  customTarifa = null,
  mostrarPrecio = false,
}) => {
  if (!markers || !comanda) return;
  let string = `Pedido: https://plataforma.pideundomi.com/comanda/${comandaId}
--------------------------------
*${comanda.aliado.nombre.toUpperCase()}*
${getFecha()}
${datosCliente.nombre}`;
  if (customTarifa) {
    string += `
GPS: ${clientGPSLink}`;
  }
  if (mostrarPrecio) {
    string += `\n--------------------------------
*TOTAL: $${redondear(precioDelivery)}*`;
  }
  if (metodoPago) {
    string += `\n--------------------------------\n`;
    string +=
      metodoPago === "efectivo"
        ? `*M. PAGO: EFECTIVO*`
        : `*M. PAGO: ${metodoPago.tipo.toUpperCase()}*`;
  }

  return encodeURIComponent(string);
};

export const getComandaPickup = ({
  aliado = null,
  productos,
  precioProductos,
}) => {
  // console.log("comandaId API", comandaId);
  const coordsAliado = getGPSLink(aliado.coords);
  let string = "";
  string = `${REACT_APP_HOST} \n`;
  string += `--------------------------------\n`;
  string += `*${aliado.nombre.toUpperCase()}*\n`;
  string += `${getFecha()}\n`;
  string += `*Productos*\n`;
  string += `--------------------------------\n`;
  productos.forEach((producto) => {
    string += `*${producto.nombre.toUpperCase()}* \n`;
    string += `CANT: ${producto.cantidad}\n`;
    if (producto.requeridos !== {}) {
      string += `DET:\n - Requeridos: `;
      for (const requerido in producto.requeridos) {
        const ingrediente = producto.requeridos[requerido];
        string += `${ingrediente.nombre} - `;
      }
      string += "\n";
    }
    if (producto.extras.length) {
      string += "- Extras: ";
      for (const extra in producto.extras) {
        const ingrediente = producto.extras[extra];
        string += `${ingrediente.nombre} - `;
      }
      string += "\n";
    }
    string += `NOTA: ${producto.nota}\n`;
    string += `PRECIO: $${redondear(producto.precioTotal)}\n `;
    string += "--------------------------------\n";
  });
  string += `*TOTAL: $${redondear(precioProductos)}* \n`;
  string += "--------------------------------\n";
  string += "*RECOGER AQUI* 👇\n";
  string += `${coordsAliado}`;
  return encodeURIComponent(string);
};

const cleanDirection = (str) =>
  str
    .replace(" ,Maracaibo, Zulia, Venezuela", "")
    .replace("Maracaibo, Zulia, Venezuela", "");

export const getComandaEncomienda = ({
  datosOrigen,
  datosDestino,
  notaEnvio = "",
  modoEnvio,
  coords,
  precioDelivery = 0,
  precioDolar = 0,
  comandaId,
}) => {
  const gpsLinks = {
    origen: getGPSLink(coords.origen),
    destino: getGPSLink(coords.destino),
  };

  const precioDeliveryRedondeado = redondear(precioDelivery);

  const string = `${REACT_APP_HOST}
*DomiFavor*
https://plataforma.pideundomi.com/comanda/${comandaId} 
--------------------------------
*Cliente*
Direccion Origen: ${cleanDirection(datosOrigen.direccion)}
GPS Origen: ${gpsLinks.origen} 
--------------------------------
Direccion Entrega: ${cleanDirection(datosDestino.direccion)}
GPS Destino: ${gpsLinks.destino} 
--------------------------------
Nombre: ${datosDestino.nombre}
Contacto: wa.me/${datosDestino.telefono}

Nota de Envio: ${notaEnvio}
Modo Envio: ${modoEnvio}
--------------------------------
*TOTAL: $${precioDeliveryRedondeado}*
*TOTAL BS: ${new Intl.NumberFormat("en-US").format(
    precioDolar * precioDeliveryRedondeado
  )} Bs.S*`;
  return encodeURIComponent(string);
};
