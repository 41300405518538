import { useState, useMemo } from "react";
import {
  calcularPrecioTotalProductos,
  calcularPrecioDelivery,
} from "../../utils/calcularPrecio";
import GoogleLibrary from "../../utils/GoogleMaps";
import {
  getComandaText,
  getCoordsFromGoogle,
  getComandaDirectText,
  getGPSLink,
} from "../../utils/comandaHelper";
import { toast } from "react-toastify";
import limpiarNumero from "../../utils/limpiarNumero";

const useCheckout = ({ direct, crearComanda, setLoading }) => {
  const [distance, setDistance] = useState("0 km");
  const distanceNumber = useMemo(() => {
    return parseFloat(distance.split("km")[0] || 0);
  }, [distance]);
  const [datosCliente, setDatosCliente] = useState({
    referencia: "",
    nombre: "",
    telefono: "",
    notaEnvio: "",
  });
  const [comanda, setComanda] = useState(null);
  const [metodosPago, setMetodosPago] = useState([]);
  const [modoEnvio, setModoEnvio] = useState("moto");
  const [metodoPago, setMetodoPago] = useState(null);
  const [direccionCliente, setDireccionCliente] = useState(null);
  const [loadingPrecio, setLoadingPrecio] = useState(true);
  const [precioDolar, setPrecioDolar] = useState(0);
  const [comandaCreada, setComandaCreada] = useState(null);

  const getPrecioDolar = async () => {
    const getDolarToday = await fetch(
      "https://s3.amazonaws.com/dolartoday/data.json"
    )
      .then((e) => e.json())
      .then((e) => e);
    setLoadingPrecio(false);
    setPrecioDolar(getDolarToday.USD.dolartoday);
  };

  const precioProductos = useMemo(
    () => calcularPrecioTotalProductos(comanda?.productos || []),
    [comanda]
  );
  const precioDelivery = useMemo(() => {
    if (!comanda) return 0;
    const customTarifa = comanda?.aliado?.customTarifa || false;

    const precio = calcularPrecioDelivery({
      distance: distanceNumber,
      tarifa: customTarifa || null,
    });

    return precio;
  }, [distanceNumber, comanda]);

  const handleDatosCliente = ({ target }, inputId) => {
    const { value } = target;
    if (inputId === "telefono" && isNaN(value)) return;
    setDatosCliente({ ...datosCliente, [inputId]: value });
  };

  const datosValidos = () => {
    const _clienteData = datosCliente;
    delete _clienteData.notaEnvio;
    const formDataInvalid = Object.values(_clienteData).some(
      (dato) => !dato.length
    );
    if (
      formDataInvalid ||
      !direccionCliente ||
      !metodoPago & comanda.aliado.metodosPago.length
    ) {
      toast.error(
        "Debes llenar todos los campos para poder procesar el pedido",
        {
          toastId: "empty-fields-checkout",
          autoClose: 3000,
          hideProgressBar: true,
        }
      );
      return false;
    }
    return true;
  };

  const enviarComanda = async () => {
    if (!datosValidos()) return;

    const datosClienteExtraInfo = {
      ...datosCliente,
      direccion: direccionCliente,
      telefono: limpiarNumero(datosCliente.telefono),
    };

    const clienteCoords = getCoordsFromGoogle(GoogleAPI.markers);
    const clienteGPSLink = getGPSLink(clienteCoords);

    const comandaArmada = {
      aliadoId: comanda.aliado._id,
      productos: !direct ? comanda.productos : [],
      metodoPago: metodoPago,
      modoEnvio,
      precioDelivery,
      precioProductos: !direct ? precioProductos : 0,
      datosDestino: datosClienteExtraInfo,
      coords: {
        origen: comanda.aliado.coords,
        destino: clienteCoords,
      },
      tarifa: "basica",
      distance: distanceNumber,
    };

    const comandaResult = await crearComanda("aliado", comandaArmada);

    const comandaId = comandaResult.data._id;

    if (!comandaId || !comandaResult) {
      toast.error("Error generando comanda...");
      setLoading(false);
      return;
    }

    const comandaParams = {
      markers: GoogleAPI.markers,
      comanda,
      metodoPago,
      datosCliente: { ...datosCliente, direccion: direccionCliente },
      precioDelivery,
      precioDolar,
      comandaId: comandaId,
      direccion: datosClienteExtraInfo.direccion,
      referencia: datosClienteExtraInfo.referencia,
      clientGPSLink: clienteGPSLink,
      customTarifa: comanda?.aliado?.customTarifa || false,
      mostrarPrecio: comanda.aliado.mostrarPrecio || false,
      direct,
    };

    const comandaText = direct
      ? getComandaDirectText(comandaParams)
      : getComandaText(comandaParams);

    setComandaCreada({
      comandaParams,
      comandaText,
    });

    const browser = window.open("about:blank", "_blank");

    setLoading(false);

    if (!browser) {
      window.location.href = `whatsapp://send?text=${comandaText}&phone=${comanda.aliado.telefono}`;
    } else {
      browser.location.href = `https://api.whatsapp.com/send?phone=${comanda.aliado.telefono}&text=${comandaText}`;
    }

    return;
  };

  const GoogleAPI = useMemo(
    () => (comanda ? new GoogleLibrary() : null),
    [comanda]
  );

  return {
    distance,
    setDistance,
    distanceNumber,
    metodosPago,
    setMetodosPago,
    modoEnvio,
    setModoEnvio,
    metodoPago,
    setMetodoPago,
    comanda,
    setComanda,
    direccionCliente,
    setDireccionCliente,
    GoogleAPI,
    datosCliente,
    handleDatosCliente,
    enviarComanda,
    precioDelivery,
    precioProductos,
    loadingPrecio,
    precioDolar,
    getPrecioDolar,
    comandaCreada,
    setComandaCreada,
  };
};

export default useCheckout;
