const limpiarNumero = (_numero) => {
  let numero = _numero;
  const caracteres = ["(", ")", "+", "-", " "];

  caracteres.forEach((char) => {
    numero = numero.split(char).join("");
  });

  return numero;
};

export default limpiarNumero;
