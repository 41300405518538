import React from "react";
import { CircularProgress } from "@material-ui/core";
class LazyBackground extends React.Component {
  state = { src: null };

  componentDidMount() {
    const { src } = this.props;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
    return !this.state.src ? (
      <CircularProgress />
    ) : (
      <div
        {...this.props}
        style={{
          backgroundImage: `url(${this.state.src || this.props.placeholder})`,
          backgroundPosition: "center" /* Center the image */,
          backgroundRepeat: "no-repeat" /* Do not repeat the image */,
          backgroundColor: "rgba(0,0,0,0.1)",
          backgroundSize:
            "cover" /* Resize the background image to cover the entire container */,
        }}
      />
    );
  }
}

export default LazyBackground;
