import { userState, appState } from "./initialStates";
import getDistance from "../../utils/getDistance";

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_STATE":
      return { user: userState(), app: appState };
    case "SET_LOGGED":
      return {
        user: { ...state.user, ...action.user },
        app: { logged: true },
      };
    // case "SET_LOCATION":
    //   return {
    //     ...state,
    //     app: {
    //       ...state.app,
    //       location: action.location,
    //     },
    //   };
    case "SET_PRODUCTO":
      return {
        ...state,
        app: {
          ...state.app,
          productos: [...state.app.productos, action.producto],
        },
      };
    case "SET_ALIADOS":
      return {
        ...state,
        app: {
          ...state.app,
          aliados: action.aliados,
          categorias: action.categorias,
        },
      };
    case "SET_LOCATION":
      return {
        ...state,
        app: {
          ...state.app,
          location: action.location,
          aliados: state.app.aliados.map((aliado) => ({
            ...aliado,
            distance: getDistance(
              aliado.coords.lat,
              aliado.coords.lng,
              action.location.lat,
              action.location.lng
            ).toFixed(1),
          })),
        },
      };
    case "MODIFY_PRODUCTO":
      return {
        ...state,
        app: {
          ...state.app,
          productos: state.app.productos.map((producto, i) =>
            i === action.position && producto.aliado === action.aliado
              ? { ...producto, ...action.producto }
              : producto
          ),
        },
      };
    case "REMOVE_PRODUCTO":
      return {
        ...state,
        app: {
          ...state.app,
          productos: state.app.productos.filter(
            (producto, i) => i !== action.position
          ),
        },
      };
    case "SET_MAP":
      return { ...state, app: { ...state.app, map: action.map } };
    case "SET_DISTANCE":
      return { ...state, app: { ...state.app, distance: action.distance } };
    case "SET_LOADING_LOCATION":
      return {
        ...state,
        app: { ...state.app, loadingLocation: action.loading },
      };
    case "SET_DENIED_LOCATION":
      return {
        ...state,
        app: {
          ...state.app,
          deniedLocation: action.denied,
        },
      };
    case "SET_COMANDA":
      return {
        ...state,
        app: {
          ...state.app,
          comanda: action.comanda,
        },
      };
    case "TOGGLE_CHART":
      return {
        ...state,
        app: {
          ...state.app,
          chartOpen: !state.app.chartOpen,
        },
      };
    default:
      console.error("Reducer, type not found...", action.type);
      break;
  }
};

export default reducer;
