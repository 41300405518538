import { createContext, useReducer, useContext } from "react";
import { userState, appState } from "./initialStates";
import reducer from "./reducer";

export const Context = new createContext();

export const ContextProvider = () => {
  const [context, dispatch] = useReducer(reducer, {
    user: userState(),
    app: appState,
  });

  return [Context, context, dispatch];
};

export const useStateValue = () => useContext(Context);
