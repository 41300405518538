import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { toast } from "react-toastify";

const Tag = ({
  text,
  addTag,
  removeTag,
  i,
  existingTag = false,
  setEditingTag = null,
  newTag = false,
}) => {
  const [tempText, setTempText] = useState("");
  return (
    <li
      className={`tag ${newTag ? "editing" : ""}`}
      onClick={() => {
        if (newTag) return;
        if (existingTag) {
          addTag(text);
          return;
        }
        removeTag(i);
      }}
    >
      {!newTag ? (
        <span>{text}</span>
      ) : (
        <input
          type="text"
          value={tempText}
          autoFocus
          onChange={({ target }) => {
            setTempText(target.value);
          }}
          onBlur={() => {
            if (!tempText.length) {
              removeTag(i);
              setEditingTag(false);
            }
          }}
        />
      )}
      {!existingTag && !newTag ? (
        <HighlightOffIcon
          style={{
            fontSize: 18,
            cursor: "pointer",
          }}
        />
      ) : (
        <AddIcon
          style={{
            fontSize: 18,
            cursor: "pointer",
          }}
          onClick={() => {
            if (!newTag) {
              return;
            }
            if (tempText.length < 2) {
              toast.error("Debe tener 3 caracteres como minimo", {
                toastId: "less-3",
                hideProgressBar: true,
                autoClose: 2000,
              });
              return;
            }
            addTag(tempText);
          }}
        />
      )}
    </li>
  );
};

export default Tag;
