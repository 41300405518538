import React, { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { TagInput, OtherTags } from "../../TagInput/TagInput";
import { toast } from "react-toastify";

const AgregarModal = ({
  setModal,
  setAvoidClose,
  context,
  dispatch,
  onSubmit,
  existingCategorias,
}) => {
  const { REACT_APP_API_HOST } = process.env;
  const [loading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [precio, setPrecio] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [editingTag, setEditingTag] = useState(false);

  const addCategoria = (categoria) => {
    if (editingTag) {
      setCategorias([
        ...categorias.filter((categoria) => categoria),
        categoria,
      ]);
      setEditingTag(false);
      return;
    }
    setCategorias([...categorias, categoria]);
  };

  const removeCategoria = (index) =>
    setCategorias((categorias) => categorias.filter((c, i) => i !== index));

  const handleSubmit = async () => {
    const { token, aliado } = context;
    if (!nombre.length) {
      toast.error("Debes ingresar un nombre...", {
        toastId: "missing-nombre",
        hideProgressBar: true,
        autoClose: 1500,
      });
      return;
    }
    if (precio <= 0) {
      toast.error("El precio no puede ser 0...", {
        toastId: "wrong-price",
        hideProgressBar: true,
        autoClose: 1500,
      });
      return;
    }
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setAvoidClose(true);
    const response = await axios
      .post(
        `${REACT_APP_API_HOST}/producto`,
        {
          aliadoId: aliado._id,
          nombre,
          descripcion: descripcion || "",
          precio,
          categorias,
        },
        config
      )
      .catch((err) => {
        return false;
      });
    if (!response) alert("Error al agregar producto!");
    setLoading(false);
    onSubmit(response.data.data);
  };

  const noPickedCategories = useMemo(() => {
    return existingCategorias.filter(
      (cat) => cat !== "Otros" && !categorias.includes(cat)
    );
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorias]);

  return (
    <div className="modal-container edit">
      <h2>Agregar producto</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="input-group" style={{ width: "75%" }}>
              <span
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Nombre Producto
              </span>
              <input
                type="text"
                value={nombre}
                onChange={({ target: { value } }) => setNombre(value)}
                placeholder="Ej: Hamburguesa"
              />
            </div>
            <div className="input-group precio" style={{ width: "20%" }}>
              <span>Precio ($)</span>
              <input
                type="numeric"
                value={precio}
                onChange={({ target: { value } }) => {
                  if (isNaN(value)) return;
                  setPrecio(value);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <span>Descripcion</span>
            <textarea
              type="text"
              value={descripcion}
              // multiline
              onChange={({ target: { value } }) => setDescripcion(value)}
              placeholder="Ej: 200 gr de carne con pan especial"
              style={{
                fontFamily: "Montserrat",
              }}
            />
          </div>
          <div className="input-group">
            <span>Categorias</span>
            <TagInput
              tags={categorias}
              addTag={addCategoria}
              removeTag={removeCategoria}
              editingTag={editingTag}
              setEditingTag={setEditingTag}
            />
          </div>
          {noPickedCategories.length ? (
            <div className="input-group">
              <OtherTags
                addTag={addCategoria}
                noPickedTags={noPickedCategories}
              />
            </div>
          ) : null}
          <p className="info">
            Puedes agregar imagen e ingredientes una vez agregado el producto
          </p>
          <div className="button-group">
            <button className="descartar" onClick={() => setModal(false)}>
              Descartar
            </button>
            <button className="guardar" onClick={() => handleSubmit()}>
              Guardar
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AgregarModal;
