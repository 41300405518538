import React from "react";
import { Grid } from "@material-ui/core";
import { AppContainer } from "./components";
import Carousel from "./Carousel";
import PromoCard from "../PromoCard/PromoCard";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  promosText: {
    color: "#515C6F",
    fontSize: "1.1em",
    fontWeight: 600,
    padding: `0px ${theme.spacing(1)}px`,
  },
  swiperContainer: {
    // background: "red",
  },
  swiper: {
    marginTop: theme.spacing(2),
    // background: "red",
  },
}));

const PromocionesCards = ({
  promos = [
    {
      data: {
        id: 2,
        title: "Ika Roll Promo",
        desc: "Relleno de camaron tempura, cebollin y queso crema, con topping de calamar tempurizado.",
        precio: 4,
        backgroundImg:
          "https://res.cloudinary.com/domi-app/image/upload/v1628883752/domi-img/rvo5ibojox54oqqnkjll.jpg",
      },
      aliado: {
        id: 4,
        nombre: "Kaori",
        perfilImg:
          "https://res.cloudinary.com/domi-app/image/upload/v1628049710/domi-img/vhd2cvnihfmbgxonurbm.jpg",
      },
      link: "https://www.pideundomi.com/aliado/610a0fcb3a59da3cbab998e3",
    },
    {
      data: {
        id: 2,
        title: "Ika Roll Promo",
        desc: "Relleno de camaron tempura, cebollin y queso crema, con topping de calamar tempurizado.",
        precio: 4,
        backgroundImg:
          "https://res.cloudinary.com/domi-app/image/upload/v1628883752/domi-img/rvo5ibojox54oqqnkjll.jpg",
      },
      aliado: {
        id: 4,
        nombre: "Kaori",
        perfilImg:
          "https://res.cloudinary.com/domi-app/image/upload/v1628049710/domi-img/vhd2cvnihfmbgxonurbm.jpg",
      },
      link: "https://www.pideundomi.com/aliado/610a0fcb3a59da3cbab998e3",
    },
  ],
  colores = null,
}) => {
  const classes = useStyles();
  return (
    <Grid item container className={classes.swiper}>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        className={classes.swiperContainer}
      >
        <AppContainer>
          <Carousel title="Carousel">
            {promos.map((promo, key) => {
              return (
                <PromoCard
                  promo={promo.data}
                  aliadoColores={colores || promo.aliado.colores}
                  aliado={promo.aliado}
                  key={key}
                  link={promo.link}
                />
              );
            })}
          </Carousel>
        </AppContainer>
      </Grid>
    </Grid>
  );
};

export default PromocionesCards;
