import { Avatar } from "@material-ui/core";

const AvatarInput = ({
  AvatarSrc,
  field = "perfilImg",
  submitImage = () => {},
  background = false,
}) => (
  <div>
    <input
      accept="image/*"
      id="avatar-file"
      multiple
      type="file"
      style={{ display: "none" }}
      onChange={({ target: { files } }) => submitImage(files[0], field)}
    />
    <label htmlFor="avatar-file">
      <div
        style={{
          padding: 0,
          margin: 0,
          ...(background
            ? {
                background: ` linear-gradient(
            rgba(0, 0, 0,.5), 
            rgba(0, 0, 0,.5)
        ), url('/assets/img/aliados/editAvatar.png') no-repeat center center/ cover `,
              }
            : {}),
          width: 120,
          height: 120,
          borderRadius: 85,
        }}
      >
        <Avatar
          src={AvatarSrc}
          variant="rounded"
          style={{
            height: 120,
            width: 120,
            boxShadow: "0px 6px 10px #727C8E40",
            alignSelf: "center",
            justifySelf: "center",
            transition: "opacity 0.3s",
            WebkitTransition: "opacity 0.3s",
            opacity: 1,
            cursor: "pointer",
          }}
        />
      </div>
    </label>
  </div>
);

export default AvatarInput;
