import React, { useState } from "react";
import { Input, makeStyles } from "@material-ui/core";
import { ChromePicker } from "react-color";
import DoneIcon from "@material-ui/icons/Done";
import useVisible from "../../../providers/hooks/useVisible";
import CloseIcon from "@material-ui/icons/Close";
import "./ColorPicker.css";

const styles = makeStyles({
  popover: {
    position: "absolute",
    zIndex: 2,
    marginTop: "38px",
    top: 0,
  },
  colorPreview: {
    height: "30px",
    width: "30px",
    borderRadius: 6,
    alignSelf: "flex-end",
    marginLeft: "0px",
  },
  pointer: {
    cursor: "pointer",
    margin: "0px 2px",
  },
  title: {
    alignSelf: "flex-end",
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
  titleContainer: {
    width: "100px",
    alignSelf: "flex-end",
  },
});

const ColorPicker = ({ color, title, type, submitColor }) => {
  const [currentColor, setCurrentColor] = useState(color);
  const [editing, setEditing] = useState(false);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const classes = styles(currentColor);

  return (
    <div className="color-picker">
      <span>{title}</span>
      <Input
        onClick={() => {
          // setDisplayColorPicker(true);
          // setEditing(true);
        }}
        value={currentColor}
        style={{
          padding: 0,
          margin: 0,
          marginLeft: 10,
          width: "40%",
          fontWeight: 500,
          color: currentColor,
        }}
        onBlur={() => {
          setIsVisible(false);
        }}
        onFocus={() => {
          setEditing(true);
          // setIsVisible(true);
        }}
        onChange={({ target: { value } }) => setCurrentColor(value)}
      />
      <div
        className={classes.colorPreview}
        style={{
          backgroundColor: `${currentColor}`,
          marginLeft: 10,
          cursor: "pointer",
        }}
        onClick={() => {
          setEditing(true);
          setIsVisible(true);
        }}
      ></div>
      {isVisible ? (
        <div className={classes.popover} ref={ref}>
          <ChromePicker
            color={currentColor}
            onChangeComplete={(newcolor) => {
              setCurrentColor(newcolor.hex);
            }}
          />
        </div>
      ) : null}
      {editing ? (
        <>
          <DoneIcon
            onClick={() => {
              setIsVisible(false);
              submitColor(type, currentColor);
            }}
            className={classes.pointer}
          />
          <CloseIcon
            onClick={() => {
              setIsVisible(false);
              setEditing(false);
              setCurrentColor(color);
            }}
            className={classes.pointer}
          />
        </>
      ) : null}
    </div>
  );
};
export default ColorPicker;
