import React from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const AppHeader = ({
  checkout = false,
  home = false,
  alt = false,
  ShoppingCart = () => <></>,
  BackButton = () => <></>,
  open,
  setOpen,
  direct,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <AppBar className={classes.header} position="fixed">
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: -15,
            }}
            onClick={() => {
              if (checkout) {
                history.push(direct ? "/aliados" : `/aliado/${checkout}`);
                return;
              }
              history.push("/");
            }}
          >
            {!home ? <BackButton /> : null}
            <img
              src={`${direct ? "../.." : alt ? ".." : "."}/assets/img/domi.png`}
              alt="logo"
              style={{ marginLeft: home ? 15 : 0, height: 20, width: "auto" }}
            />
          </div>
          {!checkout && !alt ? (
            <ShoppingCart
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    height: 55,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headerAliado: {
    height: "8vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "white",
    fontWeight: 400,
  },
  headerBar: {
    color: "white",
    // textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  logoAliado: {
    textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
}));

export default AppHeader;
