import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { makeStyles } from "@material-ui/styles";

const MetodosPagoSelect = ({ metodoPago, setMetodoPago, metodosPago }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink id="metodo-pago-label" className={classes.formLabel}>
        Metodo de Pago
      </InputLabel>
      <Select
        labelId="metodo-pago-label"
        id="metodo-pago"
        value={
          metodoPago && typeof metodoPago === "object"
            ? metodoPago._id
            : metodoPago === "efectivo"
            ? "efectivo"
            : 0
        }
        disableUnderline
        displayEmpty
        onChange={(evt) => {
          const { value } = evt.target;
          const resp = metodosPago.find((m) => m._id === value);
          setMetodoPago(resp ? resp : value);
        }}
        // displayEmpty
        className={classes.selectEmpty}
      >
        {metodosPago.map((metodo, idx) => {
          const { _id } = metodo;
          const tipo = String(metodo.tipo.replace("-", " "))
            .split(" ")
            .map((t) => capitalizeFirstLetter(t))
            .join(" ");
          const entidad = String(metodo.entidad)
            .split(" ")
            .map((t) => capitalizeFirstLetter(t))
            .join(" ");
          return (
            <MenuItem value={_id} key={idx}>{`${
              tipo === "Zelle" ? "" : `${tipo} - `
            }${entidad}`}</MenuItem>
          );
        })}
        <MenuItem value="efectivo">Efectivo</MenuItem>
      </Select>
      {/* <FormHelperText
          style={{
            lineHeight: 1.3,
            fontSize: "0.6rem",
          }}
        >
          Debe realizar la transferencia por el monto total que aparece
          abajo a la cuenta que se le mostrara segun el metodo de pago,
          guardar la captura para luego mostrarla en el mensaje que se
          generara a nuestra central una vez hagas click en pagar
        </FormHelperText> */}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: 8,
    marginBottom: theme.spacing(1),
    // background: "red",
  },
  formLabel: {
    color: "#515c6f",
    fontWeight: "bold",
    fontSize: 15,
  },
  selectEmpty: {
    border: "1px solid #ced4da",
    color: "#515c6f",
    borderRadius: 5,
    boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
    fontSize: 13,
    padding: 10,
    paddingLeft: 6,
  },
}));

export default MetodosPagoSelect;
