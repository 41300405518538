import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from "@material-ui/core";
import FormInput from "../../components/inputs/FormInput/FormInput";
import { makeStyles } from "@material-ui/styles";
import AppHeader from "../../components/Header/AppHeader";
import { useHistory, useParams } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "./Comanda.css";
import axios from "axios";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Fetch from "../../providers/Fetch";

const Comanda = () => {
  const classes = useStyles();
  const history = useHistory();
  const { comandaId = "" } = useParams();
  const { REACT_APP_API_HOST } = process.env;
  const [loading, setLoading] = useState(true);
  const [acceso, setAcceso] = useState(false);
  const [settingAcceso, setSettingAcceso] = useState(false);
  const [inputValue, setInputValue] = useState({
    user: "",
    pass: "",
  });
  const [comanda, setComanda] = useState({});
  const [error, setError] = useState(false);
  const [riderId, setRiderId] = useState(null);

  const loadComanda = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_HOST}/comanda/${comandaId}`
      );
      if (response.status === 200 && response.data.status === 200) {
        setComanda(response.data.data);
        setLoading(false);
        return;
      }
      setError(true);
    } catch (err) {
      setError(true);
    }
  };

  // FUNCIONES

  const displayToast = (message = "Datos copiados con exito...") => {
    toast.success(message, {
      autoClose: 1000,
      hideProgressBar: true,
      style: {
        backgroundColor: "#35a4fe",
      },
    });
    return;
  };

  const cancelarRide = async () => {
    const body = {
      riderId: riderId,
      status: 1,
    };
    try {
      const { status } = await Fetch({
        method: "PUT",
        endpoint: `/comanda/${comandaId}/status`,
        body: body,
      });
      if (status === 200) displayToast("Ride cancelado con exito");
      history.go(0);
    } catch (error) {
      console.log(error);
    }
  };

  const finalizarRide = async () => {
    const verificado = window.confirm(
      `Estas seguro de finalizar la comanda?

Esto significa que el pedido ya fue entregado al cliente.

*ESTA ACCION NO SE PUEDE DESHACER...*`
    );
    if (!verificado) return;
    const body = {
      riderId: riderId,
      status: 3,
    };
    try {
      const { status } = await Fetch({
        method: "PUT",
        endpoint: `/comanda/${comandaId}/status`,
        body: body,
      });
      if (status === 200) displayToast("Ride finalizado con exito");
      history.go(0);
    } catch (error) {
      console.log(error);
    }
  };

  const comenzarRide = async () => {
    const body = {
      comandaId: comandaId,
      riderId: riderId,
      status: 2,
    };
    try {
      const { status } = await Fetch({
        method: "PUT",
        endpoint: `/comanda/${comandaId}/status`,
        body: body,
      });
      if (status === 200) displayToast("Ride iniciado con exito");
      setComanda({ ...comanda, status: 2 });
    } catch (error) {
      console.log(error);
    }
  };

  const accederComanda = async () => {
    const displayDatosInvalidos = () => {
      toast.error("Datos de acceso invalidos...", {
        toastId: "comanda-info",
        closeOnClick: true,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
        autoClose: 1500,
      });
    };
    try {
      const {
        data: { data },
        status,
      } = await Fetch({
        method: "POST",
        endpoint: `/rider/auth`,
        body: {
          cedula: inputValue.user,
          clave: inputValue.pass,
        },
      });

      if (status !== 200) {
        displayDatosInvalidos();
        return;
      }

      if (comanda?.status > 1 && data !== comanda?.rider) {
        toast.error("Esta comanda ya esta tomada por otro rider...", {
          toastId: "comanda-info",
          closeOnClick: true,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          autoClose: 1500,
        });
        return;
      }

      setRiderId(data);
      setAcceso(true);
      setSettingAcceso(false);
    } catch (err) {
      displayDatosInvalidos();
    }
  };

  useEffect(() => {
    loadComanda();
  }, []);

  const CopyButton = ({ content = "" }) => (
    <IconButton
      className={classes.noSpace}
      onClick={() => {
        displayToast();
        copy(content);
      }}
    >
      <FileCopyIcon className={classes.copyIcon} />
    </IconButton>
  );

  const ButtonIcon = ({ icon = "" }) => (
    <IconButton className={classes.noSpace}>
      {icon === "ws" ? (
        <WhatsAppIcon
          className={`${classes.copyIcon} ${classes.whatsappIcon}`}
        />
      ) : icon === "map" ? (
        <LocationOnIcon className={classes.LocationOnIcon} />
      ) : null}
    </IconButton>
  );

  const AccessButton = () => (
    <div className={classes.accessButtonContainer}>
      <button
        className={classes.accessButton}
        style={{
          background: "#0DD345",
        }}
        onClick={() => {
          if (comanda?.status === 3) {
            // toast.error("Esta comanda ya fue finalizada...", {
            //   toastId: "comanda-info",
            //   closeOnClick: true,
            //   pauseOnFocusLoss: false,
            //   hideProgressBar: true,
            //   autoClose: 1500,
            // });
            // return;
          }
          setSettingAcceso(true);
        }}
      >
        Acceder a comanda
      </button>
    </div>
  );

  const InfoButton = ({
    texto = "",
    icon = "",
    onClick,
    color = "#009be5",
  }) => (
    <div className={classes.buttonContainer}>
      <button
        className={classes.button}
        style={{
          background: `${color}`, // color 2: #4fc3f7
        }}
        onClick={onClick}
      >
        {<ButtonIcon icon={icon} />}

        <div className={classes.buttonText}>{texto}</div>
      </button>
    </div>
  );

  const StatusBar = () => (
    <>
      <Typography className={classes.aliadoData}>Status: </Typography>
      <Typography className={`${classes.aliadoData} ${classes.statusData}`}>
        {comanda.status === 1 ? "Sin Iniciar" : null}
        {comanda.status === 2 ? "En proceso" : null}
        {comanda.status === 3 ? "Finalizada" : null}
      </Typography>
    </>
  );

  const ErrorComanda = () => (
    <Grid container className={classes.loadingContainer}>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className={classes.loadingText}
      >
        Comanda no encontrada...
      </Typography>
    </Grid>
  );

  const DatosComanda = () => {
    const datosUbicacion =
      comanda.modo === "aliado" ? comanda.aliado : comanda.datosUbicacion;
    const datosDestino =
      comanda.modo === "aliado" ? comanda.cliente : comanda.datosDestino;

    return (
      <div className="datos-cliente">
        <span className={classes.textBold}>
          Cliente: {comanda?.cliente?.nombre || "N/A"}
        </span>
        {comanda.modo === "aliado" ? (
          <InfoButton
            texto="Whatsapp Pickup"
            icon={"ws"}
            onClick={() => {
              window.open(`http://wa.me/${datosUbicacion.telefono}`);
            }}
          />
        ) : null}
        <InfoButton
          texto="Ir Al Mapa De Pickup"
          icon={"map"}
          onClick={() => {
            window.open(datosUbicacion.mapLink);
          }}
        />
        <div
          className={classes.textBold}
          style={{
            marginTop: 8,
            marginBottom: 30,
          }}
        >
          {datosUbicacion.direccion}
        </div>
        <InfoButton
          texto="Whatsapp Entrega"
          icon={"ws"}
          onClick={() => {
            window.open(`http://wa.me/${datosDestino.telefono}`);
          }}
        />
        <InfoButton
          texto="Ir Al Mapa De Entrega"
          icon={"map"}
          onClick={() => {
            window.open(datosDestino.mapLink);
          }}
        />
        <div
          style={{
            marginTop: 8,
            marginBottom: 8,
          }}
          className={classes.textBold}
        >
          {datosDestino.direccion}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 30,
          }}
        >
          <div className={classes.textBold}>Nota de Envio</div>
          <div className={classes.textBold}>{`: ${
            datosDestino.notaEnvio || ""
          }`}</div>
        </div>

        {comanda.status === 2 ? (
          <>
            <InfoButton
              texto="Cancelar Ride"
              color="#DE172B"
              onClick={() => {
                cancelarRide();
              }}
            />
            <InfoButton
              texto="Finalizar Ride"
              color="#0DD345"
              onClick={() => {
                finalizarRide();
              }}
            />
          </>
        ) : null}
        {comanda.status === 1 ? (
          <InfoButton
            texto="Comenzar Ride"
            color="#0DD345"
            onClick={() => {
              comenzarRide();
            }}
          />
        ) : null}
      </div>
    );
  };

  if (error) return <ErrorComanda />;

  if (loading) return <LoadingScreen message="Cargando Comanda" />;

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AppHeader alt />
      <Grid container className={classes.container}>
        <div className={classes.centerContainer}>
          <div className={classes.comandaCenter}>
            <Typography className={classes.comandaTitle}>
              Comanda: #{comandaId}
              <CopyButton content={comandaId} />
            </Typography>
          </div>
          <div className={classes.comandaContainer}>
            {comanda.modo === "aliado" ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                <Avatar
                  src={comanda.aliado.perfilImg}
                  className={classes.aliadoAvatar}
                  variant="rounded"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: 8,
                  }}
                >
                  <Typography className={classes.aliadoTitle}>
                    {comanda.aliado.nombre}
                  </Typography>
                  <div
                    className={`${classes.noSpace} ${classes.aliadoContacto}`}
                  >
                    <Typography
                      className={`${classes.aliadoData} ${classes.aliadoFlex}`}
                    >
                      Precio: ${comanda.precio}
                    </Typography>
                    <Typography
                      className={`${classes.aliadoData} ${classes.aliadoFlex}`}
                      style={{
                        marginTop: 4,
                      }}
                    >
                      Distancia: {comanda.distancia} km
                    </Typography>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={classes.statusBox}>
              <StatusBar />
            </div>
            {acceso ? <DatosComanda /> : null}
            {!acceso && !settingAcceso ? <AccessButton /> : null}
            {settingAcceso ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 14,
                  }}
                >
                  <div className={classes.inputContainer}>
                    <FormInput
                      label="Usuario"
                      placeholder="ejemplo@domi.com"
                      value={inputValue.user}
                      onChange={({ target }) =>
                        setInputValue({ ...inputValue, user: target.value })
                      }
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FormInput
                      label="Clave"
                      placeholder="*******"
                      type="password"
                      value={inputValue.pass}
                      onChange={({ target }) =>
                        setInputValue({ ...inputValue, pass: target.value })
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "48%",
                    }}
                  >
                    <button
                      className={classes.accessButton}
                      style={{ background: "#DE172B" }}
                      onClick={() => {
                        setSettingAcceso(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div
                    style={{
                      width: "48%",
                    }}
                  >
                    <button
                      className={`${classes.accessButton} ${classes.noSpace}`}
                      style={{
                        background: "#0DD345",
                      }}
                      onClick={() => accederComanda()}
                    >
                      Entrar
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {/* {acceso ? <ConfirmButtons /> : null} */}
          </div>
        </div>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    paddingTop: 55,
  },
  container: {
    flexDirection: "column",
    width: "100vw",
    padding: "0px 25px",
  },
  loadingContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  loadingText: {
    color: "#515C6F",
    fontSize: "14px",
    marginTop: 10,
  },
  centerContainer: {
    marginTop: 10,
  },
  inputContainer: {
    width: "48%",
  },
  copyIcon: {
    width: "auto",
    height: 20,
    color: "rgba(0, 0, 0, 0.53)",
    marginLeft: 6,
    cursor: "pointer",
  },
  whatsappIcon: {
    color: "rgba(255, 255, 255)",
    width: 30,
    height: "auto",
    marginLeft: 6,
    marginRight: 6,
  },
  LocationOnIcon: {
    color: "rgba(255, 255, 255)",
    width: 30,
    height: "auto",
    marginLeft: 6,
    marginRight: 6,
  },
  comandaCenter: {
    textAlign: "center",
  },
  comandaTitle: {
    fontSize: 14,
    fontWeight: 500,
    margin: 0,
    padding: 0,
  },
  comandaContainer: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    marginTop: 6,
    padding: 15,
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
  },
  statusBox: {
    marginTop: 6,
    paddingBottom: 6,
    borderBottom: "1px solid rgba(0, 0, 0, 0.21)",
    display: "flex",
    alignItems: "center",
  },
  statusData: {
    marginLeft: 4,
    color: "#EF9700",
    fontWeight: 600,
  },
  aliadoTitle: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    fontWeight: 600,
    marginTop: -4,
  },
  aliadoFlex: {
    display: "flex",
    alignItems: "center",
  },
  aliadoContacto: {
    paddingTop: 8,
    fontWeight: 500,
  },
  aliadoData: {
    fontSize: 14,
    fontWeight: 500,
  },
  aliadoAvatar: {
    height: 70,
    width: 70,
    borderRadius: 5,
    // boxShadow: "0px 4px 8px #727C8E30",
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.21)",
  },
  notaEnvio: {},
  noSpace: { margin: 0, padding: 0 },
  accessButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  accessButton: {
    width: "100%",
    padding: "8px",
    border: 0,
    borderRadius: 5,
    color: "white",
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    marginTop: 8,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    border: 0,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "16px",
    borderRadius: 5,
    cursor: "pointer",
  },
  buttonText: {
    borderRadius: 5,
    color: "white",
    fontSize: 16,
    fontWeight: 550,
    cursor: "pointer",
  },
  textBold: {
    fontSize: 14,
    fontWeight: 600,
  },
}));

export default Comanda;
