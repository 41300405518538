export const userState = () => {
  const user = {
    name: null,
    email: null,
  };
  return user;
};

export const appState = {
  routeDistance: 0,
  map: false,
  location: null,
  loadingLocation: false,
  deniedLocation: false,
  productos: [],
  aliados: [],
  categorias: [],
  comanda: null,
  chartOpen: false,
};
