/* eslint-disable react-hooks/exhaustive-deps */
// TODO: CHECK ESLINT
import { useState, useMemo } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import reorderArray from "../../utils/reorderArray";

const useAliado = ({ aliadoId, notify = () => {}, context, dispatch }) => {
  const { REACT_APP_API_HOST } = process.env;
  const history = useHistory();
  const AliadoData = useMemo(() => {
    const aliado = context.app.aliados.find((a) => a._id === aliadoId) || null;
    if (aliado?.desactivarMenu) history.replace(`/checkout/${aliadoId}`);
    return aliado;
  }, [aliadoId, context.app.aliados]);
  const [loading, setLoading] = useState(true);
  const [selector, setSelector] = useState(null);
  const [productos, setProductos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [activeProducto, setActiveProducto] = useState(null);

  const handleOpen = (producto) => {
    setActiveProducto(producto);
    setOpenModal(true);
  };

  const handleClose = (type = "close", pedido = null) => {
    setOpenModal(false);
    if (type === "submit") notify();
  };

  const handleCategoryChange = (value) => {
    setSelector(value === "Todos" ? null : value);
  };

  const filterProductos = (productoId = null) =>
    productoId ? productos.filter((p) => p._id === productoId) : productos;

  const allProductos = useMemo(
    () => filterProductos(selector ? selector.nombre : null),
    [productos, selector]
  );

  const groupedProductos = useMemo(() => {
    if (!AliadoData || !productos.length) return {};
    const resp = productos.reduce((prev, next) => {
      if (next.categorias.length) {
        next.categorias.forEach((categoria) => {
          if (prev[categoria]) {
            if (!prev[categoria].find((producto) => producto._id === next._id))
              prev[categoria].push(next);
          } else {
            prev[categoria] = [next];
          }
        });
      } else {
        if (prev["Otros"]) {
          prev["Otros"].push(next);
        } else {
          prev["Otros"] = [next];
        }
      }

      return prev;
    }, {});
    if (!AliadoData.ordenCategorias) return resp;
    return reorderArray(resp, AliadoData.ordenCategorias);
  }, [productos]);

  const loadAliado = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_HOST}/aliado/${aliadoId}`
      );

      const { data } = response.data;
      const { activo, productos } = data;

      if (!activo) {
        history.replace("/");
      }

      delete data.productos;

      if (!AliadoData) {
        dispatch({
          type: "SET_ALIADOS",
          aliados: [
            ...context.app.aliados
              .filter((aliado) => aliado.mostrarEnLista)
              .map((aliado) => ({
                ...aliado,
                distance: null,
              })),
            data,
          ],
          categorias: context.app.categorias,
        });
      }

      setProductos(productos);
      setLoading(false);
      document.title += ` - ${data.nombre}`;
    } catch (err) {
      history.replace("/");
    }
  };

  // const findCategoria = (id) => {
  //   const categoria = categorias.find((cat) => cat._id === id);
  //   return categoria ? categoria.nombre : "...";
  // };

  // const getCategoria = useCallback(() => findCategoria(selector), [selector]);

  return {
    loading,
    selector,
    AliadoData,
    openModal,
    activeProducto,
    allProductos,
    groupedProductos,
    loadAliado,
    handleCategoryChange,
    handleClose,
    handleOpen,
  };
};

export default useAliado;
