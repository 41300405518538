import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { toast } from "react-toastify";

const DialogComponent = ({
  dialogOpen,
  handleClose,
  addTempRequerido,
  requeridos,
  nuevosRequeridos,
}) => {
  const [nombre, setNombre] = useState("");
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Agregar Tipo de Ingrediente
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Inserte nombre para crear categoria de ingrediente requerido
        </DialogContentText>
        <TextField
          autoFocus
          // margin="dense"
          id="nombre"
          label="Tipo Ingrediente"
          value={nombre}
          onChange={({ target: { value } }) => {
            setNombre(value);
          }}
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (
              [...Object.keys(requeridos), ...nuevosRequeridos].includes(nombre)
            ) {
              toast.warn("Ya existe una categoria con ese nombre...", {
                autoClose: 2500,
                hideProgressBar: true,
                toastId: "already-exist-requerido",
              });
              return;
            }
            if (!nombre.length) return;
            addTempRequerido(nombre);
            setNombre("");
          }}
          color="primary"
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
