import { createContext, useReducer, useContext } from "react";

export const Context = new createContext();

export const ContextProvider = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "RESET_STATE":
        return { token: null, aliado: null };
      case "SET_ALIADO":
        return {
          ...state,
          token: action.token,
          aliado: action.aliado,
        };
      case "AGREGAR_PRODUCTO":
        return {
          ...state,
          aliado: {
            ...state.aliado,
            productos: [...state.aliado.productos, action.producto],
          },
        };
      case "UPDATE_ALIADO":
        return {
          ...state,
          nav: action.nav ? action.nav : state.nav,
          aliado: {
            ...state.aliado,
            ...action.payload,
          },
        };
      case "UPDATE_PRODUCTO":
        return {
          ...state,
          aliado: {
            ...state.aliado,
            productos: state.aliado.productos.map((producto) => {
              if (producto._id === action._id) {
                return { ...producto, ...action.payload };
              }
              return producto;
            }),
          },
        };
      case "REMOVE_PRODUCTO":
        return {
          ...state,
          aliado: {
            ...state.aliado,
            productos: state.aliado.productos.filter(
              (producto) => producto._id !== action._id
            ),
          },
        };
      case "SET_NAV":
        return {
          ...state,
          nav: action.nav,
        };
      default:
        console.error("Reducer, type not found...", action.type);
        break;
    }
  };
  const [context, dispatch] = useReducer(reducer, {
    token: null,
    aliado: null,
    nav: "tienda",
  });

  return [Context, context, dispatch];
};

export const useStateValue = () => useContext(Context);
