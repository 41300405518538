import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Tag from "./Tag";
import { toast } from "react-toastify";

export const TagInput = ({
  tags,
  addTag,
  removeTag,
  editingTag,
  setEditingTag,
}) => {
  const checkEditing = () => {
    if (editingTag) {
      toast.error("Primero guardar tag en edicion", {
        toastId: "first-save-tag",
        hideProgressBar: true,
      });
      return false;
    }
    return true;
  };

  return (
    <ul className="tag-container">
      {tags.map((tag, idx) => (
        <Tag
          text={tag}
          i={idx}
          key={idx}
          addTag={addTag}
          removeTag={removeTag}
          newTag={!tag ? true : false}
          setEditingTag={setEditingTag}
        />
      ))}
      <li
        className="tag add"
        onClick={() => {
          if (!checkEditing()) return;
          addTag(null);
          setEditingTag(true);
        }}
      >
        <AddIcon />
        <span>Agregar categoria</span>
      </li>
    </ul>
  );
};

export const OtherTags = ({ noPickedTags, addTag }) => (
  <div className="categorias-container">
    <span className="texto">Categorias de mis otros productos:</span>
    <div className="tag-container empty">
      {noPickedTags.map((tag, idx) => (
        <Tag i={1} addTag={addTag} text={tag} existingTag key={idx} />
      ))}
    </div>
  </div>
);

export default TagInput;
