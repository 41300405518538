var directionsService;
var directionsRenderer;

class GoogleMaps {
  constructor() {
    this.googleDOMObject = null;
    this.map = null;
    this.markers = [];
    this.ubicacion = null;
    this.destino = null;
    this.distanceCallback = null;
    this.placespredictions = null;
    this.autoComplete = null;
    this.routeDistance = null;
    this.dragListener = null;
  }

  initializeMap({ location, mapContainerId, distanceCallback }) {
    this.map = new window.google.maps.Map(
      document.getElementById(mapContainerId),
      {
        center: location,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        zoom: 12,
        scaleControl: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        draggable: true,
        gestureHandling: "greedy",
      }
    );
    this.distanceCallback = distanceCallback;
  }

  existMarker(markerId) {
    return this.markers.find((marker) => marker.markerId === markerId);
  }

  async dragendHandler(marker, callbackDirection) {
    this.cleanRenderer();
    this.updateRoutes();
    const place = await this.getPlace(marker);

    if (callbackDirection && typeof callbackDirection === "function") {
      callbackDirection(place.description);
      return;
    }
  }

  async setMarker({
    placeId = null,
    markerId,
    position = null,
    draggable = true,
    callbackDirection = null,
    sessionToken = null,
  }) {
    const placeMarkerByPlaceID = () =>
      new Promise((resolve, reject) => {
        try {
          const service = new window.google.maps.places.PlacesService(this.map);

          const PLACES_QUERY = {
            placeId,
            fields: ["geometry"],
            sessionToken,
          };

          // Gets Google Places API basic data (autocomplete is free and you're billed by session)

          service.getDetails(PLACES_QUERY, (place, status) => {
            const error =
              status !== window.google.maps.places.PlacesServiceStatus.OK;
            if (error) {
              console.log("Throwed new Error...");
              throw new Error(status);
            }

            const marker = new window.google.maps.Marker({
              map: this.map,
              position: place.geometry.location,
              draggable,
            });

            marker.set("markerId", markerId);
            this.markers.push(marker);

            window.google.maps.event.addListener(marker, "dragend", () => {
              this.dragendHandler(marker, callbackDirection);
            });

            this.updateRoutes();

            resolve();
          });
        } catch (err) {
          console.log("Error happened in placeMarker...");
          reject();
        }
      });

    const placeMarkerByPosition = () => {
      const marker = new window.google.maps.Marker({
        map: this.map,
        position: position,
        draggable,
      });

      marker.set("markerId", markerId);
      if (!this.existMarker(markerId)) this.markers.push(marker);

      window.google.maps.event.addListener(marker, "dragend", () => {
        this.dragendHandler(marker, callbackDirection);
      });
      
      this.updateRoutes();
    };

    try {
      if (!markerId) return false;
      this.removeMarker(markerId);
      if (position) placeMarkerByPosition();
      if (placeId) placeMarkerByPlaceID();
    } catch (err) {
      console.log("Error setMarker", err);
    }
  }

  removeMarker(markerId) {
    const marker = this.existMarker(markerId);
    if (!marker) {
      return false;
    }

    marker.setMap(null);
    this.markers = this.markers.filter(
      (marker) => marker.markerId !== markerId
    );
    return true;
  }

  setDestino(destino) {
    this.destino = destino;
  }

  cleanMap() {
    this.markers = [];
    this.ubicacion = null;
    this.destino = null;
    this.placespredictions = null;
    this.autoComplete = null;
    this.routeDistance = null;
    this.dragListener = null;
  }

  setDragListener(listener) {
    this.dragListener = listener;
  }

  setUbicacion(ubicacion) {
    this.ubicacion = ubicacion;
  }

  setCheckOutMarkers(location, markersArray) {
    markersArray.push({
      name: "You are here",
      lat: location.lat,
      lng: location.lng,
    });

    markersArray.forEach((item) => {
      const image = item.icon ? item.icon : null;
      new this.googleDOMObject.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        map: this.map,
        title: item.name,
        icon: image,
      });
    });
  }

  async calculateAndDisplayRoute(route) {
    const getRoute = (req) => {
      return new Promise((resolve, reject) => {
        directionsService.route(
          { travelMode: "DRIVING", ...req },
          function (response, status) {
            if (status === "OK") {
              directionsRenderer.setDirections(response);
              resolve(response.routes[0].legs[0].distance.text);
            } else {
              reject(false);
              console.error("Error en Calculate and Dispaly");
            }
          }
        );
      });
    };

    directionsService = new window.google.maps.DirectionsService();
    directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(this.map);
    directionsRenderer.setOptions({
      suppressMarkers: true,
      draggable: false,
    });
    try {
      const distance = await getRoute(route);
      if (this.distanceCallback) this.distanceCallback(distance);
      return distance;
    } catch (err) {
      console.log("Error en calculateAndDisplayRoute", err);
      return false;
    }
  }

  async updateRoutes() {
    // TODO: considerar varias rutas
    const condition = this.markers.length < 2;
    if (condition) return;
    const origin = this.markers[0].position;
    const destination = this.markers[1].position;
    const route = {
      origin: {
        lat: origin.lat(),
        lng: origin.lng(),
      },
      destination: {
        lat: destination.lat(),
        lng: destination.lng(),
      },
    };

    this.calculateAndDisplayRoute(route);
  }

  calcBounds() {
    if (!this.map) return;
    var bounds = this.map.getBounds();
    var areaBounds = {
      north: bounds.getNorthEast().lat(),
      south: bounds.getSouthWest().lat(),
      east: bounds.getNorthEast().lng(),
      west: bounds.getSouthWest().lng(),
    };

    return areaBounds;
  }

  async getPlace(marker, raw) {
    return new Promise((resolve, reject) => {
      try {
        const geocoder = new window.google.maps.Geocoder({
          language: "es-419",
        });
        geocoder.geocode(
          {
            location: {
              lat: raw ? marker.position.lat : marker.position.lat(),
              lng: raw ? marker.position.lng : marker.position.lng(),
            },
          },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              const { place_id } = results[0];
              const firstDescription = results[1].formatted_address.replace(
                /(, Maracaibo )\d{4}(, Zulia, Venezuela)/,
                ""
              );
              const secondDescription = results[0].formatted_address.replace(
                /(, Maracaibo )\d{4}(, Zulia, Venezuela)/,
                ""
              );
              const description = `${secondDescription}, ${firstDescription}`;
              //This is placing the returned address in the 'Address' field on the HTML form
              // console.log(
              //   address.replace(/(, Maracaibo )\d{4}(, Zulia, Venezuela)/, "")
              // );
              resolve({
                place_id,
                description,
              });
              // console.log(this.destino);
              // this.callbacks.handleLocationData.origen(results[0]);
              // document.getElementById("Address").value = ;
            }
          }
        );
      } catch (err) {
        reject(null);
      }
    });
  }

  deleteMarker(markerId) {
    this.markers.forEach((marker, index) => {
      if (marker.id === markerId) {
        marker.setMap(null);
        this.markers.splice(index, 1);
      }
    });
    this.cleanRenderer();
  }

  cleanRenderer() {
    if (!directionsRenderer) {
      return false;
    }
    directionsRenderer.setMap(null);
    directionsRenderer = null;
    this.distanceCallback("0 km");
    return true;
  }

  cleanMarkers() {
    this.markers = [];
    this.cleanRenderer();
  }
}

export default GoogleMaps;
