import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";

const inicialAgregar = {
  _id: null,
  nombre: "",
  precio: "",
  requerido: false,
  tipo: "",
};

const TablaIngredientes = ({
  opcionales = [],
  tipo = "opcional",
  modifyIngrediente,
  addIngrediente,
  retirarIngrediente,
  requeridos = null,
  temporal = false,
}) => {
  const [modoAgregar, setModoAgregar] = useState(false);
  const [modoEditar, setModoEditar] = useState(false);
  const [modificado, setModificado] = useState(false);

  const handleInput = (field, value, type = "agregar") => {
    if (!modificado) setModificado(true);
    if (type === "agregar") {
      setModoAgregar((ingrediente) => ({ ...ingrediente, [field]: value }));
      return;
    }
    setModoEditar((ingrediente) => ({ ...ingrediente, [field]: value }));
  };

  return (
    <div className="tabla-productos">
      <div className="tabla-title">
        <div>
          <h2>{tipo === "opcional" ? "Extras" : tipo}</h2>
        </div>
        <div className="right-container">
          <div
            className="agregar-container"
            onClick={() => {
              if (modoAgregar) return;
              setModoAgregar({ ...inicialAgregar, tipo });
              setModoEditar(false);
              //   setProductoSelected({ type: "agregar" });
              //   setModal(true);
            }}
          >
            <AddBoxIcon />
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Nombre Ingrediente</th>
            <th>Precio ($)</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {modoAgregar ? (
            <tr>
              <td>
                <TextField
                  type="text"
                  value={modoAgregar.nombre}
                  onChange={({ target: { value } }) => {
                    handleInput("nombre", value, "agregar");
                  }}
                  autoFocus
                />
              </td>
              <td>
                <TextField
                  type="text"
                  value={modoAgregar.precio || ""}
                  onChange={({ target: { value } }) => {
                    if (isNaN(value)) return;
                    handleInput("precio", value, "agregar");
                  }}
                />
              </td>
              <td>
                <div>
                  <CheckIcon
                    style={{
                      color: "green",
                      fontSize: 26,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addIngrediente(modoAgregar, temporal ? tipo : false);
                      setModoAgregar(false);
                      return;
                    }}
                  />
                  <CancelIcon
                    style={{
                      color: "#BF211E",
                      fontSize: 26,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setModoAgregar(false);
                      return;
                    }}
                  />
                </div>
              </td>
            </tr>
          ) : null}
          {(tipo === "opcional" ? opcionales : requeridos).map(
            (producto, idx) => {
              const { _id, nombre, precio } = producto;
              return (
                <tr key={idx + 1}>
                  <td>
                    {modoEditar && modoEditar._id === _id ? (
                      <TextField
                        type="text"
                        value={modoEditar.nombre}
                        onChange={({ target: { value } }) => {
                          handleInput("nombre", value, "modificar");
                        }}
                        autoFocus
                      />
                    ) : (
                      nombre
                    )}
                  </td>
                  <td>
                    {modoEditar && modoEditar._id === _id ? (
                      <TextField
                        type="text"
                        value={modoEditar.precio || ""}
                        onChange={({ target: { value } }) => {
                          if (isNaN(value)) return;
                          handleInput("precio", value, "modificar");
                        }}
                      />
                    ) : precio ? (
                      `$${precio}`
                    ) : (
                      "S/P"
                    )}
                  </td>
                  <td>
                    {modoEditar && modoEditar._id === _id ? (
                      <div>
                        <CheckIcon
                          style={{
                            color: "green",
                            fontSize: 26,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            modifyIngrediente(modoEditar);
                            setModoEditar(false);
                            return;
                          }}
                        />
                        <CancelIcon
                          style={{
                            color: "#BF211E",
                            fontSize: 26,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModoEditar(false);
                            return;
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <EditIcon
                          style={{
                            color: "grey",
                            cursor: "pointer",
                            marginRight: 2,
                          }}
                          onClick={() => {
                            if (modoEditar) {
                              modifyIngrediente(modoEditar);
                              return;
                            }
                            setModoEditar({
                              _id,
                              nombre,
                              precio,
                            });
                            //   setModal(true);
                            //   setProductoSelected({
                            //     type: "edit",
                            //     ...producto,
                            //   });
                          }}
                        />
                        <DeleteForeverIcon
                          style={{
                            color: "#BF211E",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            //   retirarProducto(producto._id, producto.nombre);
                            retirarIngrediente(producto);
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TablaIngredientes;
