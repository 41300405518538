import React from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const LoadingScreen = ({ message = "" }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.loadingContainer}>
      <CircularProgress />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className={classes.loadingText}
      >
        {message}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  loadingText: {
    color: "#515C6F",
    fontSize: "14px",
    marginTop: 10,
  },
}));

export default LoadingScreen;
