import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography, IconButton } from "@material-ui/core";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import CantidadInput from "../../../inputs/CantidadInput/CantidadInput";
import { calcularPrecioProducto } from "../../../../utils/calcularPrecio";

const ChartProducto = (props) => {
  const {
    dispatch,
    nombre,
    precio,
    cantidad,
    extras,
    requeridos,
    productoImg,
    i,
  } = props;
  const styles = useStyles();

  const chartFunction = () => {
    dispatch({
      type: "REMOVE_PRODUCTO",
      position: i,
      aliado: props.aliado,
    });
  };

  return (
    <div className={styles.root}>
      <Avatar src={productoImg} className={styles.avatar} />
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={styles.title}>{nombre}</Typography>
          <IconButton
            style={{
              height: 16,
              width: 16,
              padding: 0,
              margin: 0,
            }}
            onClick={() => {
              const response = window.confirm(
                "Seguro que deseas retirar producto?"
              );
              if (!response) return;
              chartFunction();
            }}
          >
            <RemoveShoppingCartIcon
              style={{
                height: 16,
                width: 16,
              }}
            />
          </IconButton>
        </div>
        {/* <Typography className={styles.desc}>{descripcion}</Typography> */}
        {(extras.length || requeridos.length) && (
          <Typography
            className={`${styles.desc} ${styles.descExtra}`}
            style={{ marginTop: 5 }}
          >
            Con extras/adicionales
          </Typography>
        )}
        <Typography className={styles.precio}>
          ${precio} - ($
          {calcularPrecioProducto({ precio, requeridos, extras, cantidad })})
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CantidadInput
            chart={chartFunction}
            cantidad={cantidad}
            className={styles.desc}
            setCantidad={(cantidad) => {
              dispatch({
                type: "MODIFY_PRODUCTO",
                position: i,
                producto: {
                  cantidad,
                },
                aliado: props.aliado,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grises.darkblue,
    textTransform: "capitalize",
    lineHeight: "1.9vh",
  },
  desc: {
    color: theme.palette.grises.darkblue,
    fontWeight: 400,
    fontSize: "1.5vh",
    lineHeight: "1.7vh",
  },
  descExtra: {
    fontWeight: 300,
    fontSize: "1.3vh",
    lineHeight: "1.2vh",
    fontStyle: "italic",
  },
  precio: {
    color: theme.palette.grises.darkblue,
    fontWeight: 700,
    fontSize: "1.7vh",
  },
  avatar: {
    height: "5vh",
    width: "5vh",
    borderRadius: 15,
    boxShadow: "0px 6px 10px #727C8E40",
  },
}));

export default ChartProducto;
