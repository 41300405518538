const getFecha = () => {
  const date = new Date();

  const fecha = {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    //   hora: date
  };

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${fecha.day}/${fecha.month + 1}/${
    fecha.year
  } - ${hours}:${minutes}${ampm}`;
};

export default getFecha;
