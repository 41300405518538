import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import debounce from "lodash/debounce";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import "./Autoplacement.css";
import { toast } from "react-toastify";
import requestLocation from "../../../utils/requestLocation";
import {
  isGoogleMapsLink,
  extractCoordinates,
} from "../../../utils/googleHelpers";

const autocompleteService = { current: null };

const Autoplacement = ({
  placeholder,
  markerId,
  GoogleAPI,
  context,
  dispatch,
  setOpen = () => {},
  setOpenOther = () => {},
  direction = null,
  setDirection = () => {},
  setEditingParent = () => {},
  loaded = false,
}) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [editing, setEditing] = useState(true);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);

  const cleanInput = () => {
    setEditingParent(true);
    setEditing(false);
    setSuggestions([]);
    setDirection(null);
  };

  const cleanMarker = () => {
    GoogleAPI.removeMarker(markerId);
    GoogleAPI.cleanRenderer();
    cleanInput();
    setEditing(true);
    setEditingParent(false);
  };

  const callbackDirection = (direction) => setDirection(direction);

  const setMyLocation = async (direct = false) => {
    const callBack = async (location) => {
      try {
        const result = await GoogleAPI.getPlace({ position: location }, true);

        GoogleAPI.cleanRenderer();

        //This marker is set by Geolocation
        GoogleAPI.setMarker({
          markerId,
          position: location,
          draggable: true,
          callbackDirection,
        });

        setDirection(result.description);
        setEditingParent(true);
        setEditing(false);
        setOpen(true);
        setOpenOther(false);
      } catch (err) {
        console.log("Error configurando ubicacion...");
        console.log("err", err);
      }
    };

    if (context.app.deniedLocation) {
      toast.warn(
        "Se ha denegado la autorizacion a la geolocalizacion, para utilizar esta funcion debes dar los permisos al navegador",
        {
          autoClose: 5500,
          hideProgressBar: true,
          toastId: "geoloc-required",
        }
      );
      return;
    }

    if (!context.app.location) {
      setLoadingLocation(true);
      try {
        const coords = await requestLocation(dispatch);
        const { latitude, longitude } = coords;
        callBack({ lat: latitude, lng: longitude });
        setLoadingLocation(false);
      } catch (err) {
        setLoadingLocation(false);
      }
      return;
    }

    callBack(context.app.location);
  };

  const requestAddressData = (addressInput = "") => {
    if (!window.google || !addressInput || fetching) {
      if (suggestions.length) setSuggestions([]);
      return;
    }

    if (!autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (
      (!autocompleteService.current ||
        addressInput.replace(/ /g, "").length === 0 ||
        addressInput === "") &&
      editing
    ) {
      return;
    }

    let newSessionToken;

    if (!sessionToken && window.google.maps) {
      // Sending the token to predictions and placeMarker lets Google know is the same session request.
      const getNewSessionToken =
        new window.google.maps.places.AutocompleteSessionToken();

      setSessionToken(getNewSessionToken);
      newSessionToken = getNewSessionToken;
    }
    const areaBounds = GoogleAPI.calcBounds();
    const settings = {
      input: addressInput,
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "ve" },
      bounds: areaBounds,
      strictBounds: true,
      language: "es-419",
      sessionToken: newSessionToken || sessionToken,
    };

    autocompleteService.current.getPlacePredictions(
      settings,
      (results = []) => {
        setFetching(false);
        if (results === null) return;
        setSuggestions(results);
      }
    );
    setFetching(true);
    setLoadingRequest(false);
  };

  const setSelectedPlaceMarker = (selectedPlaceObj) => {
    setDirection(selectedPlaceObj.description || "Sin direccion");
    GoogleAPI.cleanRenderer();
    GoogleAPI.setMarker({
      markerId,
      placeId: selectedPlaceObj.place_id,
      callbackDirection,
      sessionToken,
    });
    setSessionToken(null);
    setEditingParent(true);
    setEditing(false);
    setOpen(true);
    setOpenOther(false);
  };

  useEffect(() => {
    if (!loadingLocation && loaded) {
      setMyLocation(true);
    }
  }, [loadingLocation, loaded]);

  const EditingComponent = () => (
    <>
      <Typography
        style={{
          color: "#515C6F",
          fontWeight: "bold",
          fontSize: 13,
        }}
      >
        {capitalizeFirstLetter(markerId)}
      </Typography>
      <div
        style={{
          display: "flex",
        }}
      >
        <Typography
          style={{
            color: "rgb(148,147,147)",
            fontSize: 12,
            width: "85%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {direction}
        </Typography>
        <div
          style={{
            width: "15%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <EditIcon
            style={{
              color: "grey",
              cursor: "pointer",
            }}
            onClick={() => {
              setEditing(true);
              setEditingParent(false);
            }}
          />
          <HighlightOffIcon
            style={{
              color: "#880D1E",
              cursor: "pointer",
            }}
            onClick={() => cleanMarker()}
          />
        </div>
      </div>
    </>
  );
  const setCustomLocation = async (customLocation) => {
    const result = await GoogleAPI.getPlace({ position: customLocation }, true);

    GoogleAPI.cleanRenderer();
    GoogleAPI.setMarker({
      markerId,
      position: customLocation,
      draggable: true,
      callbackDirection,
    });

    setDirection(result.description);
    setEditingParent(true);
    setEditing(false);
    setOpen(true);
    setOpenOther(false);
  };

  return (
    <>
      <div
        style={{
          display: !editing ? "none" : "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Autocomplete
          className={classes.autoComplete}
          id="google-map-demo"
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          filterOptions={(x) => x}
          options={suggestions}
          autoComplete
          includeInputInList
          loading={loadingRequest}
          filterSelectedOptions
          value={GoogleAPI.destino}
          noOptionsText="..."
          loadingText="Cargando..."
          onChange={(event, selectedPlaceObj) => {
            setSelectedPlaceMarker(selectedPlaceObj);
          }}
          onInputChange={debounce((event, addressValue) => {
            if (event.type === "change") {
              const isGoogleLink = isGoogleMapsLink(addressValue);
              if (isGoogleLink) {
                const coords = isGoogleLink.extractCoords(addressValue);
                if (!coords) return;
                setCustomLocation(coords);
                return;
              }
              requestAddressData(addressValue);
            }
          }, 1000)}
          classes={{ popupIndicator: classes.popupIndicator }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              // fullWidth
              classes={{ root: classes.customTextField }}
            />
          )}
          renderOption={(option) => {
            if (!option) return;
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
        <Button
          style={{
            minWidth: 50,
            borderRadius: 5,
            boxShadow: "0px 3px 8px #00000029",
            border: "1px solid #ced4da",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            background: "white",
          }}
          onClick={() => {
            if (!loadingLocation) setMyLocation();
          }}
        >
          {loadingLocation ? (
            <CircularProgress
              color="secondary"
              style={{
                height: 15,
                width: 15,
              }}
            />
          ) : (
            <LocationOnIcon color="secondary" />
          )}
        </Button>
      </div>
      {!editing && <EditingComponent />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  input: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    borderRadius: "5px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
  },
  popupIndicator: {
    display: "none",
  },
  customTextField: {
    "& input": {
      color: "#515C6F",
      fontSize: 12,
      // border: "1px solid #ced4da",
      borderRadius: 5,
      border: "none",
    },
    "& input::placeholder": {
      fontSize: 12,
      // color: "#c5c9cf",
      padding: 0,
      margin: 0,
    },
  },
  autoComplete: {
    width: "calc(100% - 60px)",
  },
}));

export default React.memo(Autoplacement);
