import React from "react";
import { Typography } from "@material-ui/core";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import CakeIcon from "@material-ui/icons/Cake";
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import "./CategoriaProductos.css";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";

const CategoriaProductos = ({
  categorias = [],
  handleCategoryChange,
  selector = "",
  colores,
}) => {
  const iconos = (categoria, props) => {
    switch (categoria) {
      case "Hamburguesas":
        return <FastfoodIcon {...props} size={"md"} />;
      case "Pizzas":
        return <LocalPizzaIcon {...props} size={"md"} />;
      case "Refrescos":
        return <LocalDrinkIcon {...props} size={"md"} />;
      case "Bebidas":
        return <LocalDrinkIcon {...props} size={"md"} />;
      case "Cocteles":
        return <LocalBarIcon {...props} size={"md"} />;
      case "Postres":
        return <CakeIcon {...props} size={"md"} />;
      case "Otros":
        return <LocalGroceryStoreIcon {...props} size={"md"} />;
      default:
        return <ExpandMoreOutlined {...props} size={"md"} />;
    }
  };

  const Categoria = ({ Icon, title = "", selected = false }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginRight: 15,
        width: 70,
      }}
      onClick={() => {
        handleCategoryChange(title);
      }}
    >
      <div
        style={{
          height: 67,
          width: 67,
          border: `2px solid ${colores.primary}`,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: selected ? colores.primary : "",
        }}
      >
        <Icon
          selected={selected}
          style={{
            color: !selected ? colores.primary : "white",
            fontSize: 35,
          }}
        />
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          alignSelf: "center",
          marginTop: "5px",
          minHeight: 20,
          width: "100%",
          textAlign: "center",
          wordWrap: "break-word",
          fontSize: 8,
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </div>
  );

  return (
    <div
      className="categorias"
      style={{
        width: "98vw",
        overflowX: "scroll",
        display: "flex",
        flexDirection: "row",
        paddingLeft: 8,
        paddingBottom: 8,
      }}
    >
      <Categoria
        title="Todos"
        selected={!selector || selector === "Todos"}
        Icon={(props) => <StoreMallDirectoryIcon {...props} size={"md"} />}
      />
      {categorias.map((categoria, idx) => {
        return (
          <Categoria
            title={categoria}
            selected={selector === categoria}
            Icon={(props) => iconos(categoria, props)}
            key={idx}
          />
        );
      })}
    </div>
  );
};

export default CategoriaProductos;
