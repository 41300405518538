import React from "react";
import Input from "../FormInput/FormInput";
import { makeStyles } from "@material-ui/styles";

const DatosEnvio = ({
  id,
  open,
  datosCliente,
  handleDatosCliente = () => {},
  hideNombreCasa = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {open ? (
        <>
          {!hideNombreCasa ? (
            <Input
              inputID={`direccion-${id}`}
              rows={1}
              label="Nombre de Edificio / Casa"
              placeholder="Ej: Punto de Referencia"
              value={datosCliente.referencia}
              onChange={(evt) => handleDatosCliente(evt, "referencia")}
            />
          ) : null}
          <div className={classes.flexContainer}>
            <div className={classes.half}>
              <Input
                inputID={`nombre-${id}`}
                rows={1}
                label="Nombre"
                placeholder="Ej: Pedro Perez"
                fullWidth={false}
                value={datosCliente.nombre}
                onChange={(evt) => handleDatosCliente(evt, "nombre")}
              />
            </div>
            <div className={classes.half}>
              <Input
                inputID={`contacto-${id}`}
                label="Telefono"
                placeholder="Ej: 424-6412886"
                value={datosCliente.telefono}
                type="tel"
                onChange={(value) => {
                  handleDatosCliente({ target: { value: value } }, "telefono");
                }}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: { display: "flex", flexDirection: "column", width: "100%" },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  half: { width: "48%" },
}));

export default DatosEnvio;
