import redondear from "./redondear";

export const calcularPrecioTotalProductos = (productos) => {
  return productos.length > 0
    ? redondear(
        productos.reduce((prev, next) => {
          const { cantidad, requeridos, extras, precio } = next;
          const precioTotal = calcularPrecioProducto({
            cantidad,
            requeridos,
            extras,
            precio,
          });
          const formated = redondear(precioTotal);
          return prev + formated;
        }, 0)
      )
    : 0;
};

export const calcularPrecioProducto = ({
  precio,
  requeridos,
  extras,
  cantidad = 1,
}) => {
  // Detectar requeridos que tienen precio
  const precioRequeridos = Object.keys(requeridos).reduce((prev, next) => {
    const ingrediente = requeridos[next];
    return ingrediente.precio && ingrediente.precio !== "undefined"
      ? prev + ingrediente.precio
      : prev;
  }, 0);
  // Sumar precio de extras
  const precioExtras = extras.reduce((prev, next) => prev + next.precio, 0);
  // Sumar ingredientes extras e ingredientes requeridos con precio
  return redondear((precio + precioRequeridos + precioExtras) * cantidad);
};

const formulaPrecioDelivery = ({
  minDistance,
  minTarifa,
  distance,
  tarifa,
}) => {
  return distance <= minDistance
    ? minTarifa
    : (distance - minDistance) * tarifa + minTarifa;
};

const TARIFA_STANDARD = {
  moto: 0.7,
  carro: 1,
};

export const calcularPrecioDelivery = ({
  distance,
  modo = "moto",
  tarifa = false,
  domiFavor = false,
}) => {
  const finalTarifa = tarifa ? tarifa : TARIFA_STANDARD;

  const minDistance = {
    moto: 2.2,
    carro: 2.2,
  };

  const props = {
    minDistance: minDistance[modo],
    distance: distance,
    tarifa: finalTarifa[modo],
  };

  if (domiFavor) {
    // Si es domi favor y es moto, tarifa calcular tarifa a 0.75
    return formulaPrecioDelivery({
      ...props,
      minTarifa: modo === "moto" ? 3 : 4,
    });
  }

  return formulaPrecioDelivery({
    ...props,
    minTarifa: modo === "moto" ? 2 : 4,
  });
};
