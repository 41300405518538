import React, { useEffect, useState } from "react";
import moment from "moment";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import "moment/locale/es-us";
import capitalize from "lodash/capitalize";

moment.locale("es");

const FECHA_ABREVIADA = "MMMM DD";

const WeekSelector = ({ selectedWeek, setSelectedWeek, weeks, setWeeks }) => {
  const [open, setOpen] = useState(false);
  const triggerMenu = () => setOpen(!open);

  const WeekItem = ({ week }) => (
    <div
      className="week-item"
      onClick={() => {
        setSelectedWeek(week);
        triggerMenu();
      }}
    >
      <span>{week.label}</span>
      <ArrowForwardIos />
    </div>
  );

  const getUltimasSemanas = () => {
    const semanas = [];
    for (let i = 0; i < 5; i++) {
      const inicioSemana = moment().subtract(i, "weeks").startOf("week");
      const finDeSemana = moment().subtract(i, "weeks").endOf("week");
      const inicioLabel = inicioSemana.format(FECHA_ABREVIADA);
      const finLabel = finDeSemana.format(FECHA_ABREVIADA);

      semanas.push({
        start: inicioSemana.toISOString(),
        ends: finDeSemana.toISOString(),
        label: `${capitalize(inicioLabel)} - ${capitalize(finLabel)}`,
      });
    }
    setWeeks(semanas || []);
    setSelectedWeek(semanas[0]);
  };

  useEffect(() => {
    getUltimasSemanas();
  }, []);

  if (!selectedWeek) return null;

  return (
    <div className="custom-selector">
      <div className="title-container" onClick={() => triggerMenu()}>
        <span>{selectedWeek.label}</span>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      {open ? (
        <div className="weeks-container">
          {weeks.reverse().map((week, idx) => (
            <WeekItem key={idx} week={week} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default WeekSelector;
