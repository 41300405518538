import React from "react";
import { Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./Theme";
import {
  Home,
  Aliado,
  Aliados,
  AdminAliado,
  Checkout,
  Encomiendas,
} from "../views";
import AppContext from "../components/AppContext";
import WhatsappButton from "../components/buttons/WhatsappButton/WhatsappButton";

const DOMI_ADMIN_ROUTES = [
  "/admin/domi",
  "/admin/domi/aliado",
  "/admin/domi/rider/crear",
  "/admin/domi/rider/tipos",
  "/admin/aliado",
];

const ROUTES_WITHOUT_LOCATION = [...DOMI_ADMIN_ROUTES, "/comanda", "/aliado/"];

const AppRoutes = () => (
  <ThemeProvider theme={theme}>
    <AppContext excludeRoutes={ROUTES_WITHOUT_LOCATION}>
      <Route exact path="/" component={Home} />
      <Route path="/carro" render={() => <Encomiendas modo="carro" />} />
      <Route path="/encomiendas" component={Encomiendas} />
      <Route path="/aliado/:aliadoId" component={Aliado} />
      <Route path="/aliados" component={Aliados} />
      <Route path="/market" render={() => <Aliados market />} />
      <Route exact path="/checkout" component={Checkout} />
      <Route
        exact
        path="/checkout/:aliadoId"
        render={() => <Checkout direct />}
      />
      <WhatsappButton excludeRoutes={ROUTES_WITHOUT_LOCATION} />
    </AppContext>
    <Route path="/admin/aliado" component={AdminAliado} />
  </ThemeProvider>
);

const Routes = () => {
  return <AppRoutes />;
};

export default Routes;
