import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  formRadio: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  formRadioGroup: {
    marginTop: theme.spacing(1),
  },
  formRadioTitle: {
    fontWeight: 600,
    color: "#515C6F",
    fontSize: "0.85em",
    textTransform: "capitalize",
  },
  formRadioInput: {
    margin: 0,
    justifyContent: "space-between",
    padding: 0,
    marginRight: "2vw",
    marginBottom: 5,
  },
  formRadioInputStyle: {
    padding: 0,
  },
  formRadioInputLabel: {
    fontSize: 13,
    fontWeight: 300,
    textTransform: "capitalize"
  },
}));

export default useStyles;
