import React, { useEffect, useMemo, useRef } from "react";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Sortable from "sortablejs";
import Fetch from "../../../providers/Fetch";
import { toast } from "react-toastify";
import "./CategoriasModal.css";

const CategoriasModal = ({
  context,
  dispatch,
  ordenCategorias = [],
  existingCategorias = [],
  setModal,
}) => {
  const sortable = useRef(null);

  const categoriasOrdenadas = useMemo(() => {
    if (!ordenCategorias.length) return Object.keys(existingCategorias);

    const oldKeys = [...Object.keys(existingCategorias)];

    const limpiarNoExistentes = ordenCategorias.filter((orden) =>
      oldKeys.includes(orden)
    ); // Verificar si ya no existe

    const nuevosAgregados = oldKeys.filter(
      (categoria) => !limpiarNoExistentes.includes(categoria)
    ); // Nuevos agregados

    return [...limpiarNoExistentes, ...nuevosAgregados];
  }, [existingCategorias, ordenCategorias]);

  const CategoriaItem = ({ categoria = "", order = 1 }) => (
    <li className="categoria" id={categoria}>
      <DragHandleIcon />{" "}
      <span>
        {order} - {categoria}
      </span>
    </li>
  );

  const submitResult = async (_ordenCategorias) => {
    const result = await Fetch({
      method: "PUT",
      endpoint: `/aliado/${context.aliado._id}`,
      body: {
        ordenCategorias: _ordenCategorias,
      },
      token: context.token,
    });
    if (!result) {
      toast.error("Error al guardar orden de categorias", {
        autoClose: 2500,
        hideProgressBar: true,
        toastId: "error-modify-ordenCategorias",
      });
      return;
    }
    toast.success("Orden de categorias guardadas con exito!", {
      autoClose: 2500,
      hideProgressBar: true,
      toastId: "sucesss-modify-ordenCategorias",
    });
    dispatch({
      type: "UPDATE_ALIADO",
      payload: {
        ordenCategorias: _ordenCategorias,
      },
      nav: "misProductos",
    });
    setModal(false);
  };

  useEffect(() => {
    const el = document.querySelector(".categorias");
    sortable.current = Sortable.create(el, {
      sort: true,
      direction: "vertical",
      animation: 350,
      onEnd: (oldIndex, newIndex) => {
        if (oldIndex === newIndex) return;
        const newCategorias = document.querySelectorAll(".categoria");
        const result = [];
        newCategorias.forEach((catDiv) => {
          result.push(catDiv.id);
        });
        submitResult(result);
      },
    });
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="categorias-modal">
      <h2>Reordenar categorias</h2>
      <p className="sub-text">
        Puedes arrastrar cada categoria para establecer su orden en la pagina
        principal
      </p>
      <ul className="categorias">
        {categoriasOrdenadas.map((ej, i) => (
          <CategoriaItem categoria={ej} order={i + 1} key={i} />
        ))}
      </ul>
    </div>
  );
};

export default CategoriasModal;
