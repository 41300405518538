import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(4),
    minHeight: 80,
    width: "80%",
    overflow: "scroll",
    backgroundColor: "white",
    borderRadius: 20,
    // borderBottomLeftRadius: 10,
    // borderBottomRightRadius: 10,
    boxShadow: theme.shadows[5],
    // paddingTop: theme.spacing(5),
  },
  mediaCard: {
    height: 164,
    borderRadius: 20,
    // borderBottomLeftRadius: 10,
    // borderBottomRightRadius: 10,
    boxShadow: theme.shadows[1],
    display: "flex",
    justifyContent: "flex-end",
  },
  body: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "#515C6F",
    fontWeight: 600,
  },
  titleBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.1em",
    fontWeight: 600,
  },
  closeIcon: {
    color: "white",
    margin: theme.spacing(2),
  },
  desc: {
    fontSize: "0.7em",
    fontWeight: 300,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    lineHeight: "15px",
  },
  cantidad: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cantidadWrapper: {
    width: "20%",
    display: "flex",
    justifyContent: "space-between",
    height: "30px",
    borderRadius: "15px",
    padding: "2px 0px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    paddingLeft: "10px",
  },
  cantidadInput: {
    background: "transparent",
    border: "none",
    "-moz-appearance": "textfield",
    width: "50%",
  },
  cantidadArrowsNav: {
    width: "50%",
    height: "100%",
    margin: 0,
    padding: 0,
    borderLeft: "1px solid rgba(0,0,0,0.05)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cantidadIcon: {
    fontSize: "13px",
    margin: 0,
    padding: 0,
    cursor: "pointer",
  },
  cantidadText: {
    fontSize: "0.85em",
  },
  precio: {
    fontWeight: 800,
  },
  descTitle: {
    fontWeight: 600,
    color: "#515C6F",
    fontSize: "0.85em",
    marginTop: 10,
  },
  descInput: {
    resize: "none",
    width: "92%",
    height: 80,
    padding: "4%",
    borderRadius: 15,
    border: "1px solid rgba(183,183,183,0.4)",
    boxShadow: theme.shadows[1],
    color: "#A2A2A2",
    marginTop: theme.spacing(1),
    fontFamily: "Montserrat",
    fontSize: "0.8em",
  },
  totalBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  totalText: {
    color: "#515C6F",
    fontWeight: 600,
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(3),
  },
  button: {
    backgroundColor: "#35a4fe",
    borderRadius: 5,
    color: "white",
    width: "70%",
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "capitalize",
    margin: 0,
    padding: 0,
  },
  ingredientes: {
    overflow: "scroll",
  },
}));

export default useStyles;
