import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  Grid,
  Avatar,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import "./SearchBar.css";
import { useStateValue } from "../../../providers/context/contextProvider";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SearchBar = ({
  color = "#35a4fe",
  aliado = false,
  productos = [],
  handleOpen,
}) => {
  const [context, dispatch] = useStateValue();
  const { REACT_APP_API_HOST } = process.env;

  const useStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
    },
    searchBar: {
      border: "none !important",
    },
    textField: {
      boxShadow: "rgba(114, 124, 142, 0.2) 0px 2px 4px",
      border: "none !important",
      borderRadius: "30px",
    },
    input: {
      borderRadius: "30px",
      border: "none !important",
      borderColor: "red",
    },
    borderInput: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: color,
        },
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const optionHandler = (option, value) => option.nombre === value.nombre;
  const getOptionLabel = (option) => option.nombre;
  const filterHandler = (options, { inputValue }) =>
    options.filter(
      (option) =>
        option.nombre
          .toString()
          .toLowerCase()
          .includes(inputValue.toString().toLowerCase()) ||
        option.categorias.some((categoria) =>
          (aliado ? categoria : categoria.nombre)
            .toString()
            .toLowerCase()
            .includes(inputValue.toString().toLowerCase())
        )
    );
  const renderOption = (option) => {
    const { perfilImg = "", productoImg = "", nombre, categorias } = option;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          // if (aliado) return;
          // history.push(`aliado/${_id}`);
        }}
      >
        <Avatar
          src={aliado ? productoImg : perfilImg}
          style={{
            height: 50,
            width: 50,
            borderRadius: 15,
            boxShadow: "0px 6px 10px #727C8E40",
          }}
          variant="rounded"
        />
        <div
          style={{
            marginLeft: 10,
            ...(aliado
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 35,
                }
              : {}),
          }}
        >
          <Typography
            component={"span"}
            style={{ color: "#515C6F", fontSize: 14, fontWeight: 600 }}
          >
            {nombre}
          </Typography>
          {aliado ? (
            <Typography
              component={"span"}
              style={{ color: "#515C6F", fontSize: 12 }}
            >
              {categorias.join(" - ")}
            </Typography>
          ) : null}
        </div>
      </div>
    );
  };

  const loadData = async () => {
    if (aliado) {
      // Si es la pagina de un aliado en particular
      setOptions(productos);
      setLoading(false);
      return;
    }
    if (context.app.aliados.length) {
      // Si los aliados ya estan cargados
      setOptions(context.app.aliados);
      setLoading(false);
      return;
    }
    if (!context.app.aliados.length || context.app.aliados.length === 1) {
      // Si estaba en la pagina de un aliado
      try {
        const aliadoResponse = await axios.get(`${REACT_APP_API_HOST}/aliado`);
        const { aliados: aliadosRaw, categorias } = aliadoResponse.data.data;

        const aliados = aliadosRaw
          .filter((aliado) => aliado.mostrarEnLista && aliado.activo)
          .map((aliado) => ({
            ...aliado,
            distance: null,
          }));

        dispatch({
          type: "SET_ALIADOS",
          aliados,
          categorias,
        });

        setOptions(aliados);
        setLoading(false);
      } catch (err) {
        console.log(err);
        console.log("Error en peticion al APi");
      }
    }
  };

  useEffect(() => {
    loadData();
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item className={classes.root} xs={12}>
      <Autocomplete
        id="asynchronous-demo"
        className={classes.searchBar}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(evt, value) => {
          if (!aliado) {
            history.push(`aliado/${value._id}`);
            return;
          }
          if (value) handleOpen(value);
          return;
        }}
        loadingText="Cargando..."
        noOptionsText="..."
        getOptionSelected={optionHandler}
        getOptionLabel={getOptionLabel}
        filterOptions={filterHandler}
        options={options}
        loading={aliado ? false : loading}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            className={`${classes.textField} ${classes.borderInput}`}
            placeholder="¿Qué buscas?"
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">
                  <Search style={{ color: "#BBBBBB" }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
};

export default SearchBar;
