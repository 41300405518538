import { createContext, useReducer, useContext } from "react";

export const Context = new createContext();

export const RESET_STATE = "RESET_STATE";
export const LOGOUT = "LOGOUT";
export const SET_NAV = "SET_NAV";
export const SET_TOKEN = "SET_TOKEN";
export const SET_COMANDAS = "SET_COMANDAS";
export const SET_ALL_COMANDAS = "SET_ALL_COMANDAS";
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const SET_ADMIN = "SET_ADMIN";
export const SET_RIDERS = "SET_RIDERS";
export const SET_ALIADOS = "SET_ALIADOS";
export const SET_ALL_RIDERS = "SET_ALL_RIDERS";
export const SET_ALL_ALIADOS = "SET_ALL_ALIADOS";

const initialState = {
  token: null,
  nav: "dashboard",
  comandas: [],
  allComandas: [],
  aliados: [],
  riders: [],
  allRiders: [],
};

export const ContextProvider = (innerRoute) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case LOGOUT:
        return { ...initialState, nav: "login" };
      case SET_NAV:
        return {
          ...state,
          nav: action.nav,
        };
      case SET_TOKEN:
        return {
          ...state,
          token: action.token,
        };
      case SET_COMANDAS:
        return {
          ...state,
          comandas: action.comandas,
        };
      case SET_ALL_COMANDAS:
        return {
          ...state,
          allComandas: action.allComandas,
          riders: action.riders || state.riders,
          aliados: action.aliados || state.aliados,
        };

      case SET_RIDERS:
        return {
          ...state,
          riders: action.riders,
        };
      case SET_ALIADOS:
        return {
          ...state,
          aliados: action.aliados,
        };
      case SET_ALL_RIDERS:
        return {
          ...state,
          allRiders: action.allRiders,
        };
      case SET_ALL_ALIADOS:
        return {
          ...state,
          allAliados: action.allAliados,
        };
      case SET_INITIAL_DATA:
        return {
          ...state,
          comandas: action.comandas || state.comandas,
          aliados: action.aliados || state.aliados,
          riders: action.riders || state.riders,
          allRiders: action.allRiders || state.allRiders,
        };
      case SET_ADMIN:
        return {
          ...state,
          token: action.token,
        };
      default:
        console.error("Reducer, type not found...", action.type);
        break;
    }
  };

  const [context, dispatch] = useReducer(reducer, {
    ...initialState,
    nav: innerRoute,
  });

  return [Context, context, dispatch];
};

export const useStateValue = () => useContext(Context);
