import React from "react";
import { InputLabel, InputBase, FormControl } from "@material-ui/core";
import { fade, withStyles } from "@material-ui/core/styles";
import FormItem from "../FormItem/FormItem";
import "./FormInput.css";
import MuiPhoneNumber from "material-ui-phone-number";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    // position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 12,
    color: "#515C6F",
    padding: "10px 12px",
    borderRadius: 5,
    boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
    },
    "&::placeholder": {
      fontSize: 12,
    },
  },
}))(InputBase);

const TextInput = ({
  inputID = "text-input",
  label = null,
  placeholder,
  multiline = false,
  value = null,
  rows = 1,
  type = "text",
  endAdornment = () => <></>,
  onChange = () => {},
}) => (
  <FormControl fullWidth>
    {label ? (
      <InputLabel
        htmlFor={inputID}
        style={{
          color: "#515C6F",
          fontWeight: "Bold",
          fontSize: "15px",
        }}
        shrink
      >
        {label}
      </InputLabel>
    ) : null}
    {type === "tel" ? (
      <MuiPhoneNumber
        name={`inputID-${inputID}`}
        className="phone-input"
        htmlFor={inputID}
        // defaultCountry="us"
        preferredCountries={["us", "ve"]}
        defaultCountry="ve"
        // regions={["north-america", "carribean", "south-america"]}
        countryCodeEditable
        disableDropdown
        value={value}
        onChange={onChange}
        fullWidth
        autoFormat
        // inputClass="testeo"
        // placeholder={placeholder ?? null}
        variant="filled"
        // style={{
        //   marginBottom: 0,
        //   padding: 0,
        // }}
      />
    ) : (
      <BootstrapInput
        id={inputID}
        variant="outlined"
        multiline={multiline}
        placeholder={placeholder ?? null}
        rows={rows}
        fullWidth={true}
        value={value}
        onChange={(e) => onChange(e)}
        type={type}
        style={{
          marginBottom: 0,
          padding: 0,
        }}
      />
    )}
  </FormControl>
);

const FormInput = (props) => {
  return (
    <FormItem>
      <TextInput {...props} />
    </FormItem>
  );
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: 55,
//   },
//   loadingScreen: {
//     height: "25em",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

export default FormInput;
