import axios from "axios";

const uploadImage = (image) =>
  new Promise(async (resolve, reject) => {
    const { REACT_APP_CLOUDINARY_URL, REACT_APP_CLOUDINARY_NAME } = process.env;
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", REACT_APP_CLOUDINARY_NAME);
      const request = await axios.post(REACT_APP_CLOUDINARY_URL, formData);
      if (request) {
        resolve(request.data.secure_url);
      }
      reject(null);
    } catch (err) {
      console.log("Error", err);
      reject(null);
    }
  }, []);
// Retorna la URL con la informacion

export default uploadImage;
