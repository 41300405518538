import React, { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { TagInput, OtherTags } from "../../TagInput/TagInput";

const EditModal = ({
  productoSelected = {},
  setModal,
  setAvoidClose,
  context,
  dispatch,
  onSubmit,
}) => {
  const { REACT_APP_API_HOST } = process.env;
  const [loading, setLoading] = useState(false);
  const [tempName, setTempName] = useState(productoSelected.nombre);
  const [tempDesc, setTempDesc] = useState(productoSelected.descripcion || "");
  const [tempPrecio, setTempPrecio] = useState(productoSelected.precio || 0);
  const [editingTag, setEditingTag] = useState(false);
  const [tempCategorias, setTempCategorias] = useState(
    productoSelected.categorias || []
  );

  const addCategoria = (categoria) => {
    if (editingTag) {
      setTempCategorias([
        ...tempCategorias.filter((categoria) => categoria),
        categoria,
      ]);
      setEditingTag(false);
      return;
    }
    setTempCategorias([...tempCategorias, categoria]);
  };

  const removeCategoria = (index) =>
    setTempCategorias((tempCategorias) =>
      tempCategorias.filter((c, i) => i !== index)
    );

  const handleSubmit = async () => {
    const { aliado } = context;
    setLoading(true);
    const data = {
      nombre: tempName,
      descripcion: tempDesc,
      precio: tempPrecio,
      categorias: tempCategorias,
    };
    if (tempName === productoSelected.nombre) delete data["nombre"];
    if (tempDesc === productoSelected.descripcion) delete data["descripcion"];
    if (tempPrecio === productoSelected.precio) delete data["precio"];
    const config = {
      headers: { Authorization: `Bearer ${context.token}` },
    };

    const response = await axios
      .put(
        `${REACT_APP_API_HOST}/producto/${productoSelected._id}`,
        {
          aliadoId: aliado._id,
          ...data,
        },
        config
      )
      .catch((err) => {
        return false;
      });
    if (!response) alert("Error al modificar datos de aliado!");
    setLoading(false);
    onSubmit(data);
  };

  const noPickedCategories = useMemo(() => {
    if (!productoSelected.categorias) return [];
    const resp = productoSelected.categorias.filter(
      (cat) => cat !== "Otros" && !tempCategorias.includes(cat)
    );
    return resp;
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCategorias]);

  return (
    <div className="modal-container edit">
      <h2>Editar producto</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="input-group" style={{ width: "75%" }}>
              <span>Nombre Producto</span>
              <input
                type="text"
                value={tempName}
                onChange={({ target: { value } }) => setTempName(value)}
              />
            </div>
            <div className="input-group precio" style={{ width: "20%" }}>
              <span>Precio ($)</span>
              <input
                type="numeric"
                value={tempPrecio}
                onChange={({ target: { value } }) => {
                  if (isNaN(value)) return;
                  setTempPrecio(value);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <span>Descripcion</span>
            <textarea
              type="text"
              value={tempDesc}
              // multiline
              onChange={({ target: { value } }) => setTempDesc(value)}
            />
          </div>
          <div className="input-group">
            <span>Categorias</span>
            <TagInput
              tags={tempCategorias}
              addTag={addCategoria}
              removeTag={removeCategoria}
              editingTag={editingTag}
              setEditingTag={setEditingTag}
            />
          </div>
          {noPickedCategories.length ? (
            <div className="input-group">
              <OtherTags
                addTag={addCategoria}
                noPickedTags={noPickedCategories}
              />
            </div>
          ) : null}

          <div className="button-group">
            <button className="descartar" onClick={() => setModal(false)}>
              Descartar
            </button>
            <button className="guardar" onClick={() => handleSubmit()}>
              Guardar
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditModal;
