export const INITIAL_CONTACT_DATA = {
  nombre: "",
  cedula: "",
  telefono: "",
  direccion: "",
};

export const INITIAL_RIDER_STATE = {
  ...INITIAL_CONTACT_DATA,
  fechaNacimiento: "",
  sexo: "",
  tipoRiderId: "",
  tipoSangre: "",
  correo: "",
  clave: "",
  confirmarClave: "",
};

export const COLUMNAS_COMANDAS_RIDERS = [
  {
    id: "fecha",
    label: "Fecha",
  },
  {
    id: "_id",
    label: "# Ride",
  },
  {
    id: "aliado",
    label: "Aliado",
  },
  {
    id: "modo",
    label: "Tipo",
  },
  {
    id: "precioDelivery",
    label: "Precio",
  },
  {
    id: "%rider",
    label: "% Rider",
  },
];

export const COLUMNAS_COMANDAS_ALIADOS = [
  {
    id: "fecha",
    label: "Fecha",
  },
  {
    id: "_id",
    label: "# Ride",
  },
  {
    id: "precioDelivery",
    label: "Precio",
  },
  {
    id: "cliente",
    label: "Cliente",
  },
  {
    id: "precioDelivery",
    label: "Precio",
  },
];

export const COLUMNAS_COMANDAS = [
  {
    id: "fecha",
    label: "Fecha",
  },
  {
    id: "_id",
    label: "# Ride",
  },
  {
    id: "aliado",
    label: "Aliado",
  },
  {
    id: "modo",
    label: "Tipo",
  },
  {
    id: "rider",
    label: "Rider",
  },
  {
    id: "cliente",
    label: "Cliente",
  },
  {
    id: "distance",
    label: "Distancia",
  },
  {
    id: "precioDelivery",
    label: "Precio",
  },
  {
    id: "%rider",
    label: "% Rider",
  },
  {
    id: "%domi",
    label: "% Domi",
  },
  {
    id: "pagoRider",
    label: "Pago a Rider",
  },
  {
    id: "pagoAliado",
    label: "Cobro a Aliado",
  },
];
