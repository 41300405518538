import React, { useState, useEffect } from "react";
import {
  FormControl,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Typography,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import style from "./style";
const IngredienteCheck = ({ extras, setCuenta, colores }, ref) => {
  const classes = style();
  const [extrasSelected, setExtrasSelectd] = useState({});

  useEffect(() => {
    const keys = {};
    extras
      .map((e) => e._id)
      .forEach((key) => {
        keys[key] = false;
      });
    setExtrasSelectd({ ...keys });
  }, [extras]);

  const handleChange = React.useCallback(
    ({ target }) => {
      const { value, checked, name } = target;
      const split = value.split("%");
      const _id = split[0];
      const precio = parseFloat(split[1]);
      setExtrasSelectd({
        ...extrasSelected,
        [_id]: checked,
      });
      setCuenta({
        accion: checked ? "agregar" : "retirar",
        _id,
        nombre: name,
        precio,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extrasSelected]
  );

  const StyledCheckbox = withStyles({
    root: {
      color: colores.secondary,
      "&$checked": {
        color: colores.primary,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <FormControl
      component="fieldset"
      className={classes.formRadio}
      style={{
        marginRight: "-3.1vw",
      }}
      ref={ref}
    >
      <FormLabel component="legend" className={classes.formRadioTitle}>
        Extras
      </FormLabel>
      {extras.map((extra, key) => (
        <FormControlLabel
          key={key}
          value={`${extra._id}%${extra.precio}` || ""}
          control={
            <StyledCheckbox
              checked={extrasSelected[extra._id] || false}
              onChange={handleChange}
              name={extra.nombre}
              color="primary"
            />
          }
          label={
            <Typography
              component={"span"}
              className={classes.formRadioInputLabel}
            >
              {extra.nombre}
              {extra.precio ? (
                <Box fontWeight="fontWeightBold" style={{ display: "inline" }}>
                  {" "}
                  (+${extra.precio})
                </Box>
              ) : (
                ""
              )}
            </Typography>
          }
          labelPlacement="start"
          className={classes.formRadioInput}
          style={{
            marginRight: "-1vw",
            height: "3vh",
            marginTop: "0.5vh",
            marginBottom: "0.5vh",
          }}
        />
      ))}
    </FormControl>
  );
};

export default React.forwardRef(IngredienteCheck);
