/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useStateValue } from "../../providers/context/contextProvider";
import scriptLoader from "../../utils/scriptLoader";
const GOOGLE_API_STRING =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAEGUFNVyeWpXBZ0ivbSaKmPIsBzLrwt5A&libraries=places";

const Maps = ({ GoogleAPI, callbacks = {} }) => {
  const [context] = useStateValue();

  useEffect(() => {
    scriptLoader(GOOGLE_API_STRING, () => {
      window.initMap = function () {
        GoogleAPI.initializeMap({
          location: context.app.location ?? {
            lat: 10.688650140722363,
            lng: -71.62973769106314,
          },
          mapContainerId: "map",
          googleDOMObject: window.google,
          distanceCallback: callbacks.distanceCallback ?? null,
        });
      };
      window.initMap();
      if (callbacks.setCallback) callbacks.setCallback();
    });
  }, []);

  return <div id="map" style={mapStyles}></div>;
};

const mapStyles = {
  height: "30vh",
  width: "100%",
  borderRadius: "10px",
  marginBottom: "10px",
};

export default Maps;
