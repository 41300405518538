import axios from "axios";

const Fetch = (props) =>
  new Promise((resolve, reject) => {
    const {
      endpoint,
      method = "GET",
      body = {},
      token = false,
      dispatch = () => {
        console.log("No dispatch function provided");
      },
    } = props;
    try {
      const { REACT_APP_API_HOST } = process.env;
      const config = token
        ? {
            headers: { Authorization: `Bearer ${token}` },
          }
        : null;
      switch (method) {
        case "GET":
          axios
            .get(`${REACT_APP_API_HOST}${endpoint}`, config)
            .then((e) => resolve(e))
            .catch((err) => {
              if (err?.response?.status === 401) {
                localStorage.removeItem("domi-app-admin-memory");
                dispatch({ type: "LOGOUT" });
                return;
              }
              reject(err);
            });
          break;
        case "POST":
          axios
            .post(`${REACT_APP_API_HOST}${endpoint}`, body, config)
            .then((e) => resolve(e))
            .catch((err) => reject(err));
          break;
        case "DELETE":
          axios
            .delete(`${REACT_APP_API_HOST}${endpoint}`, {
              data: body,
              ...config,
            })
            .then((e) => resolve(e))
            .catch((err) => reject(err));
          break;
        case "PUT":
          axios
            .put(`${REACT_APP_API_HOST}${endpoint}`, body, config)
            .then((e) => resolve(e))
            .catch((err) => reject(err));
          break;
        default:
          break;
      }
    } catch (err) {
      console.log("Error obteniendo datos...");
      console.log(err);
      reject(err);
    }
  });

export default Fetch;
