import React, { useMemo } from "react";
import { IconButton, Badge } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useStateValue } from "../../../providers/context/contextProvider";
import { ShoppingCart as ShoppingIcon } from "@material-ui/icons";
import { calcularPrecioTotalProductos } from "../../../utils/calcularPrecio";
import { useLocation } from "react-router-dom";

const WhatsappButton = ({ excludeRoutes }) => {
  const [context, dispatch] = useStateValue();
  const location = useLocation();

  const isHome = location.pathname === "/";

  const total = useMemo(() => {
    return calcularPrecioTotalProductos(context.app.productos);
  }, [context.app.productos]);

  const renderCarrito =
    !isHome === "/" ||
    !excludeRoutes.some((route) => route.includes(location.pathname));

  const Whatsapp = () => (
    <IconButton
      style={{
        height: "60px",
        width: "60px",
        background: "#06d755",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => {
        const safariWindow = window.open();
        safariWindow.location.href = `https://api.whatsapp.com/send?phone=584246113560`;
      }}
    >
      <WhatsAppIcon
        style={{ color: "white", height: 35, width: 35, marginBottom: 4 }}
      />
    </IconButton>
  );

  const Carrito = () => (
    <IconButton
      edge="end"
      color="inherit"
      aria-label="menu"
      style={{
        textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
        height: "60px",
        width: "60px",
        background: "rgba(0,0,0,0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => {
        dispatch({ type: "TOGGLE_CHART" });
      }}
    >
      <Badge badgeContent={total > 0 ? `$${total}` : 0} color="secondary">
        <ShoppingIcon style={{ color: "white", height: 28, width: 28 }} />
      </Badge>
    </IconButton>
  );

  return (
    <div
      style={{
        position: "fixed",
        height: 130,
        width: 60,
        // background: "red",
        display: "flex",
        flexDirection: "column",
        justifyContent: !renderCarrito ? "flex-end" : "space-between",
        margin: "20px",
        bottom: 0,
        right: 0,
        cursor: "pointer",
      }}
    >
      {isHome ? <Whatsapp /> : null}
    </div>
  );
};

export default WhatsappButton;
