import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

const useAliados = (context, dispatch, market = false) => {
  const { REACT_APP_API_HOST } = process.env;
  const history = useHistory();
  const [selector, setSelector] = useState("");
  const [loading, setLoading] = useState(true);
  const [aliados, setAliados] = useState([]);

  const handleNavigate = (route) => {
    history.push(`/${route}`);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelector(value);
  };

  const filterAliados = (aliados) =>
    aliados.filter((aliado) => {
      const firstCondition = market ? aliado.market : !aliado.market;
      const secondCondition = aliado.activo;
      const thirdCondition = aliado.mostrarEnLista;
      return firstCondition && secondCondition && thirdCondition;
    });

  const loadAliados = async () => {
    let finalAliados = [];
    if (!context.app.aliados.length || context.app.aliados.length === 1) {
      try {
        const aliadoResponse = await axios.get(`${REACT_APP_API_HOST}/aliado`);
        const { aliados, categorias } = aliadoResponse.data.data;
        dispatch({
          type: "SET_ALIADOS",
          aliados: aliados.map((aliado) => ({
            ...aliado,
            distance: null,
          })),
          categorias,
        });
        finalAliados = aliados;
      } catch (err) {
        console.log("Error cargando aliados del API");
      }
    } else {
      finalAliados = context.app.aliados;
    }
    setAliados(filterAliados(finalAliados));
    setLoading(false);
  };

  return {
    loadAliados,
    handleChange,
    handleNavigate,
    loading,
    selector,
    aliados,
  };
};

export default useAliados;
