import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CircleButton from "../../buttons/CircleButton/CircleButton";
import { makeStyles } from "@material-ui/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: `0px ${theme.spacing(2)}px`,
    borderRadius: 10,
    boxShadow: "0px 8px 10px rgba(114,124,142,0.5)",
    height: "18vh",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    background: "url(./assets/img/market.jpg)",
    backgroundPosition: "center",
    backgroundSize: "87%",
    position: "relative",
    "&::before": {
      position: "absolute",
      content: "' '",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      background: "rgba(0,0,0,0.3)",
      borderRadius: 10,
    },
    "&:hover:before": {
      background: "rgba(0,0,0,0.4)",
    },
    cursor: "pointer",
  },
  contactoTop: {
    height: "50%",
    marginRight: "50%",
    zIndex: 3,
  },
  contactoBottom: {
    height: "50%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  contactoText: {
    color: "white",
    fontWeight: "700",
    fontSize: "34px",
    letterSpacing: "0px",
    lineHeight: "30px",
  },
  boton: {
    height: "45px",
    marginTop: "15px",
    borderRadius: "40px",
    marginBottom: " 5px",
    backgroundColor: theme.palette.secondary.main,
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  botonText: {
    fontSize: "0.8em",
    textTransform: "uppercase",
    letterSpacing: "-0.5px",
    color: theme.palette.blancos.main,
    fontWeight: "600",
    marginLeft: "15%",
  },
  circleBoton: {
    height: "30px",
    width: "30px",
    backgroundColor: theme.palette.blancos.main,
    borderRadius: "50%",
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 30,
    height: 30,
    color: theme.palette.blancos.main,
  },
  accederButton: {
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 45,
    width: 45,
    borderRadius: 50,
    zIndex: 0,
    cursor: "pointer",
  },
}));

const ContactoCard = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid
      container
      className={classes.root}
      onClick={() => {
        history.push("aliado/61f8746d4e7917002d0318f2");
      }}
    >
      <Grid item xs={12} className={classes.contactoTop}>
        <Typography className={classes.contactoText}>Domi Market</Typography>
      </Grid>
      <Grid item xs={12} className={classes.contactoBottom}>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div className={classes.accederButton}>
            <ArrowForwardIosIcon className={classes.icon} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactoCard;
