import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, CircularProgress } from "@material-ui/core";
import useAliados from "../../providers/hooks/useAliados";
import { useStateValue } from "../../providers/context/contextProvider";
import CategoriasSelect from "../../components/inputs/CategoriasSelect/CategoriasSelect";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/inputs/SearchBar/SearchBar";
import AliadoCard from "../../components/cards/AliadoCard/AliadoCard";
import PromocionesCards from "../../components/cards/PromocionesCards/PromocionesCards";

const Aliados = ({ market = false }) => {
  const classes = useStyles();
  const [context, dispatch] = useStateValue();
  const { aliados, loadAliados, handleChange, loading, selector } = useAliados(
    context,
    dispatch,
    market
  );

  useEffect(() => {
    loadAliados();
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Header />
      <Grid container>
        <div
          style={{
            marginTop: 4 * 2,
            marginBottom: 4 * 4,
          }}
        >
          {/* <PromocionesCards /> */}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          <SearchBar aliados />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 4,
          }}
        >
          <CategoriasSelect
            categorias={context.app.categorias}
            selector={selector}
            handleChange={handleChange}
          />
        </div>
      </Grid>
      {loading ? (
        <Grid container className={classes.loadingScreen}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container className={classes.aliados}>
          {aliados.map((aliado, key) => {
            if (!selector)
              return (
                <AliadoCard
                  key={key}
                  {...aliado}
                  loadingLocation={context.app.loadingLocation}
                />
              );
            const aliadoCategorias = aliado.categorias.map((c) => c._id);
            if (aliadoCategorias.includes(selector)) {
              return (
                <AliadoCard
                  key={key}
                  {...aliado}
                  loadingLocation={context.app.loadingLocation}
                />
              );
            }
            return null;
          })}
        </Grid>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 55,
  },
  loadingScreen: {
    height: "25em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default Aliados;
