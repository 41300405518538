import React, { useCallback } from "react";
import { Grid, Badge, IconButton, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ShoppingCart as ShoppingIcon } from "@material-ui/icons";
import { useStateValue } from "../../providers/context/contextProvider";
import { useHistory } from "react-router-dom";
import ChartModal from "../modals/ChartModal/ChartModal";
import { calcularPrecioTotalProductos } from "../../utils/calcularPrecio";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AppHeader from "./AppHeader";

const ShoppingCart = ({ onClick = () => {}, total, aliado = false }) => (
  <IconButton
    edge="end"
    color="inherit"
    aria-label="menu"
    style={{
      textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
      boxShadow: aliado ? "0px 3px 6px rgba(0,0,0,0.8)" : "none",
      background: aliado ? "rgba(0,0,0,0.6)" : "none",
    }}
    onClick={onClick}
  >
    <Badge badgeContent={total > 0 ? `$${total}` : 0} color="secondary">
      <ShoppingIcon />
    </Badge>
  </IconButton>
);

const BackButton = () => {
  const uniqueStyle = {
    width: 15,
    height: 15,
    padding: 0,
    margin: 0,
    marginTop: 1,
    color: "white",
    stroke: "rgba(0,0,0,0.3)",
  };
  return (
    <IconButton style={uniqueStyle}>
      <ArrowBackIosIcon style={uniqueStyle} />
    </IconButton>
  );
};

const Header = ({
  home = false,
  aliado = false,
  checkout = false,
  alt = false,
  market = false,
  direct = false,
}) => {
  const [context, dispatch] = useStateValue();
  const classes = useStyles();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total = useCallback(
    calcularPrecioTotalProductos(context.app.productos),
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.app.productos]
  );

  const closeChart = () => {
    dispatch({ type: "TOGGLE_CHART" });
  };

  const AliadoViewHeader = () => (
    <Grid
      item
      xs={12}
      className={`${classes.headerBar} ${classes.headerAliado}`}
    >
      <div
        style={{
          marginLeft: -15,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => {
          history.push(market ? "/market" : "/aliados");
        }}
      >
        <BackButton />
        <img
          src={`../assets/img/domi.png`}
          alt="logo"
          style={{ marginLeft: 0, height: 20, width: "auto" }}
        />
      </div>
      <ShoppingCart
        aliado
        onClick={() => {
          dispatch({ type: "TOGGLE_CHART" });
        }}
        total={total}
      />
    </Grid>
  );

  return (
    <>
      {aliado ? (
        <AliadoViewHeader />
      ) : (
        <AppHeader
          ShoppingCart={ShoppingCart}
          BackButton={BackButton}
          home={home}
          checkout={checkout}
          alt={alt}
          direct={direct}
          open={context.app.chartOpen}
          setOpen={() => dispatch({ type: "TOGGLE_CHART" })}
        />
      )}
      <Modal
        open={context.app.chartOpen}
        onClose={() => dispatch({ type: "TOGGLE_CHART" })}
        className={classes.modal}
      >
        <React.Fragment>
          <ChartModal closeChart={closeChart} total={total} />
        </React.Fragment>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    height: 55,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headerAliado: {
    height: "8vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "white",
    fontWeight: 400,
  },
  headerBar: {
    color: "white",
    // textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  logoAliado: {
    textShadow: "0px 10px 10px rgba(150, 150, 150, 0.58)",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
}));

export default Header;
