import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Maps from "../../components/Maps/Maps";
import Autoplacement from "../../components/inputs/TextInput/Autoplacement";
import { useStateValue } from "../../providers/context/contextProvider";
import FormInput from "../../components/inputs/FormInput/FormInput";
import FormItem from "../../components/inputs/FormItem/FormItem";
import DistancePrice from "../../components/DistancePrice/DistancePrice";
import GoogleLibrary from "../../utils/GoogleMaps";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import DatosEnvio from "../../components/inputs/DatosEnvio/DatosEnvio";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Header from "../../components/Header/Header";
import { calcularPrecioDelivery } from "../../utils/calcularPrecio";
import {
  getCoordsFromGoogle,
  getComandaEncomienda,
} from "../../utils/comandaHelper";
import { useHistory } from "react-router";
import limpiarNumero from "../../utils/limpiarNumero";
import useComanda from "../../providers/hooks/useComanda";
import OrdenConfirmada from "../../components/modals/OrdenConfirmada/OrdenConfirmada";

const toastId = "epale";

const MetodoPago = ({ metodo }) => {
  const CopyIcon = ({ text }) => (
    <IconButton
      style={{
        height: 30,
        width: 30,
        marginLeft: 5,
      }}
      onClick={() => {
        copy(text);
        toast.info("Texto copiado con exito", {
          toastId,
          style: {
            backgroundColor: "#ef9700",
          },
          closeOnClick: true,
          pauseOnFocusLoss: false,
          autoClose: 1500,
        });
      }}
    >
      <FileCopyIcon
        style={{
          color: "#515C6F",
          height: 20,
          width: 20,
          marginLeft: 5,
        }}
      />
    </IconButton>
  );

  const LogoPago = ({ tipo }) => {
    const _style = {
      width: 40,
      height: 40,
    };
    return tipo === "zelle" ? (
      <img src="./assets/img/zelle.png" alt="zelle_logo" style={_style} />
    ) : (
      <PhoneIphoneIcon style={{ ..._style, color: "#35a4fe" }} />
    );
  };

  const ZelleImage = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "30%",
        height: 40,
      }}
    >
      <LogoPago tipo="zelle" />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        style={{
          color: "#515C6F",
          fontSize: "14px",
          fontWeight: "bold",
          marginLeft: "5px",
        }}
      >
        Zelle
      </Typography>
    </div>
  );

  const PagoMovilImage = ({ entidad }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "30%",
        // height: 40,
      }}
    >
      <LogoPago tipo="pago-movil" />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        style={{
          color: "#515C6F",
          fontSize: "14px",
          fontWeight: "bold",
          marginLeft: "5px",
          textAlign: "center",
        }}
      >
        Pago Movil ({capitalizeFirstLetter(entidad)})
      </Typography>
    </div>
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 3px 6px #00000029",
        padding: "15px 10px",
        borderRadius: 5,
        border: "1px solid #ced4da",
      }}
    >
      {metodo.tipo === "zelle" ? (
        <ZelleImage />
      ) : (
        <PagoMovilImage entidad={metodo.entidad} />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{
              color: "#515C6F",
              fontSize: "11px",
              fontWeight: "bold",
              // marginLeft: "5px",
            }}
          >
            {metodo.tipo === "zelle" ? "Correo" : "Numero Telefono"}:{" "}
            {metodo.pagoDireccion}
          </Typography>
          <CopyIcon text={metodo.pagoDireccion} />
        </div>
        {metodo.tipo !== "zelle" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{
                color: "#515C6F",
                fontSize: "11px",
                fontWeight: "bold",
                // marginLeft: "5px",
              }}
            >
              Cedula: {metodo.cedula}
            </Typography>
            <CopyIcon text={metodo.cedula} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{
              color: "#515C6F",
              fontSize: "11px",
              fontWeight: "bold",
              // marginLeft: "5px",
            }}
          >
            Nombre Titular: {metodo.titular}
          </Typography>
          <CopyIcon text={metodo.titular} />
        </div>
      </div>
    </div>
  );
};

const Encomiendas = ({ modo = "moto" }) => {
  const history = useHistory();
  const classes = useStyles();
  const [context] = useStateValue();
  const [distance, setDistance] = useState("0 km");
  const [datosDestino, setDatosDestino] = useState({
    nombre: "",
    telefono: "",
  });
  const [, setOrigenOpen] = useState(false);
  const [, setDestinoOpen] = useState(false);
  const [metodosPago] = useState([]);
  const [modoEnvio] = useState(modo);
  const [metodoPago, setMetodoPago] = useState(null);
  const [direccionOrigen, setDireccionOrigen] = useState(null);
  const [direccionDestino, setDireccionDestino] = useState(null);
  const [notaEnvio, setNotaEnvio] = useState("");

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [, setEditingA] = useState(false);
  const [, setEditingB] = useState(false);
  const [loadingPrecio, setLoadingPrecio] = useState(true);
  const [precioDolar, setPrecioDolar] = useState(0);
  const [comandaCreada, setComandaCreada] = useState(null);

  const { crearComanda } = useComanda();

  const GoogleAPI = useMemo(() => new GoogleLibrary(), []);

  const getPrecioDolar = async () => {
    const getDolarToday = await fetch(
      "https://s3.amazonaws.com/dolartoday/data.json"
    )
      .then((e) => e.json())
      .then((e) => e);
    setLoadingPrecio(false);
    setPrecioDolar(getDolarToday.USD.dolartoday);
  };

  const distanceNumber = useMemo(() => {
    return parseFloat(distance.split("km")[0] || 0);
  }, [distance]);

  const precioDelivery = React.useMemo(
    () =>
      calcularPrecioDelivery({
        distance: distanceNumber,
        modo: modoEnvio,
        domiFavor: true,
      }),
    [distanceNumber, modoEnvio]
  );

  const handleDatosDestino = ({ target }, inputId) => {
    const { value } = target;
    setDatosDestino({ ...datosDestino, [inputId]: value });
  };

  const enviarComanda = async () => {
    const dto = {
      datosOrigen: { direccion: direccionOrigen },
      datosDestino: {
        ...datosDestino,
        direccion: direccionDestino,
        telefono: limpiarNumero(datosDestino.telefono),
        notaEnvio,
      },
      precioDelivery,
      distance: distanceNumber,
      modoEnvio: modo,
    };

    const isEmpty = (arr) => Object.values(arr).some((val) => !val.length);

    if (isEmpty(dto.datosOrigen) || isEmpty(dto.datosDestino)) {
      toast.error("Debes llenar todos los campos", {
        autoClose: 2500,
        hideProgressBar: true,
        toastId: "empty-fields-encomienda",
      });
      return;
    }

    const coords = {
      origen: getCoordsFromGoogle(GoogleAPI.markers, "origen"),
      destino: getCoordsFromGoogle(GoogleAPI.markers, "destino"),
    };

    dto.coords = coords;

    const comandaResult = await crearComanda("encomienda", dto);

    if (!comandaResult) {
      toast.error("Error creando comanda...", {
        autoClose: 2500,
        hideProgressBar: true,
        toastId: "create-encomienda-error",
      });
      return;
    }

    const comandaText = getComandaEncomienda({
      datosOrigen: dto.datosOrigen,
      datosDestino: dto.datosDestino,
      modoEnvio,
      metodoPago,
      notaEnvio,
      coords,
      precioDelivery,
      precioDolar,
      comandaId: comandaResult.data._id,
    });

    // const safariWindow = window.open();
    // safariWindow.location.href = `https://api.whatsapp.com/send?phone=584246113560&text=${comandaText}`;

    setComandaCreada({
      comandaParams: {},
      comandaText,
    });

    const browser = window.open("about:blank", "_blank");

    if (!browser) {
      window.location.href = `whatsapp://send?text=${comandaText}&phone=584246113560`;
    } else {
      browser.location.href = `https://api.whatsapp.com/send?phone=584246113560&text=${comandaText}`;
    }
  };

  useEffect(() => {
    getPrecioDolar();
  }, []);

  return (
    <Container maxWidth="sm" disableGutters>
      <div className={classes.root}>
        <Header />
        <Grid container justifyContent="center">
          {GoogleAPI && (
            <>
              <FormItem style={{ marginTop: 16 }}>
                <Maps
                  GoogleAPI={GoogleAPI}
                  callbacks={{
                    distanceCallback: setDistance,
                  }}
                />
              </FormItem>
              <FormItem>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "100%" }}>
                    <Autoplacement
                      placeholder="¿Dónde será recogido?"
                      markerId="origen"
                      GoogleAPI={GoogleAPI}
                      context={context}
                      direction={direccionOrigen}
                      setOpen={setOrigenOpen}
                      setOpenOther={setDestinoOpen}
                      setDirection={(data) => {
                        setDireccionOrigen(data);
                      }}
                      setEditingParent={setEditingA}
                    />
                  </div>
                </div>
              </FormItem>
              <FormItem>
                <div style={{ display: "flex", alignItems: "center " }}>
                  <div style={{ width: "100%" }}>
                    <Autoplacement
                      placeholder="¿Dónde será llevado?"
                      markerId="destino"
                      GoogleAPI={GoogleAPI}
                      context={context}
                      direction={direccionDestino}
                      setOpen={setDestinoOpen}
                      setOpenOther={setOrigenOpen}
                      setDirection={setDireccionDestino}
                      setEditingParent={setEditingB}
                    />
                  </div>
                </div>
                <DatosEnvio
                  id="destino"
                  open
                  hideNombreCasa
                  datosCliente={datosDestino}
                  handleDatosCliente={handleDatosDestino}
                />
              </FormItem>
            </>
          )}
          <Typography
            style={{
              color: "#515C6F",
              fontWeight: "bold",
              fontSize: 12,
              margin: "0px 0px",
              marginTop: 10,

              width: "100%",
            }}
          >
            Nota:
          </Typography>
          <FormInput
            inputID="notaEnvio"
            multiline
            rows={2}
            placeholder="Escribe aquí como te podemos ayudar hoy"
            value={notaEnvio}
            onChange={({ target }) => setNotaEnvio(target.value)}
          />
          {metodosPago.length ? (
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
                style={{
                  color: "#515C6F",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Metodo de Pago
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={metodoPago ? metodoPago._id : null}
                disableUnderline
                onChange={(evt) => {
                  const { value } = evt.target;
                  const resp = metodosPago.find((m) => m._id === value);
                  setMetodoPago(resp);
                }}
                // displayEmpty
                className={classes.selectEmpty}
                style={{
                  border: "1px solid #ced4da",
                  color: "#515C6F",
                  padding: "3px 0px",
                  borderRadius: 5,
                  boxShadow: "0px 3px 8px #00000029",
                  fontSize: 13,
                  paddingLeft: 12,
                }}
              >
                {metodosPago.map((metodo) => {
                  const { _id } = metodo;
                  const tipo = String(metodo.tipo.replace("-", " "))
                    .split(" ")
                    .map((t) => capitalizeFirstLetter(t))
                    .join(" ");
                  const entidad = String(metodo.entidad)
                    .split(" ")
                    .map((t) => capitalizeFirstLetter(t))
                    .join(" ");
                  return (
                    <MenuItem value={_id}>{`${
                      tipo === "Zelle" ? "" : `${tipo} - `
                    }${entidad}`}</MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                style={{
                  lineHeight: 1.3,
                  fontSize: "0.6rem",
                }}
              >
                Debe realizar la transferencia por el monto total que aparece
                abajo a la cuenta que se le mostrara segun el metodo de pago,
                guardar la captura para luego mostrarla en el mensaje que se
                generara a nuestra central una vez hagas click en pagar
              </FormHelperText>
            </FormControl>
          ) : null}
          {metodosPago.length && metodoPago ? (
            <MetodoPago metodo={metodoPago} />
          ) : null}
          <FormItem container>
            <DistancePrice
              distance={distance}
              precioDelivery={precioDelivery}
              encomienda
              precioDolar={precioDolar}
              tooltipOpen={tooltipOpen}
              loadingPrecio={loadingPrecio}
              setTooltipOpen={setTooltipOpen}
            />
          </FormItem>
          <Grid
            container
            className={classes.formContent}
            justifyContent="center"
          >
            <Button
              style={{
                backgroundColor: "#35a4fe",
                borderRadius: 5,
                color: "white",
                width: "70%",
                height: 40,
                fontSize: 16,
                fontWeight: 600,
                textTransform: "none",
                margin: 0,
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 20,
                // fontSize: "20px",
              }}
              onClick={() => enviarComanda()}
            >
              {loadingPrecio ? (
                <CircularProgress
                  color="secondary"
                  style={{
                    height: 15,
                    width: 15,
                  }}
                />
              ) : (
                <>
                  Finalizar
                  <LocationOnIcon style={{ color: "#EF9700", marginLeft: 5 }} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={comandaCreada}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <OrdenConfirmada
          comandaCreada={comandaCreada}
          volver={() => {
            history.goBack();
          }}
        />
      </Modal>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  formContent: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginTop: 8,
    marginBottom: theme.spacing(1),
    // background: "red",
  },
  locationButton: {
    color: "white",
    backgroundColor: "#2196f3",
    borderRadius: "5px",
    height: "40px",
    marginBottom: "15px",
    marginLeft: "5px",
  },
}));

export default Encomiendas;
