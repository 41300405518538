import { useState } from "react";
import axios from "axios";

const { REACT_APP_API_HOST } = process.env;

const comandaValida = (props) => {
  const { datosUbicacion, coords } = props;
  if (!datosUbicacion) return false;
  const REQUIRED_FIELDS = ["nombre", "telefono"];
  const NUMERIC_FIELDS = ["distance", "precioDelivery"];

  const missingFields = REQUIRED_FIELDS.filter(
    (field) => !datosUbicacion[field]
  );

  const validCoords = Object.entries(coords).some(([, coord]) => {
    return coord?.lat && coord?.lng;
  });

  const invalidNumber = NUMERIC_FIELDS.some((field) => {
    return isNaN(props[field]);
  });

  return !missingFields.length && validCoords && !invalidNumber;
};

const getComandaDTO = (tipo, comanda) => {
  const baseComanda = {
    coords: comanda.coords,
    precioDelivery: comanda.precioDelivery,
    modoEnvio: comanda.modoEnvio,
    distance: comanda.distance,
    datosDestino: comanda.datosDestino,
  };

  const result =
    tipo === "encomienda"
      ? {
          ...baseComanda,
          datosUbicacion: comanda.datosOrigen,
          tarifa: "basica",
        }
      : {
          ...baseComanda,
          aliadoId: comanda.aliadoId,
          productos: comanda.productos,
          metodoPago: comanda.metodoPago,
          precioProductos: comanda.precioProductos,
          tarifa: comanda.tarifa,
        };

  return result;
};

const useComanda = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enviado, setEnviado] = useState(false);

  const crearComanda = async (tipo = "aliado", comandaProps) => {
    const { datosDestino, precioDelivery, distance, coords } = comandaProps;

    try {
      if (enviado) return;
      setLoading(true);
      setEnviado(true);
      if (
        !comandaValida({
          datosUbicacion: datosDestino,
          coords,
          distance,
          precioDelivery,
        })
      )
        throw new Error("Datos incorrectos");

      const comandaDTO = getComandaDTO(tipo, comandaProps);

      const response = await axios.post(
        `${REACT_APP_API_HOST}/comanda/${tipo}`,
        comandaDTO
      );
      if (response?.status !== 200) throw new Error("Error creando comanda");
      setLoading(false);
      return response.data;
    } catch (err) {
      console.log(err);
      setError(err);
      setLoading(false);
      return false;
    }
  };

  return { loading, setLoading, error, crearComanda, enviado };
};

export default useComanda;
