import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import { ContextProvider as AdminAliadoContextProvider } from "../../providers/context/adminAliadoContext";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Login from "./Login/Login";
import Tienda from "./Tienda/Tienda";
import Productos from "./Productos/Productos";
import { ToastContainer } from "react-toastify";
import "./theme/styles.css";

const Header = ({ destroySession }) => (
  <div className="header">
    <span className="salir" onClick={() => destroySession()}>
      Cerrar Sesion
    </span>
  </div>
);

const Comandas = () => <div>Comandas</div>;

const AdminAliado = () => {
  const [AdminAliadoContext, adminAliadoState, dispatchAdminAliadoState] =
    AdminAliadoContextProvider();
  const { REACT_APP_API_HOST } = process.env;
  const selected = useMemo(() => {
    return adminAliadoState.nav ? adminAliadoState.nav : "tienda";
  }, [adminAliadoState.nav]);
  const [loading, setLoading] = useState(true);

  const destroySession = () => {
    localStorage.removeItem("domi-app-memory");
    dispatchAdminAliadoState({ type: "RESET_STATE" });
  };

  const validateToken = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios
      .post(`${REACT_APP_API_HOST}/auth/check`, {}, config)
      .catch(() => false);
    if (response) {
      return response.data.data;
    }
    return false;
  };

  const checkLogin = async () => {
    const tokenLocalStorage = localStorage.getItem("domi-app-memory");

    if (!tokenLocalStorage) {
      return false;
    }

    const tokenValid = await validateToken(tokenLocalStorage);

    if (!tokenValid) {
      destroySession();
      return false;
    }
    const { aliado, token } = tokenValid;
    dispatchAdminAliadoState({ type: "SET_ALIADO", aliado, token });
    return true;
  };

  const fixBody = () =>
    document.querySelector("body").setAttribute("id", "admin-body");

  const loadLogin = async () => {
    if (!adminAliadoState.token || !adminAliadoState.aliado) {
      if (!(await checkLogin())) {
        setLoading(false);
        return;
      }
    }
    if (loading && adminAliadoState.aliado) setLoading(false);
  };

  useEffect(() => {
    fixBody();
    loadLogin();
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAliadoState.token, adminAliadoState.aliado]);

  if (loading) return <LoadingScreen />;

  return (
    <AdminAliadoContext.Provider
      value={[adminAliadoState, dispatchAdminAliadoState]}
    >
      {!adminAliadoState.aliado || !adminAliadoState.token ? (
        <Login />
      ) : (
        <div className="container">
          <NavBar selected={selected} />
          <div className="content">
            <Header destroySession={destroySession} />
            <div className="body">
              {selected === "home" || selected === "tienda" ? <Tienda /> : null}
              {selected === "comandas" ? <Comandas /> : null}
              {selected === "misProductos" ? <Productos /> : null}
            </div>
          </div>
          <ToastContainer newestOnTop />
        </div>
      )}
    </AdminAliadoContext.Provider>
  );
};

export default AdminAliado;
