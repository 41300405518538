import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Button,
  CircularProgress,
  Avatar,
  Typography,
  Modal,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Maps from "../../components/Maps/Maps";
import Autoplacement from "../../components/inputs/TextInput/Autoplacement";
import { useStateValue } from "../../providers/context/contextProvider";
import FormInput from "../../components/inputs/FormInput/FormInput";
import FormItem from "../../components/inputs/FormItem/FormItem";
import DistancePrice from "../../components/DistancePrice/DistancePrice";
import DatosEnvio from "../../components/inputs/DatosEnvio/DatosEnvio";
import MetodosPagoSelect from "../../components/inputs/MetodosPagoSelect/MetodosPagoSelect";
import useCheckout from "../../providers/hooks/useCheckout";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import useComanda from "../../providers/hooks/useComanda";
import OrdenConfirmada from "../../components/modals/OrdenConfirmada/OrdenConfirmada";

const Checkout = ({ direct = false }) => {
  const { REACT_APP_API_HOST } = process.env;
  const history = useHistory();
  const classes = useStyles();
  const [context, dispatch] = useStateValue();
  const [loadedAutoplace, setLoadingAutoplace] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { aliadoId = null } = useParams();
  const [showOrden, setShowOrden] = useState(true);

  const { crearComanda, loading, setLoading, enviado } = useComanda({
    history,
  });
  const {
    distance,
    setDistance,
    metodosPago,
    setMetodosPago,
    metodoPago,
    setMetodoPago,
    comanda,
    setComanda,
    direccionCliente,
    setDireccionCliente,
    GoogleAPI,
    distanceNumber,
    handleDatosCliente,
    datosCliente,
    enviarComanda,
    precioDelivery,
    precioProductos,
    loadingPrecio,
    precioDolar,
    getPrecioDolar,
    comandaCreada,
    setComandaCreada,
  } = useCheckout({ crearComanda, enviado, direct, setLoading, history });

  const callback = useCallback(() => {
    // Para cargar marker del aliado
    setLoadingAutoplace(true);
    if (comanda?.aliado) {
      const { coords } = comanda.aliado;

      const { lat, lng } = coords;
      GoogleAPI.setMarker({
        markerId: "aliado",
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        draggable: false,
      });
    }
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comanda]);

  const loadComanda = () => {
    if (!context.app.comanda) {
      history.replace("/");
      return;
    }
    setComanda(context.app.comanda);
    setLoading(false);
    getPrecioDolar();
    if (
      context.app.comanda.aliado.metodosPago &&
      context.app.comanda.aliado.metodosPago.length
    ) {
      setMetodosPago(context.app.comanda.aliado.metodosPago);
    }
  };

  const loadComandaDirecta = async () => {
    const aliado = context.app.aliados.find(
      (_aliado) => _aliado._id === aliadoId
    );
    if (!aliado) {
      setLoading(true);
      const response = await axios
        .get(`${REACT_APP_API_HOST}/aliado/${aliadoId}`)
        .then((e) => e)
        .catch((e) => null);

      if (response && response.status && response.data.status === 200) {
        if (!response.data.data.activo) {
          history.replace("/aliados");
          return;
        }
        setComanda({
          aliado: response.data.data,
        });
      } else {
        history.replace("/aliados");
      }
      setLoading(false);
      return;
    }
    setComanda({
      aliado,
    });
  };

  useEffect(() => {
    if (direct) {
      loadComandaDirecta();
      return;
    }
    loadComanda();
    // TODO: CHECK ESLINT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AliadoBar = () => (
    <div
      style={{
        width: "100%",
        height: 60,
        backgroundColor: comanda.aliado.colores.secondary,
        borderRadius: 5,
        marginTop: 18,
        display: "flex",
        alignItems: "center",
        padding: "0px 10px",
      }}
    >
      <Avatar
        src={comanda.aliado.perfilImg}
        variant="rounded"
        style={{
          height: 40,
          width: 40,
        }}
      />
      <Typography
        style={{
          fontSize: 14,
          color: "white",
          fontWeight: 600,
          margin: "0px 6px",
        }}
      >
        {comanda.aliado.nombre}
      </Typography>
    </div>
  );

  if (!comanda) return <LoadingScreen title="Cargando comanda..." />;
  const mostrarPrecio = comanda?.aliado?.mostrarPrecio;
  return (
    <Container maxWidth="sm" disableGutters>
      <div className={classes.root}>
        <Header checkout={comanda.aliado._id} direct={direct} />
        <Grid container justifyContent="center">
          {direct ? <AliadoBar /> : null}
          {GoogleAPI && comanda && (
            <>
              <FormItem>
                <Maps
                  GoogleAPI={GoogleAPI}
                  callbacks={{
                    setCallback: callback,
                    distanceCallback: setDistance,
                  }}
                />
              </FormItem>
              <FormItem>
                <Autoplacement
                  placeholder="¿Dónde será llevado?"
                  markerId="destino"
                  GoogleAPI={GoogleAPI}
                  context={context}
                  dispatch={dispatch}
                  direction={direccionCliente}
                  setDirection={setDireccionCliente}
                  loaded={loadedAutoplace}
                />
              </FormItem>
            </>
          )}
          <DatosEnvio
            id="destino"
            open
            noHeader
            datosCliente={datosCliente}
            handleDatosCliente={handleDatosCliente}
          />
          {!direct ? (
            <FormInput
              rows={2}
              label="Nota de Envio"
              placeholder="Descripción detallada de la encomienda"
              value={datosCliente.notaEnvio}
              onChange={(evt) => handleDatosCliente(evt, "notaEnvio")}
              multiline
            />
          ) : null}
          {/* <ModoEnvioCard modo={modoEnvio} setModo={setModoEnvio} /> */}
          {metodosPago.length ? (
            <MetodosPagoSelect
              metodoPago={metodoPago}
              setMetodoPago={setMetodoPago}
              metodosPago={metodosPago}
            />
          ) : null}
          {mostrarPrecio ? (
            <FormItem container>
              <DistancePrice
                distance={distance}
                distanceNumber={distanceNumber}
                precioProductos={precioProductos}
                precioDelivery={precioDelivery}
                precioDolar={precioDolar}
                tooltipOpen={tooltipOpen}
                setTooltipOpen={setTooltipOpen}
              />
            </FormItem>
          ) : null}
          <Grid
            container
            className={classes.formContent}
            justifyContent="center"
          >
            <Button
              className={classes.button}
              onClick={() => enviarComanda()}
              style={
                direct
                  ? {
                      backgroundColor: comanda.aliado.colores.primary,
                    }
                  : {}
              }
            >
              Finalizar Pedido
              {loading || (!direct && loadingPrecio) ? (
                <CircularProgress
                  style={{
                    height: 20,
                    width: 20,
                    marginLeft: 10,
                  }}
                />
              ) : null}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={comandaCreada}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <OrdenConfirmada
          comandaCreada={comandaCreada}
          aliado={comanda.aliado}
          volver={() => {
            history.goBack();
          }}
        />
      </Modal>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  formContent: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginTop: 8,
    marginBottom: theme.spacing(1),
    // background: "red",
  },
  locationButton: {
    color: "white",
    backgroundColor: "#2196f3",
    borderRadius: "5px",
    height: "40px",
    marginBottom: "15px",
    marginLeft: "5px",
  },
  selectEmpty: {
    border: "1px solid #ced4da",
    color: "#515C6F",
    padding: "3px 0px",
    borderRadius: 5,
    boxShadow: "0px 3px 8px #00000029",
    fontSize: 13,
    paddingLeft: 12,
  },
  formLabel: {
    color: "#515C6F",
    fontWeight: "bold",
    fontSize: 15,
  },
  button: {
    backgroundColor: "#EF9700",
    borderRadius: 5,
    color: "white",
    width: "70%",
    height: 40,
    fontWeight: 600,
    textTransform: "capitalize",
    margin: 0,
    padding: 0,
    fontSize: "20px",
  },
}));

export default Checkout;
