const requestLocation = async (dispatch) => {
  const getGeolocationCallback = (options = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.REACT_APP_GEOLOC === "false") {
        resolve({
          coords: {
            latitude: 10.708055199999999,
            longitude: -71.617261,
          },
        });
        return;
      }
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

  dispatch({ type: "SET_LOADING_LOCATION", loading: true });

  return await getGeolocationCallback()
    .then(({ coords }) => {
      const { latitude, longitude } = coords;
      dispatch({
        type: "SET_LOCATION",
        location: {
          lat: latitude,
          lng: longitude,
        },
      });
      dispatch({ type: "SET_LOADING_LOCATION", loading: false });
      return coords;
    })
    .catch((err) => {
      console.log("Error obteniendo geolocalizacion", err);
      dispatch({ type: "SET_DENIED_LOCATION", denied: true });
    });
};

export default requestLocation;
