import React, { useState } from "react";
import { IconButton, Input } from "@material-ui/core";
import uploadImage from "../../../providers/uploadImage";
import ColorPicker from "../../../components/inputs/ColorPicker/ColorPicker";
import ChangeBanner from "../../../components/AdminAliado/ChangeBanner/ChangeBanner";
import { useStateValue } from "../../../providers/context/adminAliadoContext";
import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import { toast } from "react-toastify";
import Fetch from "../../../providers/Fetch";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getGPSLink } from "../../../utils/comandaHelper";
import AvatarInput from "../../../components/AdminAliado/AvatarInput/AvatarInput";

const Tienda = () => {
  const [context, dispatch] = useStateValue();
  const [editNombre, setEditNombre] = useState(false);
  const [editTelefono, setEditTelefono] = useState(
    context.aliado.telefono || ""
  );
  const [aliadoAvatar] = useState(context.aliado.perfilImg || "");
  const [editCoords, setEditCoords] = useState(
    context.aliado.coords || {
      lat: "",
      lng: "",
    }
  );

  const { nombre, categorias, colores, cardImg } = context.aliado;

  const submitData = async ({ field, value, successMessage, errorMessage }) => {
    const result = await Fetch({
      method: "PUT",
      endpoint: `/aliado/${context.aliado._id}`,
      body: {
        [field]: value,
      },
      token: context.token,
    });
    if (!result) {
      toast.error(errorMessage, {
        autoClose: 2500,
        hideProgressBar: true,
        toastId: `error-modify-${field}`,
      });
      return;
    }
    toast.success(successMessage, {
      autoClose: 2500,
      hideProgressBar: true,
      toastId: `sucesss-modify-${field}`,
    });
    dispatch({
      type: "UPDATE_ALIADO",
      nav: "tienda",
      payload: {
        [field]: value,
      },
    });
  };

  const submitNombre = (nombre) => {
    if (!nombre.length) {
      return;
    }
    submitData({
      field: "nombre",
      value: nombre,
      successMessage: "Nombre actualizado con exito",
      errorMessage: "Error al actualizar nombre...",
    });
    setEditNombre(false);
  };

  const submitColor = async (type = "primary", color) => {
    const colores = { ...context.aliado.colores, [type]: color };
    submitData({
      field: "colores",
      value: colores,
      successMessage: "Colores actualizados con exito",
      errorMessage: "Error al actualizar colores...",
    });
  };

  const submitImage = async (pic, field) => {
    toast.info("Subiendo imagen...", {
      toastId: "image-upload",
    });
    const imageUrl = await uploadImage(pic);

    submitData({
      field,
      value: imageUrl,
      successMessage: "Imagen actualizada con exito",
      errorMessage: "Error al actualizar imagen...",
    });
  };

  const submitTelefono = (telefono) => {
    if (isNaN(telefono)) {
      alert("Error en formato de telefono");
      return;
    }
    submitData({
      field: "telefono",
      value: telefono,
      successMessage: "Telefono actualizado con exito",
      errorMessage: "Error al actualizar telefono...",
    });
  };

  const submitCoords = () => {
    submitData({
      field: "coords",
      value: editCoords,
      successMessage: "Coordenadas actualizadas con exito",
      errorMessage: "Error al actualizar coordenadas...",
    });
  };

  const NameEditor = () => {
    return !editNombre ? (
      <div className="title-container">
        <span className="title">{nombre}</span>
        <IconButton
          // onClick={() => setEditing(true)}
          className="icon-container"
        >
          <CreateIcon
            className="icon"
            style={{ color: "white" }}
            onClick={() => setEditNombre(nombre)}
          />
        </IconButton>
      </div>
    ) : (
      <div className="title-container">
        <div className="input-container">
          <input
            type="text"
            value={editNombre}
            onChange={({ target }) => setEditNombre(target.value)}
            autoFocus
            // onBlur={() => setEditNombre(false)}
          />
          <DoneIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              if (editNombre === nombre) {
                setEditNombre(false);
                return;
              }
              if (editNombre.length > 1) submitNombre(editNombre);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="tienda-container">
      <div
        className="tienda-header"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url('${context.aliado.headerImg}') no-repeat scroll center center / cover`,
        }}
      >
        <AvatarInput
          AvatarSrc={aliadoAvatar}
          field="perfilImg"
          submitImage={submitImage}
          background
        />
        <div className="header-title">
          <NameEditor />

          <span className="categories">
            {categorias.map((categoria) => categoria.nombre).join(" - ")}
          </span>
          <span className="ubicacion">
            Tu Ubicacion:{" "}
            <a
              href={
                context.aliado.coords ? getGPSLink(context.aliado.coords) : "#"
              }
              target="_blank"
              rel="noreferrer"
            >
              Ver en Mapa
            </a>
          </span>
        </div>
        <div className="edit">
          <input
            accept="image/*"
            id="header-img"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={({ target: { files } }) =>
              submitImage(files[0], "headerImg")
            }
          />
          <IconButton
            // onClick={() => setEditing(true)}
            className="icon-container"
          >
            <CreateIcon
              className="icon"
              style={{ color: "white" }}
              onClick={() => {
                document.getElementById("header-img").click();
              }}
            />
          </IconButton>
          {/* <div
            style={{
              height: 30,
              width: 30,
              borderRadius: 50,
              background: "grey",
            }}
          /> */}
        </div>
      </div>
      <div className="tienda-body">
        <div className="colores-container">
          <span className="colores-title">Colores de la Tienda:</span>
          <div className="colores-group">
            <div className="color-input">
              <ColorPicker
                type="primary"
                submitColor={submitColor}
                color={colores.primary}
                title="Primario:"
                //   selectedColor={(color) => setSecondaryColor(color)}
              />
            </div>
            <div className="color-input">
              <ColorPicker
                type="secondary"
                submitColor={submitColor}
                color={colores.secondary}
                title="Segundario:"
                //   selectedColor={(color) => setSecondaryColor(color)}
              />
            </div>
          </div>
        </div>
        <div className="colores-container numero">
          <span className="colores-title">
            Numero de Whatsapp: <small>(Sin espacios, solo numeros)</small>
          </span>
          <div className="input-body">
            <Input
              placeholder="Ej: 584246412886"
              value={editTelefono}
              onChange={({ target: { value } }) => {
                if (isNaN(value)) return;
                setEditTelefono(value);
              }}
              style={{
                fontSize: 12,
                width: "50%",
              }}
            />
            {context.aliado.telefono === editTelefono ? null : (
              <CheckCircleIcon
                style={{
                  color: context.aliado.colores.primary,
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                onClick={() => {
                  submitTelefono(editTelefono);
                }}
              />
            )}
          </div>
        </div>
        <div className="colores-container numero coords">
          <span className="colores-title">
            Coordenadas GPS: <small>(Solo numeros)</small>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="input-body">
              <span>Latitude:</span>
              <Input
                placeholder="Ej: 10.6709814"
                value={editCoords.lat}
                onChange={({ target: { value } }) => {
                  if (isNaN(value)) return;
                  setEditCoords((coords) => ({ ...coords, lat: value }));
                }}
                style={{
                  fontSize: 12,
                  width: "50%",
                }}
              />
              {context.aliado.coords.lat === editCoords.lat ? null : (
                <CheckCircleIcon
                  style={{
                    color: context.aliado.colores.primary,
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    submitCoords();
                  }}
                />
              )}
            </div>
            <div className="input-body">
              <span>Longitude:</span>
              <Input
                placeholder="Ej: 10.6709814"
                value={editCoords.lng}
                onChange={({ target: { value } }) => {
                  if (isNaN(value)) return;
                  setEditCoords((coords) => ({ ...coords, lng: value }));
                }}
                style={{
                  fontSize: 12,
                  width: "50%",
                }}
              />
              {context.aliado.coords.lng === editCoords.lng ? null : (
                <CheckCircleIcon
                  style={{
                    color: context.aliado.colores.primary,
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    submitCoords();
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <ChangeBanner
          currentImg={cardImg}
          id="cardbgimg"
          uploadImg={(pic) => submitImage(pic, "cardImg")}
        />
        {/* <div className="footer">
          <div className="button-group">
            <div className="button guardar">Guardar</div>
            <div className="button">Descartar</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Tienda;
