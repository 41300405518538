import styled from "styled-components";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${(props) => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${(props) => {
    if (!props.sliding) return "translateX(calc(-90% - 10px))";
    if (props.dir === PREV) return "translateX(calc(2 * (-90% - 10px)))";
    return "translateX(0%)";
  }};
  padding-top: 0%;
  padding-left: 0px;
`;

export const Wrapper = styled.div`
  margin-left: -2.5%;
  width: 110%;
  overflow: hidden;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 90%;
  margin-right: 20px;
  order: ${(props) => props.order};
`;

export const SlideButton = styled.button`
    color: #ffffff;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 100;
    padding: 10px;
    background-color: #f66f3e;
    border: 1px solid white;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
  float: ${(props) => props.float};

  &:active {
    position: relative;
    top: 1px;
  }
  &:focus {
    outline: 0;
  }
`;

export const AppContainer = styled.div`
  width: 100%;
`;
