import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Categoria from "../Categoria/Categoria";
import { useHistory } from "react-router-dom";

const ServiciosCards = () => {
  const history = useHistory();
  const classes = useStyles();

  const handleNavigate = (route) => {
    history.push(`/${route}`);
  };

  const categorias = [
    {
      texto: "DomiFavor",
      tipo: "encomiendas",
      nav: "encomiendas",
      backgroundSize: "94%",
    },
    {
      tipo: "restaurantes",
      texto: "Restaurantes",
      nav: "aliados",
    },
    {
      texto: "MarketPlace",
      tipo: "markets",
      nav: "market",
      backgroundSize: "60%",
    },
    {
      texto: "Domi Carro",
      tipo: "farmacias",
      nav: "carro",
    },
  ];

  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      {categorias.map((categoria, idx) => (
        <Grid item key={idx}>
          <Categoria
            {...categoria}
            onClick={() => {
              if (categoria.nav) handleNavigate(categoria.nav);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: `0px ${theme.spacing(1)}px`,
    margin: "20px 0px",
    height: "calc(100vh - 480px)",
  },
}));

export default ServiciosCards;
