import { createTheme } from "@material-ui/core/styles";
const contrastText = "#ffffff";
export const primary = {
  main: "#35a4fe",
  light: "#7ad5ff",
  dark: "#0076ca",
  contrastText,
};
export const secondary = {
  main: "#ef9700",
  light: "#ffc846",
  dark: "#b76900",
  contrastText: "#ffffff",
};
export const theme = createTheme({
  palette: {
    primary,
    secondary,
    celestes: {
      main: "#A8EDFF",
    },
    grises: {
      main: "#8A8888",
      darkblue: "#515C6F",
    },
    blancos: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#515C6F",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "none !important",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
    h6: {
      fontWeight: "bold",
      lineHeight: 1.2,
    },
  },
});

export const adminTheme = createTheme({
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiInput: {
      // fontSize: 12,
      underline: {
        "&:before": {
          borderBottom: `1px solid rgba(0, 0, 0, 0.54)`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${primary.main}`,
          transition: "0.1 ease-in",
        },
      },
    },
  },
});
