const googleLinkTypes = [
  {
    id: "SHORT_LINK",
    value:
      /^https:\/\/www\.google\.com\/maps\?q=[-+]?[0-9]*\.?[0-9]+,[-+]?[0-9]*\.?[0-9]+$/,
    extractCoords: (link) => {
      const url = new URL(link);
      const qParam = url.searchParams.get("q");
      const [lat, lng] = qParam.split(",");

      return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
    },
  },
  {
    id: "LONG_LINK",
    value: /^https:\/\/www\.google\.com\/maps\/place\/(.+)\/[@](.+)/,
    extractCoords: (link) => {
      try {
        const coords = link.split("@")[1].split("/")[0];
        const [lat, lng] = coords.split(",");

        return {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
      } catch (error) {
        alert("Error obteniendo las coordenadas del Link introducido...");
        return null;
      }
    },
  },
  {
    id: "LONG_LINK_ALT",
    value: /^https:\/\/www\.google\.com\/maps\/[@](.+)/,
    extractCoords: (link) => {
      try {
        const coords = link.split("@")[1].split("?")[0];
        const [lat, lng] = coords.split(",");

        return {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
      } catch (error) {
        alert("Error obteniendo las coordenadas del Link introducido...");
        return null;
      }
    },
  },
  {
    id: "RAW_COORDS",
    value: /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/,
    extractCoords: (link) => {
      const [lat, lng] = link.split(",");
      return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
    },
  },
];

export function isGoogleMapsLink(link) {
  for (const googleLink of googleLinkTypes) {
    if (googleLink.value.test(link)) {
      return googleLink;
    }
  }

  return false;
}
