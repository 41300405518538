import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useStateValue } from "../../../providers/context/contextProvider";
import { Button, Typography } from "@material-ui/core";
import copy from "copy-to-clipboard";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { toast } from "react-toastify";

const OrdenConfirmada = ({
  comandaCreada: { comandaParams, comandaText },
  aliado = null,
  volver = () => {},
}) => {
  const [noRedirect, setNoRedirect] = useState(false);
  const styles = useStyles();
  const { nombre, telefono } = aliado || {};

  return (
    <div className={styles.root}>
      <div className={styles.modalContainer}>
        <Typography
          style={{
            marginTop: 10,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          No has sido redireccionado?
        </Typography>
        {noRedirect ? (
          <>
            <Typography
              style={{
                marginTop: 10,
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              Haz click en el siguiente boton para copiar tu orden
            </Typography>
            <Button
              style={{
                background: "#EF9700",
                margin: "10px 0px",
              }}
              onClick={() => {
                copy(comandaText);
                toast.success(
                  "Orden copiada con exito, pega el contenido en el chat con el aliado",
                  {
                    toastId: "sucesss-copiado",
                    style: {
                      backgroundColor: "#35a4fe",
                    },
                  }
                );
              }}
            >
              <FileCopyIcon
                style={{
                  color: "white",
                }}
              />
              <Typography
                style={{
                  color: "white",
                  fontSize: 12,
                  fontWeight: 500,
                  margin: "0px 8px",
                }}
              >
                Copiar
              </Typography>
            </Button>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              {aliado
                ? `Contacto de ${nombre}: ${telefono}`
                : "Contacto: 04246113560"}
            </Typography>
          </>
        ) : (
          <>
            <Button
              style={{
                background: "#EF9700",
                margin: "10px 0px",
              }}
              onClick={() => setNoRedirect(true)}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: "initial",
                  color: "white",
                }}
              >
                Soporte
              </Typography>
              <ContactSupportIcon
                style={{
                  height: 20,
                  width: 20,
                  color: "white",
                  margin: "0px 4px",
                }}
              />
            </Button>
          </>
        )}
        <Button
          style={{
            background: "#35a4fe",
            marginTop: 20,
            width: "100%",
          }}
          onClick={volver}
        >
          <Typography
            style={{
              color: "white",
              fontSize: 12,
              margin: "0px 8px",
              fontWeight: 500,
            }}
          >
            Volver a App
          </Typography>
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    // minHeight: 300,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    backgroundColor: "white",
  },
  modalContainer: {
    textAlign: "center",
    padding: "10px 40px",
  },
  noItemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.grises.main,
  },
  titleText: {
    fontSize: 16,
  },
  button: {
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(2),
    backgroundColor: "#35a4fe",
    borderRadius: 5,
    color: "white",
    width: "50%",
    // marginLeft: "15%",
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  buttonContinuar: {
    backgroundColor: "#EF9700",
  },
  buttonAdd: {
    width: "100%",
  },
  internalContainer: {
    display: "flex",
    flexDirection: "column",
    // background: "yellow",
    width: "100%",
  },
  options: {
    height: "20%",
  },
  totalBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grises.darkblue,
    textTransform: "capitalize",
    lineHeight: "1.9vh",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default OrdenConfirmada;
