/* eslint-disable react-hooks/exhaustive-deps */
// TODO: CHECK ESLINT
import React, { useEffect, useMemo, useState, Fragment } from "react";
import { Modal } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ImageIcon from "@material-ui/icons/Image";
import KitchenIcon from "@material-ui/icons/Kitchen";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import "./Tabla.css";
import ImageModal from "./Modals/ImageModal";
import EditModal from "./Modals/EditModal";
import AgregarModal from "./Modals/AgregarModal";
import IngredientesModal from "./Modals/IngredientesModal";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { toast } from "react-toastify";
import axios from "axios";

const TablaProductos = ({
  productos,
  existingCategorias = [],
  categoria,
  context,
  dispatch,
  reordered,
}) => {
  const { REACT_APP_API_HOST } = process.env;
  const [productoQuery, setProductoQuery] = useState("");
  const [productoSelected, setProductoSelected] = useState(null);
  const [modal, setModal] = useState(false);
  const [avoidClose, setAvoidClose] = useState(false);
  const [newImage, setNewImage] = useState(null);

  const productosFiltered = useMemo(() => {
    const abc = productos.filter((option) =>
      option.nombre
        .toString()
        .toLowerCase()
        .includes(productoQuery.toString().toLowerCase())
    );
    return abc;
  }, [productos, categoria, productoQuery]);

  useEffect(() => {
    setProductoQuery("");
  }, [categoria]);

  useEffect(() => {
    if (newImage) {
      dispatch({
        type: "UPDATE_PRODUCTO",
        _id: productoSelected._id,
        payload: {
          productoImg: newImage,
        },
        nav: "misProductos",
      });
      toast.success("Imagen agregada con exito", {
        autoClose: 1500,
      });
    }
  }, [newImage]);

  useEffect(() => {
    if (!modal) {
      setProductoSelected(null);
    }
  }, [modal]);

  const retirarProducto = async (productoId, nombre = "") => {
    const confirm = window.confirm(
      `Seguro que deseas retirar el producto: ${nombre}`
    );
    if (!confirm) return;
    const config = {
      headers: { Authorization: `Bearer ${context.token}` },
    };
    const response = await axios
      .delete(`${REACT_APP_API_HOST}/producto/${productoId}`, config)
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!response) {
      toast.error("Error al retirar producto...", {
        toastId: "error-removing",
      });
      return;
    }
    toast.success("Producto retirado con exito...");
    dispatch({
      type: "REMOVE_PRODUCTO",
      _id: productoId,
      nav: "misProductos",
    });
  };

  return (
    <div className="tabla-productos">
      <div className="tabla-title">
        <h2>{categoria}</h2>
        <div className="right-container">
          <div
            className="agregar-container"
            onClick={() => {
              setProductoSelected({ type: "agregar" });
              setModal(true);
            }}
          >
            <span>Agregar nuevo producto</span>
            <AddBoxIcon />
          </div>
          <div className="text-input">
            <input
              type="text"
              value={productoQuery}
              placeholder={`Buscar en ${categoria}...`}
              onChange={({ target }) => setProductoQuery(target.value)}
            />
            <SearchIcon />
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripcion</th>
            <th>Precio</th>
            <th>Imagen</th>
            <th>Ingredientes</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productosFiltered.map((producto, idx) => {
            return (
              <tr key={idx}>
                <td>{producto.nombre}</td>
                <td>{producto.descripcion}</td>
                <td>${producto.precio}</td>
                <td>
                  {producto.productoImg ? (
                    <ImageIcon
                      style={{
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModal(true);
                        setProductoSelected({
                          type: "image",
                          ...producto,
                        });
                      }}
                    />
                  ) : (
                    <AddPhotoAlternateIcon
                      style={{
                        color: "grey",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModal(true);
                        setProductoSelected({
                          type: "image",
                          productoImg: null,
                          ...producto,
                        });
                      }}
                    />
                  )}
                </td>
                <td>
                  <KitchenIcon
                    style={{
                      color: "var(--primary-color)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setModal(true);
                      setProductoSelected({
                        type: "ingredientes",
                        ...producto,
                      });
                    }}
                  />
                </td>
                <td>
                  <EditIcon
                    style={{
                      color: "grey",
                      cursor: "pointer",
                      marginRight: 2,
                    }}
                    onClick={() => {
                      setModal(true);
                      setProductoSelected({
                        type: "edit",
                        ...producto,
                      });
                    }}
                  />
                  <DeleteForeverIcon
                    style={{
                      color: "#BF211E",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      retirarProducto(producto._id, producto.nombre);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        open={modal && Boolean(productoSelected)}
        onClose={() => {
          if (!avoidClose) setModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fragment>
          {productoSelected && productoSelected.type === "image" ? (
            <ImageModal
              productoSelected={productoSelected || {}}
              setProductoSelected={setProductoSelected}
              setModal={setModal}
              setAvoidClose={setAvoidClose}
              context={context}
              dispatch={dispatch}
              onSubmit={(value) => {
                setNewImage(value);
                setModal(false);
              }}
            />
          ) : null}
          {productoSelected && productoSelected.type === "edit" ? (
            <EditModal
              productoSelected={productoSelected || {}}
              setProductoSelected={setProductoSelected}
              setModal={setModal}
              setAvoidClose={setAvoidClose}
              context={context}
              dispatch={dispatch}
              onSubmit={(values) => {
                setAvoidClose(false);
                setModal(false);
                dispatch({
                  type: "UPDATE_PRODUCTO",
                  _id: productoSelected._id,
                  payload: {
                    ...values,
                  },
                  nav: "misProductos",
                });

                toast.success("Producto modificado con exito", {
                  autoClose: 1500,
                });
              }}
            />
          ) : null}
          {productoSelected && productoSelected.type === "agregar" ? (
            <AgregarModal
              productoSelected={productoSelected || {}}
              setProductoSelected={setProductoSelected}
              setModal={setModal}
              setAvoidClose={setAvoidClose}
              context={context}
              dispatch={dispatch}
              existingCategorias={existingCategorias}
              onSubmit={(values) => {
                setAvoidClose(false);
                setModal(false);
                dispatch({
                  type: "AGREGAR_PRODUCTO",
                  producto: values,
                  nav: "misProductos",
                });

                toast.success("Producto agregado con exito", {
                  autoClose: 1500,
                });
              }}
            />
          ) : null}
          {productoSelected && productoSelected.type === "ingredientes" ? (
            <IngredientesModal
              productoSelected={productoSelected}
              setProductoSelected={setProductoSelected}
              setModal={setModal}
              setAvoidClose={setAvoidClose}
              context={context}
              dispatch={dispatch}
              onSubmit={(values) => {
                setAvoidClose(false);
                setModal(false);
                alert("Submited!");
                // dispatch({
                //   type: "AGREGAR_PRODUCTO",
                //   producto: values,
                //   nav: "misProductos",
                // });

                // toast.success("Producto agregado con exito", {
                //   autoClose: 1500,
                // });
              }}
            />
          ) : null}
        </Fragment>
      </Modal>
    </div>
  );
};

export default TablaProductos;
