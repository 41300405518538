import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/inputs/SearchBar/SearchBar";
import ServiciosCards from "../../components/cards/ServiciosCards/ServiciosCards";
import ContactoCard from "../../components/cards/ContactoCard/ContactoCard";

const Home = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Header home />
      <div className={classes.searchBarContainer}>
        <SearchBar />
      </div>
      <ServiciosCards />
      <ContactoCard />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 55,
    minHeight: "100vh",
  },
  promos: {
    margin: `${theme.spacing(2)}px 0px`,
  },
  searchBarContainer: {
    margin: "30px 0px",
  },
}));

export default Home;
